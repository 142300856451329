import React, { useState, useEffect, useRef } from "react";
import "./WhatsappChat.css";
import { formatTimestamp } from "utils/formatTimeStamp";
import {
  MdDownload,
  MdOutlinePlayCircleFilled,
  MdOutlineReply,
} from "react-icons/md";

const Message = ({
  message,
  query,
  setMobileNumberSearchQuery,
  handleReplyClick,
}) => {
  const [showFullSupportMessages, setShowFullSupportMessages] = useState(false);
  const [showFullRepliedMessage, setShowFullRepliedMessage] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const ref = useRef(null);
  const isMatch =
    query.length > 7 &&
    message.user.user_type === "user" &&
    message.user.phone_number.includes(query);

  const isMatch2 =
    query.length > 7 &&
    (message.user.user_type === "doctor" ||
      message.user.user_type === "support") &&
    message.user.reply_user &&
    message.user.reply_user.includes(query);
  const isImage = message.message.media_type === "image";
  const isVideo = message.message.media_type === "video";
  const isReply = message.message.reply_id !== null;
  // Function to truncate support messages to 80 characters
  const renderMessageText = (message) => {
    if (message.user.user_type === "support" && !showFullSupportMessages) {
      const truncatedMessage = message.message.content.slice(0, 80);
      return (
        <>
          {truncatedMessage}
          {message.message.content.length > 80 && (
            <span>
              {" "}
              <button
                onClick={toggleFullSupportMessages}
                className="cursor-pointer text-xs text-blue-500"
              >
                Read More
              </button>
            </span>
          )}
        </>
      );
    } else {
      return message.message.content;
    }
  };

  // Function to truncate replied messages
  const renderRepliedMessageText = (repliedMessage) => {
    if (!showFullRepliedMessage) {
      const truncatedMessage = repliedMessage.slice(0, 80);
      return (
        <>
          {truncatedMessage}
          {repliedMessage.length > 80 && (
            <span>
              {" "}
              <button
                onClick={toggleFullRepliedMessage}
                className="cursor-pointer text-xs text-blue-500"
              >
                Read More
              </button>
            </span>
          )}
        </>
      );
    } else {
      return repliedMessage;
    }
  };
  const renderMedia = () => {
    // console.log(message.message.media);
    if (isImage) {
      return (
        <div className="relative mt-1">
          <img
            src={`data:image/jpeg;base64,${message.message.media}`}
            alt="Media"
            className="h-full max-h-[400px] min-h-[100px] w-full min-w-[100px] max-w-[500px] cursor-pointer rounded-md  object-contain"
            onClick={() => setIsFullScreen(!isFullScreen)}
          />

          <div className="absolute top-1 right-1">
            <button
              onClick={() => downloadImage(message.message.media)}
              className="rounded-full bg-blue-500 p-2 text-white"
            >
              <MdDownload className="text-lg" />
            </button>
          </div>
        </div>
      );
    } else if (isVideo) {
      return (
        //  <div className="relative">
        //    <ReactPlayer
        //      url={`data:video/mp4;base64,${message.message.media}`}
        //      width="100%"
        //      height="100%"
        //      playing={isFullScreen}
        //      controls={!isFullScreen}
        //      className="object-contain border rounded-md cursor-pointer"
        //      onClick={() => setIsFullScreen(!isFullScreen)}
        //    />
        //    {isFullScreen && (
        //      <div
        //        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80"
        //        onClick={() => setIsFullScreen(false)}
        //      >
        //        <ReactPlayer
        //          url={`data:video/mp4;base64,${message.message.media}`}
        //          width="100%"
        //          height="100%"
        //          playing={isFullScreen}
        //          controls={!isFullScreen}
        //        />
        //      </div>
        //    )}
        //  </div>
        <div className="relative mt-1">
          <img
            src={`data:image/jpeg;base64,${message.message.media}`}
            alt="Media"
            className="h-full max-h-[400px] min-h-[100px] w-full min-w-[100px] max-w-[500px] cursor-pointer rounded-md  object-contain"
          />

          <div className="absolute top-1 right-1 ">
            <button
              onClick={() => downloadImage(message.message.media)}
              className="rounded-full bg-blue-500 p-2 text-white "
            >
              <MdOutlinePlayCircleFilled className="text-lg" />
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
  const toggleFullSupportMessages = () => {
    setShowFullSupportMessages(!showFullSupportMessages);
  };

  const toggleFullRepliedMessage = () => {
    setShowFullRepliedMessage(!showFullRepliedMessage);
  };
  const downloadImage = (base64Data) => {
    const link = document.createElement("a");
    link.href = `data:image/jpeg;base64,${base64Data}`;
    link.download = "image.jpg";
    link.click();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (typeof setMobileNumberSearchQuery === "function") {
          setMobileNumberSearchQuery("");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setMobileNumberSearchQuery]);
  return (
    <div
      className="row message-body"
      id={`message-${message.message.message_id}`}
      ref={ref}
    >
      {message.user.user_type === "user" ? (
        <div className={` message-main-receiver group px-2 py-4`}>
          <div className="flex items-center gap-2">
            <div
              onClick={() => {
                const value = message.user.phone_number.replace("+", "");
                // console.log(value);
                setMobileNumberSearchQuery(value);
              }}
              className={`receiver relative cursor-pointer bg-white ${
                isMatch ? "bg-yellow-500" : ""
              }`}
            >
              <div className="flex justify-between">
                <div
                  className={`font-bold ${
                    message.user.user_type === "user"
                      ? "text-green-500"
                      : "text-blue-500"
                  }`}
                >
                  {message.user.name}
                </div>

                <div>
                  ~{""} {message.user.phone_number}
                </div>
              </div>
              {isReply && (
                <div className="reply-content mt-2 rounded-lg bg-gray-300 px-2 py-1 pl-2">
                  <div className="message-time pl-2 text-right">
                    {formatTimestamp(message.message.reply_time)}
                  </div>
                  {message.message.reply_content ? (
                    <div className="">
                      {renderRepliedMessageText(message.message.reply_content)}
                    </div>
                  ) : (
                    "N/A"
                  )}
                  <div className="flex items-center justify-between pt-1">
                    <div className="font-normal ">
                      <span className="font-bold text-green-500">
                        {" "}
                        {message.user.reply_user_name
                          ? message.user.reply_user_name
                          : "N/A"}
                      </span>
                    </div>
                    <div className="pl-2 text-right text-pink-500">
                      {" ~ "}{" "}
                      {message.user.reply_user
                        ? message.user.reply_user
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}
              {renderMedia()}
              <div className="message-text">{renderMessageText(message)}</div>
              <span className="message-time text-right">
                {formatTimestamp(message.message.time)}
              </span>
            </div>
            <button
              className="hidden items-center rounded-full bg-gray-600 px-2 py-1 text-white group-hover:flex"
              onClick={() => handleReplyClick(message)}
            >
              <MdOutlineReply />
            </button>
          </div>
        </div>
      ) : (
        <div className="message-main-sender py-4">
          <div className="group flex items-center gap-2">
            <button
              className="hidden items-center rounded-full bg-gray-600 px-2 py-1 text-white group-hover:flex"
              onClick={() => handleReplyClick(message)}
            >
              <MdOutlineReply />
            </button>
            <div
              className={`sender py-4 ${
                isMatch2 ? "bg-yellow-500" : "bg-[#dcf8c6]"
              }`}
            >
              <div className="flex justify-between">
                <div className="font-bold text-blue-500">
                  {message.user.name}
                </div>
                <div>
                  ~{""} {message.user.phone_number}
                </div>
              </div>
              {isReply && (
                <div
                  onClick={() => {
                    const value = message.user.reply_user.replace("+", "");
                    // console.log(value);
                    setMobileNumberSearchQuery(value);
                  }}
                  className="reply-content mt-2 cursor-pointer rounded-lg bg-gray-300 px-2 pt-1"
                >
                  <div className="message-time pl-2 text-right">
                    {formatTimestamp(message.message.reply_time)}
                  </div>
                  {message.message.reply_content ? (
                    <div className="">
                      {renderRepliedMessageText(message.message.reply_content)}
                    </div>
                  ) : (
                    "N/A"
                  )}
                  <div className="flex items-center justify-between py-1">
                    <div className="font-normal ">
                      <span className="font-bold text-green-500">
                        {" "}
                        {message.user.reply_user_name
                          ? message.user.reply_user_name
                          : "N/A"}
                      </span>
                    </div>
                    <div className="pl-2 text-right text-pink-500 ">
                      {" ~ "}{" "}
                      {message.user.reply_user
                        ? message.user.reply_user
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}
              {renderMedia()}
              <div className="message-text">{renderMessageText(message)}</div>
              <span className="message-time text-right">
                {formatTimestamp(message.message.time)}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
