import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import axios from "axios";
import { getToken } from "utils/getToken";
import { toast } from "react-toastify";
import SyncLoader from "components/Loaders/SyncLoader";
import { IoMdClose } from "react-icons/io";
import { Listbox } from "@headlessui/react";
import { HiOutlineSelector } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};
const specialties = ["lactation", "growth", "nutrition"];

const CreateDoctorModal = ({
  openCreateDoctorModal,
  setOpenCreateDoctorModal,
  refresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    medical_registration_number: "",
    degree: "",
    mobile: "",
    address: "",
    visibility: "all_customer",
    babynama_doctor: true,
    specialty: [],
  });

  useEffect(() => {
    if (openCreateDoctorModal.open) {
      setFormData({
        ...formData,
        name: openCreateDoctorModal.userName,
        mobile: openCreateDoctorModal.mobile,
      });
    }
  }, [openCreateDoctorModal]);

  console.log(formData);

  const handleCreateDoctor = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}doctors/support/create-doctor/`,
        { user_id: openCreateDoctorModal.userId?.toString(), ...formData },
        { headers }
      );

      if (response.status === 200) {
        toast.success("Doctor's details uploaded successfully!");
        setOpenCreateDoctorModal({ open: false, userId: null });
        setFormData({});
        refresh();
      }
    } catch (error) {
      toast.error("Something went wrong while creating doctor.");
      console.error("Create doctor data", error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setOpenCreateDoctorModal({
      open: false,
      mobile: "",
      userId: null,
      userName: "",
    });
    setFormData({});
  };

  const handleSpecialtyChange = (selectedSpecialties) => {
    setFormData({ ...formData, specialty: selectedSpecialties });
  };

  return (
    <Dialog
      open={openCreateDoctorModal.open}
      onClose={closeModal}
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
    >
      <div
        className="bg-black fixed inset-0 bg-opacity-50"
        aria-hidden="true"
      />
      <div className="relative mx-auto w-full max-w-lg rounded-lg bg-white shadow-lg">
        <div className="flex items-center justify-between border-b p-4">
          <h3 className="text-lg font-medium text-gray-900">
            Create Doctor Profile
          </h3>
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-700"
          >
            <IoMdClose size={25} />
          </button>
        </div>

        <form onSubmit={handleCreateDoctor} className="space-y-6 p-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                name="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                name="phoneNumber"
                value={formData.mobile}
                onChange={(e) =>
                  setFormData({ ...formData, mobile: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Degree
              </label>
              <input
                name="degree"
                value={formData.degree}
                onChange={(e) =>
                  setFormData({ ...formData, degree: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Medical Registration Number
              </label>
              <input
                name="reg number"
                value={formData.medical_registration_number}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    medical_registration_number: e.target.value,
                  })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <input
                name="degree"
                value={formData.address}
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Visibility
              </label>
              <select
                onChange={(e) =>
                  setFormData({ ...formData, visibility: e.target.value })
                }
                value={formData.visibility}
                name="visibility"
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option value="all_customer">All Customers</option>
                <option value="old_customer">Old Customers</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Babynama Doctor
              </label>
              <select
                value={formData.babynama_doctor}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    babynama_doctor: JSON.parse(e.target.value),
                  })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Specialty
              </label>
              <Listbox
                value={formData.specialty}
                onChange={handleSpecialtyChange}
                multiple
              >
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button className="inline-flex w-full items-center justify-between gap-2 rounded-md border border-gray-300 bg-white px-2 py-3 text-sm font-medium text-gray-700 shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                        {Array.isArray(formData.specialty) &&
                        formData.specialty.length > 0
                          ? `${formData.specialty.length} ${
                              formData.specialty.length > 1
                                ? "Specialties"
                                : "Specialty"
                            } Selected`
                          : "Select Specialty"}
                        {Array.isArray(formData.specialty) &&
                        formData.specialty.length > 0 ? (
                          <button
                            onClick={() => handleSpecialtyChange([])}
                            className="ml-2 flex items-center text-gray-500 hover:text-red-500"
                          >
                            <MdOutlineClose size={22} />
                          </button>
                        ) : (
                          <HiOutlineSelector size={22} />
                        )}
                      </Listbox.Button>

                      <Listbox.Options
                        className={`ring-black absolute bottom-[110%] mt-1 max-h-60 min-w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm ${
                          open ? "block" : "hidden"
                        }`}
                      >
                        {specialties.map((option, index) => (
                          <Listbox.Option
                            key={index}
                            value={option} // Send only the role name
                            className={({ active }) =>
                              `${
                                active
                                  ? "bg-green-500 text-white"
                                  : "text-gray-900"
                              } relative cursor-pointer select-none py-2 pl-3 pr-9`
                            }
                          >
                            {({ selected }) => (
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  readOnly
                                  checked={selected}
                                  className="form-checkbox w h-5 w-5 text-green-600"
                                />
                                <span
                                  className={`ml-3 block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {option}
                                </span>
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>

          <div>
            {loading ? (
              <div className="flex justify-center py-2">
                <SyncLoader size="8px" color="#22C55E" />
              </div>
            ) : (
              <button
                type="submit"
                className={`w-full rounded-md bg-green-600 px-4 py-2 text-white shadow-sm hover:bg-green-700`}
              >
                Upload Doctor Details
              </button>
            )}
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default CreateDoctorModal;
