import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import axios from "axios";
import { getToken } from "utils/getToken";
import { toast } from "react-toastify";
import SyncLoader from "components/Loaders/SyncLoader";
import { IoMdClose } from "react-icons/io";
import { Listbox } from "@headlessui/react";
import { HiOutlineSelector } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};

const roleOptions = [
  { name: "User" },
  { name: "Doctor" },
  { name: "Affiliate" },
  { name: "Support" },
  { name: "Sales" },
];

const EditUserModal = ({
  openEditUserModal,
  setOpenEditUserModal,
  refresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [supportUsers, setSupportUsers] = useState([]);
  const [formData, setFormData] = useState({
    firstName: openEditUserModal.firstName,
    lastName: openEditUserModal.lastName,
    roles: openEditUserModal.role || [], // Ensure roles is always an array
    phoneNumber: openEditUserModal.userMobile,
    email: openEditUserModal.email,
    growthCallEnabled: openEditUserModal.growth,
    subscriberOnboarding: openEditUserModal.onboardingStatus ? "Yes" : "No",
    btpSubscriber: openEditUserModal.btpSubscriber,
    updatedBy: openEditUserModal.updated_by || "",
  });

  useEffect(() => {
    if (openEditUserModal.open) {
      getSupportUsers();

      setFormData({
        firstName: openEditUserModal.firstName,
        lastName: openEditUserModal.lastName,
        roles: openEditUserModal.role?.filter((role) => role !== null) || [],
        phoneNumber: openEditUserModal.userMobile,
        email: openEditUserModal.email,
        growthCallEnabled: openEditUserModal.growth,
        subscriberOnboarding: openEditUserModal.onboardingStatus ? "Yes" : "No",
        btpSubscriber: openEditUserModal.btpSubscriber,
        updatedBy: openEditUserModal.updated_by || "",
      });
    }
  }, [openEditUserModal]);

  const handleEditUser = async (e) => {
    e.preventDefault();

    if (openEditUserModal.userId) {
      try {
        setLoading(true);

        // Prepare the payload
        const payload = {
          id: openEditUserModal.userId,
          first_name: formData.firstName,
          last_name: formData.lastName,
          phone_number: formData.phoneNumber,
          email: formData.email,
          growth_call_enabled: formData.growthCallEnabled === "Yes",
          subscriber_onboarding: {
            call_completed: formData.subscriberOnboarding === "Yes", // Convert "Yes"/"No" back to boolean
            call_updated_by: formData.updatedBy,
          },
          user_role: formData.roles,
        };
        console.log(payload);

        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}users/update-user/`,
          payload,
          { headers }
        );

        if (response.status === 200) {
          toast.success("User details updated successfully!");
          setOpenEditUserModal({ open: false, userId: null });
          refresh();
        }
      } catch (error) {
        toast.error("Something went wrong while editing user data.");
        console.error("Edit user data", error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.warn("Invalid user ID. Please try again.");
    }
  };

  const handleRoleChange = (selectedRoles) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      roles: selectedRoles,
    }));
  };

  const handleOnboardingUserChange = (userId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      updatedBy: userId,
    }));
  };

  const closeModal = () => {
    setOpenEditUserModal({
      open: false,
      userId: null,
    });
  };

  const getSupportUsers = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}users/get-support-user/`,
      { headers }
    );
    setSupportUsers(res.data);
  };

  return (
    <Dialog
      open={openEditUserModal.open}
      onClose={closeModal}
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
    >
      <div
        className="bg-black fixed inset-0 bg-opacity-50"
        aria-hidden="true"
      />
      <div className="relative mx-auto w-full max-w-lg rounded-lg bg-white shadow-lg">
        <div className="flex items-center justify-between border-b p-4">
          <h3 className="text-lg font-medium text-gray-900">Edit User</h3>
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-700"
          >
            <IoMdClose size={25} />
          </button>
        </div>

        <form onSubmit={handleEditUser} className="space-y-6 p-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                name="firstName"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                name="lastName"
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Role
              </label>
              <Listbox
                value={formData.roles}
                onChange={handleRoleChange}
                multiple
              >
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button className="inline-flex w-full items-center justify-between gap-2 rounded-md border border-gray-300 bg-white px-2 py-3 text-sm font-medium text-gray-700 shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                        {Array.isArray(formData.roles) &&
                        formData.roles.length > 0
                          ? `${formData.roles.length} Role(s) Selected`
                          : "Select Roles"}
                        {Array.isArray(formData.roles) &&
                        formData.roles.length > 0 ? (
                          <button
                            onClick={() => handleRoleChange([])}
                            className="ml-2 flex items-center text-gray-500 hover:text-red-500"
                          >
                            <MdOutlineClose size={22} />
                          </button>
                        ) : (
                          <HiOutlineSelector size={22} />
                        )}
                      </Listbox.Button>

                      <Listbox.Options
                        className={`ring-black absolute mt-1 max-h-60 min-w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm ${
                          open ? "block" : "hidden"
                        }`}
                      >
                        {roleOptions.map((option, index) => (
                          <Listbox.Option
                            key={index}
                            value={option.name} // Send only the role name
                            className={({ active }) =>
                              `${
                                active
                                  ? "bg-green-500 text-white"
                                  : "text-gray-900"
                              } relative cursor-pointer select-none py-2 pl-3 pr-9`
                            }
                          >
                            {({ selected }) => (
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  readOnly
                                  checked={selected}
                                  className="form-checkbox w h-5 w-5 text-green-600"
                                />
                                <span
                                  className={`ml-3 block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {option.name}
                                </span>
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </>
                )}
              </Listbox>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={(e) =>
                  setFormData({ ...formData, phoneNumber: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Growth Call Enabled
              </label>
              <select
                name="growthCallEnabled"
                value={formData.growthCallEnabled}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    growthCallEnabled: e.target.value,
                  })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>

          {/* Conditionally render Onboarding fields if btpSubscriber is true */}
          {formData.btpSubscriber && (
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Onboarding Status
                </label>
                <select
                  name="onboardingStatus"
                  value={formData.subscriberOnboarding}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      subscriberOnboarding: e.target.value,
                    })
                  }
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Done by
                </label>
                <select
                  name="updatedBy"
                  value={formData.updatedBy}
                  onChange={(e) => handleOnboardingUserChange(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                >
                  <option value="">Select User</option>
                  {supportUsers.map((userInfo) => (
                    <option key={userInfo.id} value={userInfo.id}>
                      {userInfo.name || userInfo.email}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          <div>
            {loading ? (
              <div className="flex justify-center py-2">
                <SyncLoader size="8px" color="#22C55E" />
              </div>
            ) : (
              <button
                type="submit"
                className={`w-full rounded-md bg-green-600 px-4 py-2 text-white shadow-sm hover:bg-green-700`}
              >
                Update User
              </button>
            )}
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default EditUserModal;
