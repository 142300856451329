import React from "react";
import { FaRegClock, FaRobot } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import { MdCancel, MdMessage } from "react-icons/md";
import { SyncLoader } from "react-spinners";

function ServerStatus({
  readBotLastMsgTime,
  readBotRaiseAlert,
  writeBotLastMsgTime,
  writeBotRaiseAlert,
  lastServerSyncTime,
  lastBlockAlertSyncTime,
  loading,
}) {
  function formatDateTime(input) {
    const date = new Date(input);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  }

  const statusData = [
    {
      title: "Message sync status",
      lastSyncTime: lastServerSyncTime,
      lastMsgTime: readBotLastMsgTime,
      raiseAlert: readBotRaiseAlert,
      loading,
    },
    {
      title: "Block alerts",
      lastSyncTime: lastBlockAlertSyncTime,
      lastMsgTime: writeBotLastMsgTime,
      raiseAlert: writeBotRaiseAlert,
      loading,
    },
  ];

  return (
    <div className="flex items-center justify-between gap-x-12">
      {statusData.map((item, index) => (
        <div
          key={index}
          className={`w-1/2 space-y-4 rounded-lg bg-white px-6 py-2 shadow-[0px_5px_12px_0px_rgba(0,0,0,0.15)] ${
            item.raiseAlert && !item.loading && "blink-bg-red bg-red-400 text-white"
          } ${item.loading && "relative bg-gray-500 min-h-[7.5rem]"}`}
        >
          {item.loading ? (
            <SyncLoader
              color={"#fff"}
              size={10}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          ) : (
            <>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-4">
                  <FaRobot color="#2962FF" size={35} />
                  <div>
                    <p className="text-xl font-semibold">{item.title}</p>
                    {item.raiseAlert === true ? (
                      <p className="text-xs text-[#ff3b30]">Inactive</p>
                    ) : item.raiseAlert === false ? (
                      <p className="text-xs text-[#00c853]">Operational</p>
                    ) : null}
                  </div>
                </div>
                {item.raiseAlert === true ? (
                  <div className="flex h-max items-center gap-x-2 rounded-full bg-[#ffe9e9] py-2 px-3 text-xs font-semibold text-[#ff3b30]">
                    <MdCancel size={15} />
                    DOWN
                  </div>
                ) : item.raiseAlert === false ? (
                  <div className="flex h-max items-center gap-x-2 rounded-full bg-[#e8f5e9] py-2 px-3 text-xs font-semibold text-[#00c853]">
                    <FaCircleCheck />
                    UP
                  </div>
                ) : null}
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-4">
                  <FaRegClock
                    color={`${item.raiseAlert ? "#FFF" : "#0000008C"}`}
                    size={20}
                  />
                  <div>
                    <p
                      className={`text-sm ${
                        item.raiseAlert ? "text-white" : "text-[#0000008C]"
                      }`}
                    >
                      Last sync time
                    </p>
                    <p>{formatDateTime(item.lastSyncTime)}</p>
                  </div>
                </div>
                <div className="flex items-center gap-x-4">
                  <MdMessage
                    color={`${item.raiseAlert ? "#FFF" : "#0000008C"}`}
                    size={20}
                  />
                  <div>
                    <p
                      className={`text-sm ${
                        item.raiseAlert ? "text-white" : "text-[#0000008C]"
                      }`}
                    >
                      Last Message
                    </p>
                    <p>{formatDateTime(item.lastMsgTime)}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default ServerStatus;
