import React, { useState, useEffect } from "react";
import axios from "axios";
import { getToken } from "utils/getToken";
import { toast, ToastContainer } from "react-toastify";
import { Listbox } from "@headlessui/react";
import { HiOutlineSelector } from "react-icons/hi";
import { FaCheck } from "react-icons/fa";
import SyncLoader from "components/Loaders/SyncLoader";
import { formatToDateAndTime } from "utils/formatTime";
import { useSetRecoilState, useRecoilState } from "recoil";
import {
  whatsappSessionModalState,
  isSessionDetailsUpdatedState,
} from "store/whatsappSession/atoms/whatsappSessionAtomState";
import EditSessionModal from "./EditSessionModal/EditSessionModal";
import StartSessionModal from "./StartSessionModal/StartSessionModal";

const filterOptions = [
  { value: "created", name: "Created" },
  { value: "connected", name: "Connected" },
  { value: "disconnected", name: "Disconnected" },
  { value: "deleted", name: "Deleted" },
];

const WhatsappSessions = () => {
  const [whatsappSessionsData, setWhatsappSessionsData] = useState([]);
  const [fetchingSessionsData, setFetchingSessionsData] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const setWhatsappSessionModal = useSetRecoilState(whatsappSessionModalState);
  const [isSessionDetailsUpdated, setIsSessionDetailsUpdated] = useRecoilState(
    isSessionDetailsUpdatedState
  );

  const getWhatsappSessionsData = async () => {
    try {
      setFetchingSessionsData(true);
      const apiUrl =
        process.env.REACT_APP_API_URL + "whatsapp/get-all-sessions/";

      let statusFilters = [];
      let formattedStatusFilters;

      if (selectedFilters.length > 0) {
        statusFilters = selectedFilters.map((filter) => filter.value);
        formattedStatusFilters = `${statusFilters.join(",")}`;
      }

      const WhatsappSessionsResponse = await axios.get(apiUrl, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
        params: {
          status: selectedFilters.length > 0 ? formattedStatusFilters : null,
        },
      });

      if (WhatsappSessionsResponse.status === 200) {
        if (WhatsappSessionsResponse.data?.length > 0) {
          setWhatsappSessionsData(WhatsappSessionsResponse.data);
        } else {
          setWhatsappSessionsData([]);
        }
      } else {
        toast.error("Failed to fetch whatsapp sessions from the API.");
      }
    } catch (error) {
      console.error("WHATSAPP_SESSION_DATA_ERROR", error);
      toast.error("Something went wrong while fetching whatsapp sessions");
    } finally {
      setFetchingSessionsData(false);
    }
  };

  const handleRefresh = async () => {
    getWhatsappSessionsData();
  };

  useEffect(() => {
    getWhatsappSessionsData();
  }, [selectedFilters]);

  useEffect(() => {
    if (isSessionDetailsUpdated) {
      getWhatsappSessionsData();
      setIsSessionDetailsUpdated(false);
    }
  }, [isSessionDetailsUpdated]);

  return (
    <div className="mx-auto sm:w-full">
      {/* Live Chat section */}
      <div className="mb-5 py-6">
        {/* Header */}
        <div className="mr-1 flex items-center justify-between px-2 md:px-0">
          <h1 className="items-center justify-center text-center text-lg font-bold capitalize text-blue-500 dark:text-white md:text-2xl">
            Whatsapp Sessions
          </h1>
          <div className="flex items-center gap-3">
            <button
              onClick={() => {
                setWhatsappSessionModal({
                  open: true,
                  sessionId: null,
                  sessionDetails: null,
                  type: "add",
                });
              }}
              className="rounded-lg bg-red-500 px-3 py-2 text-white hover:bg-red-600 md:px-6"
            >
              Create Session
            </button>
            <button
              disabled={fetchingSessionsData}
              className="rounded-lg bg-blue-500 px-3 py-2 text-white hover:bg-blue-600 md:px-6"
              onClick={() => handleRefresh()}
            >
              Refresh
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="my-4 flex items-center">
          <div className="">
            {/* <span className="mr-2">Filter by Status:</span> */}
            <Listbox
              value={selectedFilters}
              onChange={setSelectedFilters}
              multiple
            >
              {({ open }) => (
                <>
                  <div className="relative inline-block text-left">
                    <Listbox.Button className="inline-flex w-full items-center justify-center gap-4 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                      {selectedFilters.length > 0
                        ? `${selectedFilters.length} Filter Selected`
                        : "Filter By Status"}
                      <HiOutlineSelector size="22" />
                    </Listbox.Button>
                  </div>
                  <Listbox.Options
                    className="absolute mt-1 max-h-60 min-w-[180px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
                    style={{ display: open ? "block" : "none" }}
                  >
                    {filterOptions.map((option, index) => (
                      <Listbox.Option
                        key={index}
                        value={option}
                        className={({ active }) =>
                          `${
                            active ? "bg-blue-500 text-white" : "text-gray-900"
                          }
                        relative cursor-pointer select-none py-2 pl-10 pr-4`
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`${
                                selected ? "font-semibold" : "font-normal"
                              }`}
                            >
                              {option.name}
                            </span>
                            {selected ? (
                              <span
                                className={`${
                                  active ? "text-white" : "text-blue-500"
                                }
                              absolute inset-y-0 left-0 flex items-center pl-3`}
                              >
                                <FaCheck size="15" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </>
              )}
            </Listbox>
          </div>
        </div>
        {/* Filters end */}

        {!fetchingSessionsData && whatsappSessionsData.length > 0 ? (
          <div className="min-h-[60vh] max-w-full overflow-y-auto">
            <table className="w-full bg-white shadow-md">
              <thead>
                <tr className="bg-blue-200">
                  <th className="border px-4 py-2">Session Name</th>
                  <th className="border px-4 py-2">Description</th>
                  <th className="border px-4 py-2">Type</th>
                  <th className="border px-4 py-2">Phone Number</th>
                  <th className="border px-4 py-2">Status</th>
                  <th className="border px-4 py-2">Created At</th>
                  <th className="border px-4 py-2">Last Login</th>
                  <th className="border px-4 py-2">Last Used</th>
                  <th className="border px-4 py-2">Last Updated</th>
                  <th className="border px-4 py-2">Edit Session</th>
                </tr>
              </thead>
              <tbody>
                {whatsappSessionsData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="min-w-[150px] border px-2 py-2">
                      {row?.session_name || ""}
                    </td>
                    <td className="min-w-[200px] border px-4 py-2">
                      {row?.description || ""}
                    </td>
                    <td className="min-w-[200px] border px-4 py-2">
                      {row?.type.length > 0 ? row.type.join(", ") : ""}
                    </td>
                    <td className="min-w-[150px] border px-4 py-2">
                      {row?.phone_number || ""}
                    </td>
                    <td className="min-w-[150px] border px-4 py-2">
                      {row?.status || ""}
                    </td>

                    <td className="min-w-[200px] border px-4 py-2">
                      {row?.created_at
                        ? formatToDateAndTime(row.created_at)
                        : ""}
                    </td>
                    <td className="min-w-[200px] border px-4 py-2">
                      {row?.last_login_at
                        ? formatToDateAndTime(row.last_login_at)
                        : ""}
                    </td>
                    <td className="min-w-[200px] border px-4 py-2">
                      {row?.last_used_at
                        ? formatToDateAndTime(row.last_used_at)
                        : ""}
                    </td>
                    <td className="min-w-[200px] border px-4 py-2">
                      {row?.updated_at
                        ? formatToDateAndTime(row.updated_at)
                        : ""}
                    </td>
                    <td className="min-w-[100px] border px-4 py-2">
                      <div className="flex items-center justify-center">
                        <button
                          onClick={() => {
                            setWhatsappSessionModal({
                              open: true,
                              sessionId: row.id,
                              sessionDetails: row,
                              type: "edit",
                            });
                          }}
                          className="rounded-md bg-blue-400 py-1.5 px-3 font-light text-white"
                        >
                          Edit
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : fetchingSessionsData ? (
          <div className="flex h-[70vh] items-center justify-center">
            <SyncLoader color={"#525CEB"} size={10} />
          </div>
        ) : (
          <div className="flex h-full min-h-[60vh] w-full items-center justify-center">
            <p className="text-center text-lg font-semibold">
              No Sessions Data available
            </p>
          </div>
        )}

        {/* Pagination */}
        {/* {data.length > 0 && (
          <div className="mt-10 flex flex-col justify-center md:mt-20 md:flex-row">
            <div className="flex justify-center md:ml-auto">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-flex h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Previous Page
              </button>
              {totalPages > 1 &&
            [
              ...Array(Math.min(numberOfButtons, totalPages - currentPage + 1)),
            ].map((_, index) => {
              const pageNumber = currentPage + index;
              return (
                <button
                  key={index}
                  onClick={() => setCurrentPage(pageNumber)}
                  disabled={pageNumber > totalPages}
                  className={`ml-2 rounded-lg px-4 py-2 ${
                    currentPage === pageNumber
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })}

              <h3 className="flex items-center px-2 text-lg text-gray-800 ">
                Page {currentPage}
              </h3>

              <button
                onClick={handleNextPage}
                // disabled={currentPage === totalPages}
                className="mx-2 rounded-lg bg-blue-500 px-4 py-4 text-white hover:bg-blue-600 md:px-8"
              >
                Next Page
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-flex h-5 w-5 -rotate-180 transform"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-5 flex items-center justify-end md:ml-auto md:mt-0">
              <input
                type="text"
                placeholder={`Go to page (1 - ${totalPages})`}
                value={filterPage}
                onChange={(e) => setFilterPage(e.target.value)}
                className="w-full rounded-md border border-gray-400 bg-white py-2 pl-8 pr-2 text-sm font-medium text-gray-800 outline-none"
              />
              <button
                className="mx-2 cursor-pointer rounded-lg bg-blue-500 px-4 py-3 text-white hover:bg-blue-300"
                onClick={handlePageSearch}
              >
                <FiSearch
                  className="h-4 w-4 cursor-pointer rounded-lg"
                  onClick={handlePageSearch}
                />
              </button>
            </div>
          </div>
        )} */}
      </div>

      {/* Session Update/Create Modal */}
      <EditSessionModal />

      {/* Session Start Modal */}

      <StartSessionModal />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default WhatsappSessions;
