import React from "react";
import { SyncLoader } from "react-spinners";

const Loader = ({ size, color }) => {
  const defaultSize = "10px";
  const defaultColor = "#F1EFEF";
  return (
    <div className="flex w-full justify-center px-2 py-2">
      <SyncLoader color={color || defaultColor} size={size || defaultSize} />
    </div>
  );
};

export default Loader;
