import { useRef, useEffect, useState } from "react";

import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";

import { useSearchParams } from "react-router-dom";
const generateRandomID = (len) => {
  let result = "";
  var chars = "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP";
  var maxPos = chars.length;
  for (var i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
};

const Videocall = () => {
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get("roomID");

  const [roomId, setRoomId] = useState(paramValue);

  const zegoRef = useRef(null);
  const zegoInstanceRef = useRef(null);

  useEffect(() => {
    return () => {
      // Cleanup function to destroy ZegoCloud instance
      if (zegoInstanceRef.current) {
        zegoInstanceRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (roomId) {
      myMeeting(zegoRef, roomId);
    }
  }, [roomId]);

  const myMeeting = (element, roomID) => {
    const appID = 1064396057;

    if (isNaN(appID)) {
      throw new Error(
        "Invalid REACT_APP_ZEGO_CLOUD_APP_ID: Must be a valid integer"
      );
    }
    const serverSecret = process.env.REACT_APP_ZEGO_CLOUD_SERVER_SECRET;
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomID,
      generateRandomID(5),
      "SupportAgent"
    );

    zegoInstanceRef.current = ZegoUIKitPrebuilt.create(kitToken);
    zegoInstanceRef.current.joinRoom({
      container: element.current,
      sharedLinks: [
        {
          name: "Personal link",
          url:
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            "?roomID=" +
            roomID,
        },
      ],
      scenario: {
        mode: ZegoUIKitPrebuilt.GroupCall,
      },
      layout: "Grid",
    });
  };

  return (
    <div className="h-screen w-full">
      {roomId && (
        <div
          className="myCallContainer"
          ref={zegoRef}
          style={{ width: "100%", height: "100%" }}
        ></div>
      )}
    </div>
  );
};

export default Videocall;
