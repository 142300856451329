import React, { useEffect, useState } from "react";
import { MdDelete, MdCheck } from "react-icons/md";
import { parseISO, format } from "date-fns";
import axios from "axios";
import { getToken } from "utils/getToken";
import { ClipLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";

function JobsStatus(props) {
  const [jobsBeingDeleted, setJobsBeingDeleted] = useState([]);
  const [rowVisibility, setRowVisibility] = useState({});
  const [rowVisibilityForGroupNames, setRowVisibilityForGroupNames] = useState(
    {}
  );
  const [messageVisibility, setMessageVisibility] = useState({});
  const [mediaVisibility, setMediaVisibility] = useState({});
  const [displayGroupsCount, setDisplayGroupsCount] = useState(1);
  const { handleRefresh } = props;
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleDeleteClick = async (id) => {
    try {
      setJobsBeingDeleted([...jobsBeingDeleted, id]);
      const url = process.env.REACT_APP_API_URL + "whatsapp/edit-job/";
      const formData = new FormData();
      formData.append("job_id", id);
      formData.append("status", "deleted");
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${getToken()}`,
        },
      });
      console.log(response);

      fetchData();
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setJobsBeingDeleted((prevJobs) =>
        prevJobs.filter((jobIdToDelete) => jobIdToDelete !== id)
      ); // Remove the job from being deleted, whether the request was successful or not
    }
  };
  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      const url = process.env.REACT_APP_API_URL + "whatsapp/get-message-job/";

      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      });
      console.log(response.data);
      setData([...response.data]);
    } catch (error) {
      console.log("Error fetching data:", error);
      toast.error("Something went wrong!");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    props.onRef(fetchData);
    return () => {
      props.onRef(null);
    };
  }, [props]);
  const handleToggleMediaVisibility = (rowIndex) => {
    setMediaVisibility((prevVisibility) => ({
      ...prevVisibility,
      [rowIndex]: !prevVisibility[rowIndex],
    }));
  };
  const handleToggleRowVisibility = (rowIndex) => {
    setRowVisibility((prevVisibility) => ({
      ...prevVisibility,
      [rowIndex]: !prevVisibility[rowIndex],
    }));
  };
  const handleToggleMessageVisibility = (rowIndex) => {
    setMessageVisibility((prevVisibility) => ({
      ...prevVisibility,
      [rowIndex]: !prevVisibility[rowIndex],
    }));
  };

  const dateTimeFormateHandler = (date) => {
    const time = new Date(date);
    let hours = time.getHours();
    let minutes = time.getMinutes();

    // Determine whether it's AM or PM
    var ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (12 AM)

    // Pad minutes with leading zero if necessary
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const timeInAMPM = hours + ":" + minutes + " " + ampm;
    const newDate = dateFormateHandler(date);
    return `${newDate} at ${timeInAMPM}`;
  };

  const dateFormateHandler = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth();
    const year = newDate.getFullYear();
    return `${day}-${months[month]}-${year}`;
  };

  const handleToggleRowVisibilityForGroupNames = (rowIndex) => {
    setRowVisibilityForGroupNames((prevVisibility) => ({
      ...prevVisibility,
      [rowIndex]: !prevVisibility[rowIndex],
    }));
  };

  const showMore = () => {
    setDisplayGroupsCount((prevCount) => prevCount + 10); // Increase count by 10
  };

  const showLess = () => {
    setDisplayGroupsCount((prevCount) => Math.max(prevCount - 10, 0)); // Decrease count by 10, but keep a minimum of 10
  };

  return (
    <div className="mx-auto overflow-x-scroll bg-white px-4 md:mx-0 md:w-full md:p-0">
      {/* // Desktop View Data  */}
      <table className="  hidden  max-h-[80vh] max-w-[90vw] table-auto border-collapse text-sm  md:block">
        <thead>
          <tr className="bg-blue-200">
            <th className="border px-4 py-2">Job Name</th>
            <th className="border px-4 py-2">Content Type</th>

            <th className="border px-4 py-2">Content</th>
            <th className="border px-4 py-2">Sender Phone</th>
            <th className="border px-4 py-2">Sent-Via</th>
            <th className="border px-4 py-2">Media File Path</th>
            <th className="border px-4 py-2">Status</th>

            <th className="border px-4 py-2">Success - Failed</th>

            {/* <th className="border px-4 py-2">Created At</th> */}
            <th className="border px-4 py-2">Scheduled At</th>
            <th className="border px-4 py-2">Est. Completion Time</th>
            <th className="border px-4 py-2"> Completed At</th>
            <th className="border px-4 py-2">Recipient Names</th>
            <th className="border px-4 py-2"> Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-100">
                <td className="border px-4 py-2">
                  {row.job_name ? row.job_name : ""}
                </td>
                <td className="border px-4 py-2">
                  {row.content_type ? row.content_type : ""}
                </td>

                <td className="border px-4 py-2">
                  {row.content
                    ? `${row.content.substring(
                        0,
                        messageVisibility[rowIndex] ? undefined : 25
                      )}`
                    : ""}
                  {""}
                  {row.content && row.content.length > 25 ? "..." : ""}
                  {row.content && row.content.length > 25 && (
                    <button
                      className="text-blue-500 underline"
                      onClick={() => handleToggleMessageVisibility(rowIndex)}
                    >
                      {messageVisibility[rowIndex] && row.content.length > 25
                        ? "less"
                        : "more"}
                    </button>
                  )}
                </td>
                <td className="border px-4 py-2">
                  {row.sender_phone ? row.sender_phone : ""}
                </td>
                <td className="border px-4 py-2">
                  {row.send_via ? row.send_via : ""}
                </td>
                <td className="border px-4 py-2">
                  {row.media_file_path
                    ? `${row.media_file_path.substring(
                        0,
                        mediaVisibility[rowIndex] ? undefined : 25
                      )}...`
                    : ""}
                  {row.media_file_path && row.media_file_path.length > 25 && (
                    <button
                      className="text-blue-500 underline"
                      onClick={() => handleToggleMediaVisibility(rowIndex)}
                    >
                      {mediaVisibility[rowIndex] &&
                      row.media_file_path.length > 25
                        ? "less"
                        : "more"}
                    </button>
                  )}
                </td>

                <td className=" border px-4 py-2">
                  {row.status ? row.status : ""}
                </td>
                <td className=" border px-4 py-2 text-center">
                  <p>
                    <b className="text-green-500">
                      {" "}
                      {row.status_count && row.status_count.success
                        ? row.status_count.success
                        : "0"}{" "}
                    </b>
                    -{" "}
                    <b className="text-red-500">
                      {" "}
                      {row.status_count && row.status_count.failed
                        ? row.status_count.failed
                        : "0"}
                    </b>
                  </p>
                  <p>
                    Total:{" "}
                    <b>{row.recipient_names && row.recipient_names.length}</b>
                  </p>
                </td>

                {/* <td className="border px-4 py-2">
                  {row.created_at
                    ? format(parseISO(row.created_at), "dd-MM-yyyy HH:mm:ss")
                    : "N/A"}{" "}
                </td> */}
                <td className="border px-4 py-2">
                  {row.scheduled_at
                    ? dateTimeFormateHandler(row.scheduled_at)
                    : "N/A"}{" "}
                </td>
                <td className=" border px-4 py-2">
                  {row.estimated_completion_time
                    ? row.estimated_completion_time
                    : ""}
                  {" Min"}
                </td>
                <td className="border px-4 py-2">
                  {row.completed_at
                    ? dateTimeFormateHandler(row.completed_at)
                    : "N/A"}{" "}
                </td>
                <td className="border px-4 py-2">
                  {row.recipient_names && row.recipient_names.length > 0
                    ? row.recipient_names
                        .slice(
                          0,
                          rowVisibilityForGroupNames[rowIndex] &&
                            displayGroupsCount > 1
                            ? displayGroupsCount
                            : 1
                        )
                        .map((name, index) => (
                          <p key={index}>{`${index + 1}. ${name}`}</p>
                        ))
                    : ""}
                  {row.recipient_names &&
                    row.recipient_names.length > 1 &&
                    (displayGroupsCount === 1 ||
                      !rowVisibilityForGroupNames[rowIndex]) && (
                      <button
                        className="text-blue-500 underline"
                        onClick={() => {
                          setDisplayGroupsCount(1);
                          showMore(rowIndex);
                          handleToggleRowVisibilityForGroupNames(rowIndex);
                        }}
                      >
                        {"more"}
                      </button>
                    )}
                  {rowVisibilityForGroupNames[rowIndex] &&
                    displayGroupsCount < row.recipient_names.length &&
                    displayGroupsCount > 1 && (
                      <button
                        className="mr-1 text-blue-500 underline"
                        onClick={() => showMore(rowIndex)}
                      >
                        {"more"}
                      </button>
                    )}
                  {rowVisibilityForGroupNames[rowIndex] &&
                    displayGroupsCount > 1 && (
                      <button
                        className="text-blue-500 underline"
                        onClick={() =>
                          displayGroupsCount > row.recipient_names.length ||
                          displayGroupsCount === row.recipient_names.length
                            ? (handleToggleRowVisibilityForGroupNames(rowIndex),
                              showLess(rowIndex))
                            : showLess(rowIndex)
                        }
                      >
                        {"less"}
                      </button>
                    )}
                </td>
                <td className="mr-2 border px-4 py-2">
                  {row.status === "pending" ? (
                    jobsBeingDeleted.includes(row.id) ? (
                      <ClipLoader color={"blue"} size={20} />
                    ) : (
                      <MdDelete
                        onClick={() => handleDeleteClick(row.id)}
                        className="cursor-pointer text-center text-2xl text-red-500"
                      />
                    )
                  ) : (
                    <MdCheck className=" text-center text-2xl text-green-500" />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="border px-4 py-2" colSpan="6">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default JobsStatus;
