import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Listbox } from "@headlessui/react";
import { useRecoilState } from "recoil";
import { FiSearch } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "assets/css/filter.css";
import { HiExternalLink, HiOutlineSelector } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { ClipLoader } from "react-spinners";
import "../../assets/css/Blink.css";
import { formatTimeInMinutes } from "utils/formatTimeInMinutes";
import { formatToDateAndTime } from "utils/formatTime";
import {
  supportTaskModalState,
  isTasksUpdatedState,
} from "store/supportTasks/atoms/supportTasksState";
import IframeModal from "components/ChatStatus/IframeModal";
import UserDataPopup from "components/UserData/UserDataPopup";
import CreateTaskModal from "components/SupportTasks/CreateTaskModal/CreateTaskModal";
import { getToken } from "utils/getToken";
import { mobileNumberState } from "store/appointment/atoms/appointmentAtomState";

const getFormattedBackendDate = (date) => {
  if (!date) return null;
  const scheduledAtFromBackend = date;

  // Create a new Date object using the date string
  const dateObject = new Date(scheduledAtFromBackend);

  // Format the date according to the expected format ('yyyy-MM-ddTHH:mm')
  const formattedDate = `${dateObject.getFullYear()}-${(
    "0" +
    (dateObject.getMonth() + 1)
  ).slice(-2)}-${("0" + dateObject.getDate()).slice(-2)}T${(
    "0" + dateObject.getHours()
  ).slice(-2)}:${("0" + dateObject.getMinutes()).slice(-2)}`;

  return formattedDate;
};

const taskTypeOptions = [
  { value: "all", name: "All" },
  { value: "followup", name: "Followup" },
  { value: "custom_task", name: "Custom Task" },
  { value: "wa_tagged", name: "Whatsapp Tagged" },
  { value: "callback_request", name: "Callback request" },
  { value: "subscription_booked", name: "Subscription Booked" },
  { value: "history_form_not_filled", name: "Hitsory Form Not Filled" },
  { value: "webinar_registration", name: "Webinar Registration"},
  { value: "subscription_expired", name: "Subscription Expired" },
  { value: "subscription_lead", name: "Subscription Lead" },
];

const SupportTasks = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPage, setFilterPage] = useState("");
  const [statusFilter, setStatusFilter] = useState(["pending"]);
  const [isChatStatusModalOpen, setIsChatStatusModalOpen] = useState(false);
  const [isChatStatusSaveButtonDisabled, setIsChatStatusSaveButtonDisabled] =
    useState(data.map(() => false));
  const [isFilterStatusMenuOpen, setIsFilterStatusMenuOpen] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);
  const [newStatus, setNewStatus] = useState("default");
  const [currentChatId, setCurrentChatId] = useState(null);
  const [chatIndex, setChatIndex] = useState(null);
  const [
    isChatStatusModalOpenSaveButttonDisable,
    setIsChatStatusModalOpenSaveButttonDisable,
  ] = useState(false);
  const [sortingMethod, setSortingMethod] = useState("asc");
  const [selectedUser, setSelectedUser] = useState(null);
  const [mobileNumber, setMobileNumber] = useRecoilState(mobileNumberState);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [isSearchButtonLoading, setIsSearchButtonLoading] = useState(false);
  const [isPDataModalOpen, setIsPDataModalOpen] = useState(false);
  const [supportTaskModal, setSupportTaskModal] = useRecoilState(
    supportTaskModalState
  );
  const [isTasksUpdated, setIsTasksUpdated] =
    useRecoilState(isTasksUpdatedState);
  const [token, setToken] = useState(getToken());
  const [selectedTypeFilters, setSelectedTypeFilters] = useState([
    taskTypeOptions[4],
    taskTypeOptions[3],
    taskTypeOptions[2],
    taskTypeOptions[1],
    taskTypeOptions[5],
    taskTypeOptions[6],
    taskTypeOptions[7],
  ]);

  const retrieveConversation = async (retries = 0) => {
    try {
      setIsPageLoading(true);
      const formattedStatus = statusFilter.join(",");
      const queryParams = {
        status: statusFilter.length > 0 ? formattedStatus : null,
      };
      if (sortingMethod !== null && sortingMethod !== "default") {
        queryParams.sort_by = sortingMethod;
      }

      let taskTypeFilters = [];
      let formattedTaskTypeFilters;

      if (selectedTypeFilters.length > 0) {
        selectedTypeFilters.forEach((filter) => {
          if (filter.value !== "all") {
            taskTypeFilters.push(filter.value);
          }
        });
        formattedTaskTypeFilters = `${taskTypeFilters.join(",")}`;
      }

      if (formattedTaskTypeFilters) {
        queryParams.type = !selectedTypeFilters.includes(taskTypeOptions[0])
          ? formattedTaskTypeFilters
          : null;
      }

      if (filterChanged) {
        setCurrentPage(1);
      }
      if (currentPage) {
        queryParams.page = filterChanged ? 1 : currentPage;
      }
      if(mobileNumber){
        queryParams.mobile = mobileNumber || "";
      }
      const apiUrl = process.env.REACT_APP_API_URL + "tasks/get-support-tasks/";
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        params: queryParams,
      });
      if (filterChanged) {
        setFilterChanged(false);
      }
      setIsSearchButtonLoading(false);
      if (response.status === 200) {
        if (response.data.data.length === 0) {
          toast.error("No data found for the selected filters.");
          setData([]);
        } else {
          setData(response.data.data);
          const count = response.data.data.filter(
            (item) => item.status === "pending"
          ).length;
          setPendingTasksCount(count);
          setIsPageLoading(false);
        }
      } else {
        toast.error("Failed to fetch data from the API.");
        setIsPageLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsPageLoading(false);
      toast.error("Error fetching data from the API.");
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    retrieveConversation();
  }, [currentPage, statusFilter, sortingMethod, selectedTypeFilters]);

  useEffect(() => {
    if (isTasksUpdated) {
      retrieveConversation();
      setIsTasksUpdated(false);
    }
  }, [isTasksUpdated]);

  // For filters

  // For status filter
  const handleStatusFilterChange = (value) => {
    setStatusFilter((prevStatusFilter) => {
      if (value === "all") {
        if (
          prevStatusFilter.includes("done") &&
          prevStatusFilter.includes("pending")
        ) {
          // If any of them is selected, deselect all
          return prevStatusFilter.filter(
            (item) => item !== "pending" && item !== "done"
          );
        } else {
          // If none of them are selected, select all
          setFilterChanged(true);
          return ["pending", "done"];
        }
      } else {
        if (prevStatusFilter.includes(value)) {
          return prevStatusFilter.filter((item) => item !== value);
        } else {
          setFilterChanged(true);
          return [...prevStatusFilter, value];
        }
      }
    });
  };

  // Close statusfilter on clicking outside
  const filterStatusMenuRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterStatusMenuRef.current &&
        !filterStatusMenuRef.current.contains(event.target)
      ) {
        setIsFilterStatusMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // For ascending/descending sort
  const handleSortChange = (value) => {
    setSortingMethod(value);
    setFilterChanged(true);
  };

  const handlePageSearch = () => {
    const pageNumber = parseInt(filterPage);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    window.scrollTo(0, 0);
    setData([]);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    setData([]);
    window.scrollTo(0, 0);
  };

  // For changing status
  const handleChatStatusChangeEdit = (taskId, currentStatus, rowIndex) => {
    setIsChatStatusModalOpen(true);
    setCurrentChatId(taskId);
    setChatIndex(rowIndex);
    setNewStatus(currentStatus);
  };

  const handleChatStatusChange = async (taskId, currentStatus, rowIndex) => {
    const url = process.env.REACT_APP_API_URL + "tasks/edit-task-status/";
    const payload = {
      status: currentStatus,
      task_id: taskId,
    };
    try {
      setIsPageLoading(true);
      const updatedDisabledState = [...isChatStatusSaveButtonDisabled];
      updatedDisabledState[rowIndex] = true;
      setIsChatStatusSaveButtonDisabled(updatedDisabledState);
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      // console.log(response);
      // console.log(response.data);
      if (response.status === 200) {
        const itemIndex = data.findIndex((item) => item.id === taskId);
        // console.log("itemIndex", itemIndex);

        if (itemIndex !== -1) {
          // Create a copy of the data array with the updated task_status
          const updatedData = [...data];
          updatedData[itemIndex].status = response.data.task_status;
          updatedData[itemIndex].updated_at = response.data.updated_at;
          // Update the state with the new data
          setData(updatedData);
          updatedDisabledState[rowIndex] = false;
          setIsChatStatusSaveButtonDisabled(updatedDisabledState);
          setIsChatStatusModalOpen(false);
          toast.success("Chat status updated successfully");
        }
      }
    } catch (error) {
      console.log("Error updating data:", error);
      const updatedDisabledState = [...isChatStatusSaveButtonDisabled];

      updatedDisabledState[rowIndex] = false;
      setIsChatStatusSaveButtonDisabled(updatedDisabledState);

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Error updating data:");
      }
    } finally {
      setIsPageLoading(false);
    }
  };

  const timeAgo = (dateTime) => {
    const messageDate = new Date(dateTime);
    const now = new Date();
    const differenceInMinutes = Math.round((now - messageDate) / 60000);

    if (differenceInMinutes < 60) {
      return `${differenceInMinutes} minutes`;
    } else {
      // Handle hours or days if needed
      const differenceInHours = Math.round(differenceInMinutes / 60);
      if (differenceInHours < 24) {
        return `${differenceInHours} hours`;
      } else {
        const differenceInDays = Math.round(differenceInHours / 24);
        return `${differenceInDays} days`;
      }
    }
  };

  // For navigating to that particular chat
  // For navigating to that particular chat
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  function createLinkAndOpenChat(row, rowIndex) {
    if (!row.scheduled_at) {
      return "";
    }

    const chatStartTime = new Date(row.scheduled_at);
    const startTimeOffset = chatStartTime.getTimezoneOffset();

    const lastMessageTime = new Date(row.scheduled_at);
    const lastMessageTimeOffset = lastMessageTime.getTimezoneOffset();
    const startTime = new Date(
      chatStartTime.getTime() - 10 * 60 * 1000 - startTimeOffset * 60 * 1000
    );
    const endTime = new Date(
      lastMessageTime.getTime() +
        10 * 60 * 1000 -
        lastMessageTimeOffset * 60 * 1000
    );
    const startTimeISO = startTime.toISOString().slice(0, 19);
    const endTimeISO = endTime.toISOString().slice(0, 19);
    console.log(startTimeISO, endTimeISO);

    const url = `/admin/whatsapp-chat?group_id=${
      row.all_details.whatsappgroup_id
    }&start_time=${startTimeISO}&end_time=${endTimeISO}&mobile_number=${row.all_details.user_details.user_mobile.slice(
      1
    )}`;

    setHighlightedRow(rowIndex);
    setModalContent({ url });
  }

  const closeModal = () => {
    setModalContent(null);
  };

  const refreshData = () => {
    retrieveConversation();
  };

  // For chat count
  const [pendingTasksCount, setPendingTasksCount] = useState(0);
  const currentTime = new Date();
  const [serverSyncTime, setServerSyncTime] = useState(currentTime);

  const timeDifference = serverSyncTime
    ? currentTime - new Date(serverSyncTime)
    : null;

  const isTimeDifferenceExceeded =
    timeDifference && timeDifference > 15 * 60 * 1000;

  const handlePDataModal = (row) => {
    setSelectedUser(row?.all_details?.user_details?.user_mobile);
    // console.log(row.Chat_started_by);
    setIsPDataModalOpen(true);
  };

  const handlePDataModalClose = () => {
    setIsPDataModalOpen(false);
    setSelectedUser(null);
  };

  const handleTaskTypeFilterChange = (newSelectedStatuses) => {
    if (newSelectedStatuses.includes(taskTypeOptions[0])) {
      if (
        newSelectedStatuses.length === taskTypeOptions.length - 1 &&
        selectedTypeFilters.includes(taskTypeOptions[0])
      ) {
        setSelectedTypeFilters(
          newSelectedStatuses.filter((status) => status.value !== "all")
        );
        setFilterChanged(true);
      } else {
        setSelectedTypeFilters(taskTypeOptions);
        setFilterChanged(true);
      }
    } else if (
      !selectedTypeFilters.includes(taskTypeOptions[0]) &&
      newSelectedStatuses.length >= taskTypeOptions.length - 1
    ) {
      setSelectedTypeFilters(taskTypeOptions);
      setFilterChanged(true);
    } else if (
      selectedTypeFilters.includes(taskTypeOptions[0]) &&
      newSelectedStatuses.length >= taskTypeOptions.length - 1
    ) {
      setSelectedTypeFilters([]);
      setFilterChanged(true);
    } else {
      setSelectedTypeFilters(newSelectedStatuses);
      setFilterChanged(true);
    }
  };

  const handleMobileNumberChange = (e) => {
    const mobileNumberData = e.target.value.replace(
      /[^\d+]/g,
      ""
    );
    setMobileNumber(mobileNumberData);
    setIsSearchButtonClicked(true);
  };

  const handleSearchButtonClick = () => {
    setIsSearchButtonLoading(true);
    setCurrentPage(1);

    if (mobileNumber.length < 7) {
      toast.error("Please Enter the Correct Mobile number!");
      setIsSearchButtonLoading(false);
      console.log("Invalid Mobile");
      return;
    }
    retrieveConversation();
  };
  useEffect(() => {
    setMobileNumber("");
  }, []);

  return (
    <>
      <CreateTaskModal />

      <div className="mx-auto sm:w-full">
        {modalContent && (
          <IframeModal onClose={closeModal} url={modalContent.url} />
        )}

        {isPDataModalOpen && (
          <UserDataPopup
            selectedUser={selectedUser}
            noSearch={true}
            handlePDataModalClose={handlePDataModalClose}
          />
        )}

        <div className="flex items-center justify-center pt-2">
          <h1
            className={`text-lg font-semibold ${
              isTimeDifferenceExceeded
                ? "animate-blink text-red-500"
                : "text-green-500"
            }`}
          >
            {isTimeDifferenceExceeded && (
              <>
                <span className={` text-red-500`}>⚠️</span>
                Last message synced on server:{" "}
                {formatTimeInMinutes(serverSyncTime)}
                <span className="text-red-500 ">⚠️</span>
                Contact Tech Team.
              </>
            )}
            {!isTimeDifferenceExceeded && (
              <>
                Last message synced on server:{" "}
                {formatTimeInMinutes(serverSyncTime)}
              </>
            )}
          </h1>
        </div>
        {/* Live Chat section */}
        <div className="py-6 mb-5">
          {/* Header */}
          <div className="flex justify-between mr-1">
            <h1 className="items-center justify-center text-2xl font-bold text-center text-blue-500 capitalize dark:text-white">
              Pending Tasks
            </h1>
            <button
              className={`rounded-lg px-8 py-2 text-white ${
                pendingTasksCount > 0
                  ? "bg-red-500 hover:bg-red-600"
                  : "bg-green-500 hover:bg-green-600"
              }`}
            >
              Total Pending Tasks: {pendingTasksCount}
            </button>

            <div className="flex items-center gap-3">
              <button
                onClick={() => {
                  setSupportTaskModal({
                    open: true,
                    taskId: null,
                    taskDetails: null,
                    type: "add",
                  });
                }}
                className="px-3 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600 md:px-6"
              >
                Create Task
              </button>

              <button
                className="px-8 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                onClick={() => refreshData()}
              >
                Refresh
              </button>
            </div>
          </div>

          {/* Filters */}
          <div className="flex flex-col py-4 md:flex-row md:justify-between ">
            {/* Filter using task type */}

            {/* Mobile search */}
            <div className="flex items-center justify-center gap-2 px-2 py-1 bg-white border border-gray-300 rounded-md shadow-md w-72 dark:border-navy-700 dark:bg-navy-800">
              <input
                type="text"
                placeholder="User Phone Number"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                className="flex-grow h-10 px-3 text-sm rounded-md outline-none bg-50 text-navy-700 placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-white"
              />
              <button
                className="px-4 py-2 text-white bg-blue-500 rounded-md shadow hover:bg-blue-600 disabled:opacity-50"
                onClick={handleSearchButtonClick}
                disabled={isSearchButtonLoading}
              >
                {isSearchButtonLoading ? "Loading..." : "Search"}
              </button>
            </div>

            <div className="">
              {/* <span className="mr-2">Filter by Status:</span> */}
              <Listbox
                value={selectedTypeFilters}
                onChange={handleTaskTypeFilterChange}
                multiple
              >
                {({ open }) => (
                  <>
                    <div className="relative inline-block text-left">
                      <Listbox.Button className="inline-flex items-center justify-center w-full gap-4 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                        {selectedTypeFilters.length > 0
                          ? `${selectedTypeFilters.length} Filter Selected`
                          : "Filter By Type"}
                        <HiOutlineSelector size="22" />
                      </Listbox.Button>
                    </div>
                    <Listbox.Options
                      className="absolute mt-1 max-h-60 min-w-[180px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
                      style={{ display: open ? "block" : "none" }}
                    >
                      {taskTypeOptions.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          value={option}
                          className={({ active }) =>
                            `${
                              active
                                ? "bg-blue-500 text-white"
                                : "text-gray-900"
                            }
                      relative cursor-pointer select-none py-2 pl-4 pr-4`
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center gap-3">
                                <input
                                  type="checkbox"
                                  checked={selected ? true : false}
                                  className="cursor-pointer"
                                />
                                <span
                                  className={`${
                                    selected ? "font-semibold" : "font-normal"
                                  }`}
                                >
                                  {option.name}
                                </span>
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </>
                )}
              </Listbox>
            </div>

            {/* Filter by status */}
            <div className="flex flex-col">
              <div
                className="flex w-[80vw] flex-col rounded-xl md:w-auto"
                ref={filterStatusMenuRef}
              >
                <button
                  id="dropdownCheckboxButton"
                  className={`focus:ring-primary ${
                    statusFilter.length > 0 ? "text-green-500" : "text-black"
                  } relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base ring-opacity-5 transition-transform hover:bg-gray-50 focus:outline-none focus:ring-1`}
                  type="button"
                  onClick={() =>
                    setIsFilterStatusMenuOpen(!isFilterStatusMenuOpen)
                  }
                >
                  {statusFilter.length > 0 ? (
                    <>
                      {statusFilter.length} Status Selected
                      <svg
                        className={`ml-2.5 h-2.5 w-2.5 transform ${
                          isFilterStatusMenuOpen ? "rotate-180" : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                      <span
                        className="ml-2 text-red-500 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setStatusFilter([]);
                        }}
                      >
                        <MdCancel />
                      </span>
                    </>
                  ) : (
                    <>
                      Filter By Status
                      <svg
                        className={`ml-2.5 h-2.5 w-2.5 transform ${
                          isFilterStatusMenuOpen ? "rotate-180" : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </>
                  )}
                </button>

                {isFilterStatusMenuOpen && (
                  <div
                    id="dropdownDefaultCheckbox"
                    className="absolute z-10 mt-11 w-[163px] rounded-lg bg-white shadow ring-1 ring-opacity-5 transition-transform focus:outline-none dark:divide-gray-600 dark:bg-gray-700"
                  >
                    <ul
                      className="p-3 space-y-3 text-sm text-black dark:text-gray-200"
                      aria-labelledby="dropdownCheckboxButton"
                    >
                      <li>
                        <div className="flex items-center">
                          <input
                            id="checkbox-item-all"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                            value="all"
                            onChange={() => handleStatusFilterChange("all")}
                            checked={
                              statusFilter.includes("pending") &&
                              statusFilter.includes("done")
                            }
                          />
                          <label
                            htmlFor="checkbox-item-live-chat"
                            className="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            All
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <input
                            id="checkbox-item-live-chat"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                            value="live-chat"
                            onChange={() => handleStatusFilterChange("pending")}
                            checked={statusFilter.includes("pending")}
                          />
                          <label
                            htmlFor="checkbox-item-live-chat"
                            className="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Pending Tasks
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <input
                            id="checkbox-item-2"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                            value="done"
                            onChange={() => handleStatusFilterChange("done")}
                            checked={statusFilter.includes("done")}
                          />
                          <label
                            htmlFor="checkbox-item-2"
                            className="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Closed Tasks
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* Ascending/ descending filter */}
            <div className="relative inline-flex items-center">
              <select
                id="ascendingDescending"
                className={`focus:ring-primary rounded-md border border-gray-300 bg-white px-4 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1 ${
                  sortingMethod !== "default" && sortingMethod !== null
                    ? "text-green-500"
                    : "text-black"
                }`}
                value={sortingMethod}
                onChange={(e) => {
                  handleSortChange(e.target.value);
                }}
              >
                <option value="default">Choose sorting format</option>
                <option
                  value="asc"
                  className={
                    sortingMethod === "asc" ? "text-green-500" : "text-black"
                  }
                >
                  Ascending
                </option>
                <option
                  value="desc"
                  className={
                    sortingMethod === "desc" ? "text-green-500" : "text-black"
                  }
                >
                  Descending
                </option>
              </select>
              {sortingMethod && sortingMethod !== "default" && (
                <button
                  className="z-50 ml-2 text-red-500 cursor-pointer"
                  onClick={() => setSortingMethod("default")}
                >
                  <MdCancel />
                </button>
              )}
            </div>
          </div>
          {/* Filters end */}

          {/* For desktop view */}
          {!isPageLoading ? (
            <div className="ml-4 max-w-full overflow-y-auto">
              <table className="w-full bg-white shadow-md">
                <thead>
                  <tr className="bg-blue-200">
                    <th className="px-4 py-2 border">User's Details</th>
                    <th className="px-2 py-2 border">Tasks</th>
                    <th className="max-w-[200px] border px-4 py-2">Details</th>
                    <th className="px-4 py-2 border">Created At</th>
                    <th className="min-w-[150px] border px-4 py-2">
                      Scheduled At
                    </th>
                    <th className="px-4 py-2 border">Link</th>
                    <th className="px-4 py-2 border">Status</th>
                    <th className="px-4 py-2 border">Actions</th>
                    <th className="px-4 py-2 border">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className={`${
                        rowIndex === highlightedRow || !row?.scheduled_at
                          ? "bg-orange-200"
                          : "hover:bg-blue-100"
                      }`}
                    >
                      <td className="px-4 py-2 border">
                        {row?.all_details?.user_details?.user_name ||
                        row?.all_details?.user_details?.user_mobile ? (
                          <div>
                            <p>{row?.all_details?.user_details?.user_name}</p>
                            <br />
                            <p
                              className="flex items-center gap-2 text-blue-500 cursor-pointer"
                              onClick={() => {
                                handlePDataModal(row);
                              }}
                            >
                              {row?.all_details?.user_details?.user_mobile}
                              <HiExternalLink />
                            </p>
                          </div>
                        ) : null}
                      </td>
                      <td className="px-2 py-2 border">
                        {row?.task || row?.type}
                      </td>

                      <td className="px-4 py-2 border">
                        {row?.all_details?.details?.length > 0 &&
                          row.all_details.details.map((detail, index) => (
                            <div key={index} className="space-y-2">
                              {Object.entries(detail).map(([key, value]) => {
                                if (value) {
                                  let formattedValue = value;
                                  if (
                                    [
                                      "started_at",
                                      "ending_at",
                                      "event_time",
                                    ].includes(key)
                                  ) {
                                    formattedValue = formatToDateAndTime(value);
                                  }
                                  const formattedKey = key.replace(/_/g, " ");
                                  return (
                                    <p key={key} className="my-2">
                                      <span className="font-bold">
                                        {formattedKey.charAt(0).toUpperCase() +
                                          formattedKey.slice(1)}
                                      </span>{" "}
                                      - {formattedValue}
                                    </p>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          ))}
                      </td>

                      <td className="px-4 py-2 text-sm border">
                        <div className="space-y-2">
                          <p className="">
                            <span>
                              {row?.created_at
                                ? formatToDateAndTime(row?.created_at)
                                : null}
                            </span>
                          </p>
                        </div>
                      </td>

                      <td className="px-4 py-2 text-sm border">
                        <div className="flex items-center gap-2 space-y-2">
                          <p className="">
                            <span>
                              {row?.scheduled_at
                                ? formatToDateAndTime(row?.scheduled_at)
                                : null}
                            </span>
                          </p>
                        </div>
                      </td>

                      <td className="px-4 py-2 border">
                        {row.type === "wa_tagged" &&
                        row.all_details.whatsappgroup_id ? (
                          <button
                            className="flex items-center gap-2 text-sm text-blue-500 cursor-pointer hover:underline"
                            onClick={() => {
                              createLinkAndOpenChat(row, rowIndex);
                            }}
                          >
                            Open chat <HiExternalLink size={20} />
                          </button>
                        ) : row.all_details.chat_link ? (
                          <a
                            className="flex items-center gap-2 text-sm text-blue-500 cursor-pointer hover:underline"
                            href={row.all_details.chat_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Open chat <HiExternalLink size={20} />
                          </a>
                        ) : (
                          "Not available"
                        )}
                      </td>

                      <td className="px-4 py-2 border">
                        <p
                          className={`text-sm font-semibold capitalize ${
                            row.status === "pending"
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {row.status ? row.status : "Not available"}
                        </p>
                        <button
                          className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                          onClick={() =>
                            handleChatStatusChange(row.id, "done", rowIndex)
                          }
                          disabled={isChatStatusSaveButtonDisabled[rowIndex]}
                        >
                          {isChatStatusSaveButtonDisabled[rowIndex]
                            ? "Please Wait..."
                            : "Done"}
                        </button>
                      </td>

                      <td className="px-4 py-2 border">
                        <button
                          className="px-3 py-2 mt-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                          onClick={() => {
                            setSupportTaskModal({
                              open: true,
                              taskId: row.id,
                              taskDetails: {
                                scheduled_at: getFormattedBackendDate(
                                  row.scheduled_at
                                ),
                                remarks: row.remarks || [],
                                user_name:
                                  row.all_details.user_details.user_name,
                                mobile:
                                  row.all_details.user_details.user_mobile,
                                status: row.status,
                                type: row.type,
                              },
                              type: "edit",
                            });
                          }}
                        >
                          Edit
                        </button>
                      </td>

                      <td className="px-4 py-2 border">
                        {row?.remarks?.length > 0 && (
                          <div className="space-y-4">
                            {row.remarks && (
                              <div>
                                {row.remarks.map((remark, index) => (
                                  <div key={index} className="space-y-2">
                                    {Object.entries(remark).map(
                                      ([key, value]) => {
                                        const formattedKey = key.replace(
                                          /_/g,
                                          " "
                                        );
                                        return (
                                          <p key={key} className="my-2">
                                            <span className="font-bold">
                                              {formattedKey
                                                .charAt(0)
                                                .toUpperCase() +
                                                formattedKey.slice(1)}
                                            </span>{" "}
                                            - {value}
                                          </p>
                                        );
                                      }
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : data.length > 0 ? (
            <div className="flex h-[70vh] items-center justify-center">
              <ClipLoader color={"blue"} size={60} />
            </div>
          ) : (
            <p className="text-lg font-semibold text-center">
              No data available
            </p>
          )}

          <div className="flex flex-col justify-center mt-10 md:mt-20 md:flex-row">
            <div className="flex justify-center md:ml-auto">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-flex w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Previous Page
              </button>

              <h3 className="flex items-center px-2 text-lg text-gray-800 ">
                Page {currentPage}
              </h3>

              <button
                onClick={handleNextPage}
                // disabled={currentPage === totalPages}
                className="px-4 py-4 mx-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 md:px-8"
              >
                Next Page
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-flex w-5 h-5 transform -rotate-180"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="flex items-center justify-end mt-5 md:ml-auto md:mt-0">
              <input
                type="text"
                placeholder={`Go to page (1 - ${totalPages})`}
                value={filterPage}
                onChange={(e) => setFilterPage(e.target.value)}
                className="w-full py-2 pl-8 pr-2 text-sm font-medium text-gray-800 bg-white border border-gray-400 rounded-md outline-none"
              />
              <button
                className="px-4 py-3 mx-2 text-white bg-blue-500 rounded-lg cursor-pointer hover:bg-blue-300"
                onClick={handlePageSearch}
              >
                <FiSearch
                  className="w-4 h-4 rounded-lg cursor-pointer"
                  onClick={handlePageSearch}
                />
              </button>
            </div>
          </div>
        </div>
        <hr />

        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
};

export default SupportTasks;
