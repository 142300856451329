import Home from 'components/Home/Home'
import React from 'react'

function index() {
  return (
    <div>
     <Home/>
    </div>
  )
}

export default index
