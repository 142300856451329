import PaymentLink from "../../../components/PaymentLink/PaymentLink";

export default function Card() {
  return (
    <>
      <>
        <PaymentLink />
      </>
    </>
  );
}
