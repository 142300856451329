import { useRef, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { IoMdClose } from "react-icons/io";
import { useRecoilState } from "recoil";
import { openVideoCallModalState } from "store/appointment/atoms/appointmentAtomState";
import waitVideoCallImg from "assets/img/VideoConsultation/wait-video-call-img.webp";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import SyncLoader from "components/Loaders/SyncLoader";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getToken } from "utils/getToken";

const generateRandomID = (len) => {
  let result = "";
  var chars = "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP";
  var maxPos = chars.length;
  for (var i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
};

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};

const VideoCallModal = () => {
  const [openVideoCallModal, setOpenVideoCallModal] = useRecoilState(
    openVideoCallModalState
  );
  const [roomId, setRoomId] = useState(null);
  const [loading, setLoading] = useState(false);
  const zegoRef = useRef(null);
  const zegoInstanceRef = useRef(null);

  useEffect(() => {
    return () => {
      // Cleanup function to destroy ZegoCloud instance
      if (zegoInstanceRef.current) {
        zegoInstanceRef.current.destroy();
      }
    };
  }, []);

  const handleStartVideoCall = async () => {
    if (openVideoCallModal.appointmentId) {
      try {
        setLoading(true);

        const response = await axios.post(
          process.env.REACT_APP_API_URL + "consultation/get-appointment-code/",
          {
            appointment_id: openVideoCallModal.appointmentId,
          },
          {
            headers,
          }
        );

        if (response.status === 200) {
          if (response?.data?.unique_code) {
            setRoomId(response?.data?.unique_code);
            window.open(
              `/video-consultation?roomID=${response?.data?.unique_code}`,
              "_blank"
            );
          } else {
            setRoomId(null);
          }
        }
      } catch (error) {
        console.error("Video_Consultation_MODAL", error);

        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something Went wrong!");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    setOpenVideoCallModal({
      open: false,
      appointmentId: null,
    });
    setRoomId(null);
    if (zegoInstanceRef.current) {
      zegoInstanceRef.current.destroy();
    }
  };

  return (
    <Dialog
      open={openVideoCallModal.open}
      onClose={handleCloseModal}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-[#000] opacity-20" aria-hidden="true" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto w-full max-w-xl rounded md:min-w-[50%]">
            <div className="my-8 inline-block w-full bg-white p-6 shadow-xl">
              <div className="flex justify-end">
                <button
                  onClick={handleCloseModal}
                  className="flex gap-3 text-gray-600 hover:text-gray-800"
                >
                  <IoMdClose size={25} />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="text-center text-lg font-medium leading-6 text-gray-900"
              >
                Join Video Consultation Room
              </Dialog.Title>

              {roomId ? (
                <div className="m-2 bg-blue-500 px-3 py-1 text-center text-white">
                  <Link
                    to={`/video-consultation?roomID=${roomId}`}
                    target="_blank"
                  >
                    Connect to call
                  </Link>
                </div>
              ) : (
                <div>
                  <img
                    src={waitVideoCallImg}
                    alt="waitVideoCallImg"
                    className="mx-auto w-[40%]"
                  />
                </div>
              )}

              {!roomId && (
                <div className="mt-4">
                  {loading ? (
                    <div>
                      <div className="rounded-md bg-red-600 px-4 py-2 text-white">
                        <SyncLoader size="5px" />
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={handleStartVideoCall}
                      className="w-full rounded-md bg-red-600 px-4 py-2 text-white"
                    >
                      Start Video Consultation
                    </button>
                  )}
                </div>
              )}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default VideoCallModal;
