import React, { useState } from "react";
import BulkWhatsappMessage from "./BulkWhatsappMessage";
import JobsStatus from "./JobsStatus";
import { ToastContainer, toast } from "react-toastify";
import { IoMdRefreshCircle } from "react-icons/io";
function BulkMessageMain() {
  const [isJobStatusVisible, setIsJobStatusVisible] = useState(false);
  const childRef = React.useRef(null);
  const handleRefresh = () => {
    if (childRef.current) {
      childRef.current(); // Call the function in the child component
      toast.success("Successfully refresh!");
    }
  };
  return (
    <div className="mx-auto w-full overflow-hidden px-4  md:py-2 md:px-0">
      <div className="my-3 flex w-full justify-between md:my-0">
        <h2
          className={`items-center  text-xl font-bold capitalize text-navy-700 dark:text-white sm:my-4 md:text-3xl ${isJobStatusVisible?"invisible":""}`}
        >
          Bulk Messages
        </h2>
        <div className="my-auto flex items-center">
          {" "}
          {isJobStatusVisible && (
            <button
              onClick={handleRefresh}
              className="hover:rotate-360 focus:rotate-360 transform transition-transform"
            >
              <IoMdRefreshCircle className="text-4xl text-blue-500" />
            </button>
          )}
          <button
            onClick={() => setIsJobStatusVisible(!isJobStatusVisible)}
            className="rounded-md bg-blue-500 px-4 py-1 text-white"
          >
            {isJobStatusVisible ? "Create Job" : "Jobs Status"}
          </button>
        </div>
      </div>
      {isJobStatusVisible ? (
        <JobsStatus onRef={(ref) => (childRef.current = ref)} />
      ) : (
        <BulkWhatsappMessage setIsJobStatusVisible={setIsJobStatusVisible} />
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default BulkMessageMain;
