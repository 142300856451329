import { useEffect } from "react";
import axios from "axios";
import { getToken } from "utils/getToken";

const ChatCountLogic = ({
  setGroupBlockedChatsCount = () => {},
  setGroupPendingDoctorChatsCount = () => {},
  setActionablePendingDoctorChatsCount = () => {},
  setGroupLiveChatsCount = () => {},
  setActionableGroupLiveChatsCount = () => {},
  setPrivateBlockedChatsCount = () => {},
  setPrivatePendingDoctorChatsCount = () => {},
  setPrivateLiveChatsCount = () => {},
  setActionablePrivateLiveChatsCount = () => {},
  setServerSyncTime = () => {},
  setIsServerSyncTimeExceed = () => {},
  setLastBlockAlertTime = () => {},
  setIsLastBlockAlertTimeExceed = () => {},
  setLastServerSyncTime = () => {},
  setLastBlockAlertSyncTime = () => {},
  setFollowUpChatTotalCount = () => {},
  setPendingDoctorChatsCount = () => {},
  setShouldPrivateChatBlink = () => {},
  setGroupChatBlink = () => {},
  setPendingDoctorChatBlink = () => {},
  setTaskTotalCount = () => {},
  setCriticalTaskCount = () => {},
  setShouldTaskBlink = () => {},
  setChatwootChatsCount = () => {},
  setQnasTotalActionableCount = () => {},
  setQnasTotalPendingCount = () => {},
  setQnasCountDocs = () => {},
  setQnasCountFollowup = () => {},
  setShouldQnasBlink = () => {},
  setQnasBotCount = () => {},
  setCountsLoading = () => {},
  setQnaCountsLoading = () => {}
  // setActionableChatwootChatsCount = () => {},
}) => {
  useEffect(()=>{
    setCountsLoading(true)
    setQnaCountsLoading(true)
  },[])

  const fetchChatsCount = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}whatsapp/chats-count-v2`;
      const qnaUrl = `${process.env.REACT_APP_API_URL}bot/support-portal/conversation/count/pending`;
      const headers = {
        Authorization: `Token ${getToken()}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(url, { headers });
      const responseQna = await axios.get(qnaUrl, { headers });

      if (response.data) {
        setCountsLoading(false);
        const {
          chat,
          old_live_private_chats,
          old_live_general_chats,
          old_pending_general_chats,
          read_bot,
          write_bot,
          pending_tasks,
          chatwoot_unattended_chat,
        } = response.data;

        const qna_chats = responseQna?.data;
        console.log(responseQna);

        if (chat.general_group) {
          handleGroupChats(chat.general_group);
        }
        if (chat.private_group) {
          handlePrivateChats(chat.private_group);
        }
        if (read_bot?.last_msg_time) {
          setServerSyncTime(read_bot.last_msg_time);
        }
        if (read_bot?.heartbeat_time) {
          setLastServerSyncTime(read_bot.heartbeat_time);
        }

        if (
          read_bot?.raise_alert !== undefined &&
          read_bot?.raise_alert !== null
        ) {
          setIsServerSyncTimeExceed(read_bot?.raise_alert);
        }

        if (write_bot?.last_msg_time) {
          setLastBlockAlertTime(write_bot.last_msg_time);
        }
        if (write_bot?.heartbeat_time) {
          setLastBlockAlertSyncTime(write_bot.heartbeat_time);
        }

        if (
          write_bot?.raise_alert !== undefined &&
          write_bot?.raise_alert !== null
        ) {
          setIsLastBlockAlertTimeExceed(write_bot.raise_alert);
        }
        if (chat?.followup_chat) {
          handleFollowUpChatsCount(chat.followup_chat);
        }
        if (chat) {
          const totalPendingDoctorCount = getTotalPendingDoctorsCount(chat);
          setPendingDoctorChatsCount(totalPendingDoctorCount);
        }

        if (old_live_private_chats !== undefined) {
          setActionablePrivateLiveChatsCount(old_live_private_chats);
          setShouldPrivateChatBlink(old_live_private_chats > 0);
        }

        if (old_pending_general_chats !== undefined) {
          setActionablePendingDoctorChatsCount(old_pending_general_chats);
          console.log("old_pending_general_chats", old_pending_general_chats);
          setPendingDoctorChatBlink(old_pending_general_chats > 0);
        }
        if (old_live_general_chats !== undefined) {
          setActionableGroupLiveChatsCount(old_live_general_chats);
          setGroupChatBlink(old_live_general_chats > 0);
        }

        if (pending_tasks) {
          if (pending_tasks.total > 0) {
            setTaskTotalCount(pending_tasks.total);
          }

          if (pending_tasks.critical > 0) {
            setCriticalTaskCount(pending_tasks.critical);
            setShouldTaskBlink(true);
          } else {
            setShouldTaskBlink(false);
          }
        }
        if (chatwoot_unattended_chat !== undefined) {
          setChatwootChatsCount(chatwoot_unattended_chat);
          // setActionableChatwootChatsCount(chatwoot_unattended_chat);
        }
        if (qna_chats) {
          setQnaCountsLoading(false);
          setQnasCountDocs(qna_chats.doctor_care.actionable);
          setQnasCountFollowup(qna_chats.followup.actionable);
          setQnasBotCount(qna_chats.qna.actionable);
          setQnasTotalActionableCount(
            qna_chats.doctor_care.actionable +
              qna_chats.followup.actionable +
              qna_chats.qna.actionable
          );
          setQnasTotalPendingCount(
            qna_chats.doctor_care.pending +
              qna_chats.followup.pending +
              qna_chats.qna.pending
          );
          setShouldQnasBlink(
            (qna_chats.doctor_care.actionable ||
              qna_chats.followup.actionable ||
              qna_chats.qna.actionable) > 0
          );
        }
      } else {
        setCountsLoading(false);
        console.log("No data found");
      }
    } catch (error) {
      setCountsLoading(false);
      setQnaCountsLoading(false)
      console.error(
        "Error fetching chats count:",
        error.response || error.message || error
      );
    }
  };

  const handleFollowUpChatsCount = (followUpChats) => {
    if (followUpChats?.length > 0) {
      let openFollowUpChats = 0;
      let activeFollowUpChats = 0;

      followUpChats.forEach((chatStatus) => {
        if (chatStatus?.status === "open") {
          openFollowUpChats = chatStatus?.count;
        }

        if (chatStatus?.status === "active") {
          activeFollowUpChats = chatStatus?.count;
        }
      });

      const totalFollowUpChatCounts = openFollowUpChats + activeFollowUpChats;

      setFollowUpChatTotalCount(totalFollowUpChatCounts);
    }
  };

  const handleGroupChats = (groupChats) => {
    const {
      blocked: groupBlockedChatsCount = 0,
      pending_doctor: groupPendingDoctorChatsCount = 0,
      active: groupActiveChatsCount = 0,
      open: groupOpenChatsCount = 0,
      replied: groupRepliedChatsCount = 0,
    } = groupChats.reduce((counts, item) => {
      counts[item.status] = item.count || 0;
      return counts;
    }, {});
    // console.log(groupChats);

    const groupLiveChatsCount =
      groupActiveChatsCount +
      groupOpenChatsCount +
      groupPendingDoctorChatsCount;

    setGroupBlockedChatsCount(groupBlockedChatsCount);
    setGroupPendingDoctorChatsCount(groupPendingDoctorChatsCount);
    setGroupLiveChatsCount(groupLiveChatsCount);
  };

  const handlePrivateChats = (privateChats) => {
    const {
      blocked: privateBlockedChatsCount = 0,
      pending_doctor: privatePendingDoctorChatsCount = 0,
      active: privateActiveChatsCount = 0,
      open: privateOpenChatsCount = 0,
      replied: privateRepliedChatsCount = 0,
    } = privateChats.reduce((counts, item) => {
      counts[item.status] = item.count || 0;
      return counts;
    }, {});

    const privateLiveChatsCount =
      privateActiveChatsCount +
      privateOpenChatsCount +
      privatePendingDoctorChatsCount;

    setPrivateBlockedChatsCount(privateBlockedChatsCount);
    setPrivatePendingDoctorChatsCount(privatePendingDoctorChatsCount);
    setPrivateLiveChatsCount(privateLiveChatsCount);
  };

  const getTotalPendingDoctorsCount = (chatData) => {
    let allCategoryPendingDoctorCount = 0;

    if (chatData) {
      if (chatData?.followup_chat.length > 0) {
        const followUpChatStats = chatData.followup_chat;

        followUpChatStats.forEach((chatStat) => {
          if (chatStat.status === "pending_doctor") {
            allCategoryPendingDoctorCount =
              allCategoryPendingDoctorCount + chatStat.count;
          }
        });
      }

      if (chatData?.general_group.length > 0) {
        const generalGroupChatStats = chatData.general_group;

        generalGroupChatStats.forEach((chatStat) => {
          if (chatStat.status === "pending_doctor") {
            allCategoryPendingDoctorCount =
              allCategoryPendingDoctorCount + chatStat.count;
          }
        });
      }

      if (chatData?.private_group.length > 0) {
        const privateGroupChatStats = chatData.private_group;

        privateGroupChatStats.forEach((chatStat) => {
          if (chatStat.status === "pending_doctor") {
            allCategoryPendingDoctorCount =
              allCategoryPendingDoctorCount + chatStat.count;
          }
        });
      }
    }

    return allCategoryPendingDoctorCount;
  };

  useEffect(() => {
    const interval = setInterval(fetchChatsCount, 60000);
    fetchChatsCount();

    const handleFocus = fetchChatsCount;
    window.addEventListener("focus", handleFocus);

    return () => {
      clearInterval(interval);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  return null;
};

export default ChatCountLogic;
