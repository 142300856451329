import React from "react";
import Subscription from "views/admin/subscription";
import Appointment from "views/admin/appointment";
import UserData from "views/admin/userData";
import Reminder from "views/admin/reminder";
import SignIn from "views/auth/SignIn";
import { MdBarChart, MdLock, MdEvent, MdSend, MdHome, MdQuestionAnswer } from "react-icons/md";
import { BiData } from "react-icons/bi";
import { BsCashCoin, BsChatTextFill, BsWhatsapp } from "react-icons/bs";
import { IoGitNetworkOutline } from "react-icons/io5";
import { HiUserGroup } from "react-icons/hi";
import { FaHospitalUser } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { MdPendingActions } from "react-icons/md";
import SignOut from "views/auth/SignOut";
import AffiliatesPortal from "views/admin/affiliatesPortal";
import WhatsappChat from "views/admin/chat";
import Default from "views/admin/default";
import {
  FollowUpChatTool,
  FollowUpChatMessages,
} from "views/admin/followUpChat";
import { WhatsappSessions } from "views/admin/whatsappSessions";
import ChatStatus from "views/admin/chatStatus";
import PaymentLink from "views/admin/paymentLink";
import BookConsultation from "views/admin/bookConsultation";
import BlockedChatStatus from "components/ChatStatus/BlockedChatStatus";
import PendingDoctorChatStatus from "components/ChatStatus/PendingDoctorChatStatus";
import DoctorsAppointments from "components/DoctorsAppointments/DoctorsAppointments";
import BulkWhatsappMessage from "views/admin/bulkWhatsappMessage";
import SupportTasks from "components/SupportTasks/SupportTasks";
import { BiSupport } from "react-icons/bi";
import PrivateChatTool from "components/PrivateChatTool/PrivateChatTool";
import { RiChatPrivateLine } from "react-icons/ri";
import { FaRegCalendarCheck } from "react-icons/fa";
import Growth from "./views/admin/growth";
import { FaChartLine } from "react-icons/fa";
import ChatWootChatStatus from "components/ChatwootChatStatus/ChatwootChatStatus";
import { SiChatwoot } from "react-icons/si";
import QNAs from "components/QNAs/QNAs";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    icon: <MdHome className="text-xl" />,
    path: "home",
    component: <Default />,
  },
  {
    name: "User Data",
    layout: "/admin",
    path: "userdata",
    icon: <BiData className="text-xl" />,
    component: <UserData />,
  },
  {
    name: "Payment Link",
    layout: "/admin",
    path: "payment",
    icon: <BsCashCoin className="text-lg" />,
    component: <PaymentLink />,
  },
  {
    name: "Subscription",
    layout: "/admin",
    icon: <MdBarChart className="text-xl" />,
    path: "plan",
    component: <Subscription />,
  },
  {
    name: "Growth",
    layout: "/admin",
    icon: <FaChartLine className="text-xl" />,
    path: "growth",
    component: <Growth />,
  },
  {
    name: "Appointment",
    layout: "/admin",
    icon: <MdEvent className="text-xl" />,
    path: "appointment",
    component: <Appointment />,
  },

  {
    name: "Book consultation",
    layout: "/admin",
    path: "consultation",
    icon: <FaHospitalUser className="text-lg" />,
    component: <BookConsultation />,
  },
  {
    name: "Whatsapp",
    layout: "/admin",
    path: "whatsapp-chat",
    icon: <BsWhatsapp className="text-lg" />,
    component: <WhatsappChat />,
  },

  {
    name: "Chat Tool",
    layout: "/admin",
    path: "chat-status",
    icon: <BsChatTextFill className="text-lg" />,
    component: <ChatStatus />,
  },
  {
    name: "Private Chat Tool",
    layout: "/admin",
    path: "private-chat-tool",
    icon: <RiChatPrivateLine className="text-xl" />,
    component: <PrivateChatTool />,
  },
  {
    name: "Chatwoot Chat Tool",
    layout: "/admin",
    path: "chatwoot-tool",
    icon: <SiChatwoot className="text-xl" />,
    component: <ChatWootChatStatus />,
  },
  {
    name: "QnAs",
    layout: "/admin",
    path: "qnas",
    icon: <MdQuestionAnswer className="text-xl" />,
    component: <QNAs />,
  },
  {
    name: "Follow Up Chat Tool",
    layout: "/admin",
    path: "followup-chat-tool",
    icon: <FaRegCalendarCheck className="text-xl" />,
    component: <FollowUpChatTool />,
  },
  {
    name: "Support Tasks",
    layout: "/admin",
    path: "support-tasks",
    icon: <BiSupport className="text-xl" />,
    component: <SupportTasks />,
  },
  {
    name: "Bulk Message",
    layout: "/admin",
    path: "bulk-message",
    icon: <MdSend className="text-xl" />,
    component: <BulkWhatsappMessage />,
  },
  {
    name: "Whatsapp Sessions",
    layout: "/admin",
    path: "whatsapp-sessions",
    icon: <BsWhatsapp className="text-lg" />,
    component: <WhatsappSessions />,
  },
  {
    name: "Pending Doctor Chat",
    layout: "/admin",
    path: "pending-doctor-chat",
    icon: <MdPendingActions className="text-xl" />,
    component: <PendingDoctorChatStatus />,
  },

  // {
  //   name: "Blocked Chat",
  //   layout: "/admin",
  //   path: "blocked-chat",
  //   icon: <ImBlocked />,
  //   component: <BlockedChatStatus />,
  // },

  // {
  //   name: "Reminder",
  //   layout: "/admin",
  //   icon: <BsAlarmFill />,
  //   path: "reminder",
  //   component: <Reminder />,
  // },
  {
    name: "Affiliates Portal",
    layout: "/admin",
    path: "affiliates-portal",
    icon: <HiUserGroup className="text-xl" />,
    component: <AffiliatesPortal />,
  },
  {
    name: "Doctors Appointments",
    layout: "/admin",
    path: "manage-doctors-appointments",
    icon: <FaUserDoctor className="text-xl" />,
    component: <DoctorsAppointments />,
  },
  {
    name: "Sign Out",
    layout: "/admin",
    path: "sign-out",
    icon: <MdLock className="text-xl" />,
    component: <SignOut />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: null,
    component: <SignIn />,
  },
];

export default routes;
