import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { startSessionModalState } from "store/whatsappSession/atoms/whatsappSessionAtomState";
import { useRecoilState } from "recoil";
import { IoMdClose } from "react-icons/io";
import SyncLoader from "components/Loaders/SyncLoader";
import PuffLoader from "components/Loaders/PuffLoader";
import axios from "axios";
import { getToken } from "utils/getToken";
import { toast } from "react-toastify";
import { AiOutlineReload } from "react-icons/ai";
import sampleQRImg from "assets/img/whatsappSession/sampleQR.png";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};

const StartSessionModal = () => {
  const [startSessionModal, setStartSessionModal] = useRecoilState(
    startSessionModalState
  );
  const [fetchingSessionDetails, setFetchingSessionDetails] = useState(false);
  const [startingSession, setStartingSession] = useState(false);
  const [terminatingSession, setTerminatingSession] = useState(false);
  const [testingSession, setTestingSession] = useState(false);
  const [fetchingQr, setFetchingQr] = useState(false);
  const [fetchingStatus, setFetchingStatus] = useState(false);
  const [sessionStatus, setSessionStatus] = useState("");
  const [qrImageUrl, setQrImageUrl] = useState("");
  const [isQrActive, setIsQrActive] = useState(false);
  const [timerRunning, setTimerRunning] = useState(false);
  const [remainingTime, setRemainingTime] = useState(15);
  const [testStatus, setTestStatus] = useState(false);


  const selectedSessionId = startSessionModal.sessionId;
  const selectedSessionName = startSessionModal.sessionName;

  const handleStartSession = async () => {
    if (selectedSessionId) {
      try {
        setStartingSession(true);

        const startSessionResponse = await axios.post(
          process.env.REACT_APP_API_URL + `whatsapp/start-session/`,
          {
            session_name: selectedSessionName,
          },
          { headers }
        );

        if (startSessionResponse.status === 200) {
          toast.success("Session is initialized, please scan the QR!");
          console.log("Start Session Response", startSessionResponse);
        }
      } catch (err) {
        console.log("SESSION_START_MODAL_ERROR", err);
        toast.error("Something went wrong while starting this session!");
      } finally {
        setStartingSession(false);
      }
    }
  };

  const handleTestSession = async () => {
    if (selectedSessionId) {
      try {
        setTestingSession(true);

        const testSessionResponse = await axios.post(
          process.env.REACT_APP_API_URL + `whatsapp/send-test-message/`,
          {
            session_name: selectedSessionName,
          },
          { headers }
        );

        if (testSessionResponse.status === 200) {
          toast.success(
            "Successfully passed test! This session is now active."
          );
          console.log("Test Session Response", testSessionResponse);
          setTestStatus(true);
        }
      } catch (err) {
        console.log("SESSION_TEST_MODAL_ERROR", err);
        if (err?.response?.data?.status_code === 404) {
          toast.error("Test Failed! Please try again.");
        } else {
          toast.error("Something went wrong while testing this session!");
        }
        setTestStatus(false);
      } finally {
        setTestingSession(false);
      }
    }
  };

  const handleTerminateSession = async () => {
    if (selectedSessionId) {
      try {
        setTerminatingSession(true);

        const terminateSessionResponse = await axios.post(
          process.env.REACT_APP_API_URL + `whatsapp/terminate-session/`,
          {
            session_name: selectedSessionName,
          },
          { headers }
        );

        if (terminateSessionResponse.status === 200) {
          toast.success("This session is now successfully Terminated!");
        }
      } catch (err) {
        console.log("SESSION_TERMINATE_MODAL_ERROR", err);
        toast.error("Something went wrong while terminating this session!");
      } finally {
        setTerminatingSession(false);
      }
    }
  };

  const getQrBySessionId = async () => {
    if (selectedSessionId) {
      try {
        setFetchingQr(true);

        const getSessionQrResponse = await axios.post(
          process.env.REACT_APP_API_URL + `whatsapp/get-qr/`,
          {
            session_name: selectedSessionName,
          },
          { headers, responseType: "blob" }
        );

        if (getSessionQrResponse.status === 200) {


          if (getSessionQrResponse?.data) {
            let imageUrl = URL.createObjectURL(getSessionQrResponse.data);

            if (imageUrl) {
              setQrImageUrl(imageUrl);
              setRemainingTime(15);
              setTimerRunning(true);
              setIsQrActive(true);
            }
          }
        }
      } catch (err) {
        console.log("SESSION_START_MODAL_GET_QR_ERROR", err);
        toast.error(
          "Failed to get QR! Make sure to start session before getting QR"
        );
      } finally {
        setFetchingQr(false);
      }
    }
  };

  const getSessionStatusById = async () => {
    if (selectedSessionId) {
      try {
        setFetchingStatus(true);

        const getSessionStatusResponse = await axios.post(
          process.env.REACT_APP_API_URL + `whatsapp/get-session-status/`,
          {
            session_name: selectedSessionName,
          },
          { headers }
        );

        if (getSessionStatusResponse.status === 200) {
          if (getSessionStatusResponse?.data?.status) {
            setSessionStatus(getSessionStatusResponse?.data?.status);
          }
        }
      } catch (err) {
        console.log("SESSION_START_MODAL_GET_STATUS_ERROR", err);
        setSessionStatus("Not available");
      } finally {
        setFetchingStatus(false);
      }
    }
  };

  useEffect(() => {
    if (startSessionModal.sessionStatus) {
      getSessionStatusById();
    }
  }, [selectedSessionId]);

  // Use useEffect to handle qr timer
  useEffect(() => {
    let timerInterval;

    if (timerRunning) {
      timerInterval = setInterval(() => {
        if (remainingTime !== 0) {
          setRemainingTime((prevTime) => prevTime - 1);
        } else {
          clearInterval(timerInterval);
          setTimerRunning(false);
          setIsQrActive(false);
          setQrImageUrl(null);
        }
      }, 1000);
    }

    return () => clearInterval(timerInterval);
  }, [timerRunning, remainingTime]);


  return (
    <Dialog
      open={startSessionModal.open}
      onClose={() => {
        setStartSessionModal({
          open: false,
          sessionName: null,
          sessionId: null,
        });
      }}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-[#000] opacity-20" aria-hidden="true" />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 overflow-y-auto">
        {/* Container to center the panel */}
        <div className="flex min-h-screen items-center justify-center p-4">
          {/* The actual dialog panel  */}
          {fetchingSessionDetails ? (
            <div>
              <SyncLoader size="10px" color="#525CEB" />
            </div>
          ) : (
            <Dialog.Panel className="mx-auto w-full max-w-md rounded md:min-w-[40%]">
              <div className="my-8 inline-block w-full bg-white p-6 shadow-xl">
                <div className="flex justify-end">
                  <button
                    onClick={() => {
                      setStartSessionModal({
                        open: false,
                        sessionName: null,
                        sessionId: null,
                      });
                    }}
                    className="text-gray-600 hover:text-gray-800"
                  >
                    <IoMdClose size={25} />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="bg-gray-50 text-center text-lg font-medium leading-6 text-gray-900"
                >
                  <div className="flex flex-col gap-4 py-4 px-2 md:flex-row md:items-center md:justify-between">
                    <h3 className="text text-base capitalize md:text-lg">
                      {selectedSessionName}
                    </h3>
                    <div className="flex items-center justify-center gap-3">
                      {fetchingStatus ? (
                        <div
                          role="status"
                          className="max-w-sm animate-pulse bg-white"
                        >
                          <div className="h-3.5 w-32 rounded-md bg-gray-400"></div>
                        </div>
                      ) : (
                        <p className="flex items-center text-sm font-medium text-gray-900 me-3">
                          <span
                            className={`flex h-2.5 w-2.5 flex-shrink-0 rounded-full ${
                              sessionStatus === "CONNECTED"
                                ? "bg-green-500"
                                : "bg-red-500"
                            } me-1.5`}
                          ></span>

                          {sessionStatus}
                        </p>
                      )}

                      <button
                        onClick={getSessionStatusById}
                        disabled={fetchingStatus}
                        className=""
                      >
                        <AiOutlineReload size={20} />
                      </button>
                    </div>
                  </div>
                </Dialog.Title>

                <div className="mt-5 gap-5 bg-gray-100">
                  <div className="flex items-center justify-between py-4 px-2">
                    <h4 className="text-lg font-semibold text-gray-800">
                      Step 1
                    </h4>

                    <div className="">
                      <div className="flex items-center gap-3">
                        {startingSession ? (
                          <div>
                            <div className="rounded-md bg-blue-500 px-4 py-2 text-white">
                              <SyncLoader size="5px" />
                            </div>
                          </div>
                        ) : (
                          <button
                            onClick={handleStartSession}
                            className="rounded-md bg-blue-500 px-4 py-2 text-white"
                          >
                            Start Session
                          </button>
                        )}

                        {terminatingSession ? (
                          <div>
                            <div className="rounded-md bg-red-500 px-4 py-2 text-white">
                              <SyncLoader size="5px" />
                            </div>
                          </div>
                        ) : (
                          <button
                            onClick={handleTerminateSession}
                            className="rounded-md bg-red-500 px-4 py-2 text-white"
                          >
                            Terminate Session
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 bg-gray-100 px-2">
                  <div className="flex items-center justify-between">
                    <h4 className="py-2 text-lg font-semibold text-gray-800">
                      Step 2
                    </h4>

                    <p className="text-lg font-semibold text-gray-700">
                      Scan QR
                    </p>
                  </div>
                  <div className="my-4 grid grid-cols-2 gap-2">
                    {fetchingQr ? (
                      <div className="flex h-60 items-center justify-center">
                        <PuffLoader size="50px" />
                      </div>
                    ) : (
                      <div className="relative">
                        <img
                          src={qrImageUrl || sampleQRImg}
                          alt="Qr-img"
                          className={`h-60 w-full ${
                            !qrImageUrl && !isQrActive && "blur-md filter"
                          }`}
                        />
                        {isQrActive && (
                          <div className="absolute top-0 -right-12 rounded-full bg-gray-50 p-2 px-3 text-base font-bold text-red-500">
                            {remainingTime}s
                          </div>
                        )}
                        {!isQrActive && qrImageUrl === null && (
                          <div className="absolute inset-0 z-40 flex items-center justify-center bg-white/30">
                            <p className="text-lg font-extrabold text-red-600">
                              This QR is expired!
                            </p>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="flex items-center justify-center">
                      <button
                        onClick={getQrBySessionId}
                        disabled={fetchingQr}
                        className="rounded-md bg-blueSecondary py-3 px-4 text-white"
                      >
                        Get QR
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mt-5 gap-5 bg-gray-100">
                  <div className="flex items-center justify-between py-4 px-2">
                    <h4 className="text-lg font-semibold text-gray-800">
                      Step 3
                    </h4>

                    <div className="">
                      <button
                        disabled={fetchingStatus}
                        onClick={() => getSessionStatusById()}
                        className="rounded-md bg-blue-500 px-4 py-2 text-white"
                      >
                        Check Status
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mt-5 gap-5 bg-gray-100">
                  <div className="flex items-center justify-between py-4 px-2">
                    <h4 className="text-lg font-semibold text-gray-800">
                      Step 4
                    </h4>

                    {testStatus && (
                      <div>
                        <p className="font-bold text-green-600 md:text-base">
                          Great! Test passed successfully!
                        </p>
                      </div>
                    )}

                    <div className="">
                      {testingSession ? (
                        <div>
                          <div className="rounded-md bg-green-500 px-4 py-2 text-white">
                            <SyncLoader size="5px" />
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center gap-3">
                          <button
                            onClick={handleTestSession}
                            className="rounded-md bg-green-500 px-4 py-2 text-white"
                          >
                            Test Session
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default StartSessionModal;
