import React, { useEffect, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "utils/getToken";

const AffiliatesPortal = () => {
  const [affiliatesSetSuccess, setAffiliatesSetSuccess] = useState(false);
  const [token, setToken] = useState(getToken());
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [couponStatus, setCouponStatus] = useState(false);

  // For fetching user data
  const [affiliateProfile, setAffiliateProfile] = useState({
    name: "",
    email: "",
    mobile: "",
    role: [],
    coupon_code: "",
    coupon_detail: [],
  });

  const handleFetchAffiliateData = async () => {
    const affiliatesData = {
      mobile: phoneNumber,
    };
    setIsDisabled(true);

    if (phoneNumber.length < 7) {
      toast.error("Please enter mobile number");
      setIsDisabled(false);
      console.log("Please enter mobile number");
      return;
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "referral/get-affiliate-data/",
        {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(affiliatesData),
        }
      );

      if (couponStatus) {
        setCouponStatus(false);
      }

      if (response.ok) {
        const responseData = await response.json();
        setIsDisabled(false);
        if (responseData) {
          toast.success("User found");
          setAffiliatesSetSuccess(true);

          setAffiliateProfile({
            name: responseData.first_name,
            email: responseData.email,
            mobile: responseData.phone_number,
            role: responseData.role,
            coupon_detail: responseData.coupon_detail,
          });
        } else {
          toast.error("User not found");
          setAffiliatesSetSuccess(false);
          setIsDisabled(false);
        }
      }
    } catch (error) {
      setAffiliatesSetSuccess(false);
      setPhoneNumber("");
      setIsDisabled(false);
      toast.error("Something went wrong");
      console.error("An error occurred:", error);
    }
  };
  useEffect(() => {
    console.log(affiliateProfile);
  }, [affiliateProfile]);

  // For role change
  const handleRoleChange = async (userRoleType) => {
    const roleChangeData = {
      mobile: affiliateProfile.mobile,
    };
    setIsDisabled(true);
    if (affiliateProfile.mobile.length < 7) {
      toast.error("Please enter mobile number");
      setIsDisabled(false);
      return;
    }

    if (!userRoleType) {
      return;
    }
    try {
      const apiUrl =
        userRoleType === "affiliate"
          ? `referral/change-role-to-affiliate/`
          : `referral/change-role-to-user/`;
      const response = await fetch(process.env.REACT_APP_API_URL + apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(roleChangeData),
      });

      if (response.ok) {
        setIsDisabled(false);
        const data = await response.json();
        if (data.message) {
          handleFetchAffiliateData();
          toast.success("Role changed successfully");
        }
      }
    } catch (error) {
      setIsDisabled(false);
      toast.error("An error occurred");
      console.error("An error occurred:", error);
    }
  };

  // For coupon code
  const [couponCode, setCouponCode] = useState("");
  const [couponDescription, setCouponDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState("Percent");
  const [couponAmount, setCouponAmount] = useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const updateCouponData = async () => {
    const couponData = {
      mobile: affiliateProfile.mobile,
      coupon_name: couponCode,
      coupon_description: couponDescription,
      coupon_type: 0,
      coupon_amount: couponAmount,
    };
    if (!couponCode.includes("GH")) {
      toast.error("Please enter coupon code starting with GH");
      return;
    }
    if (couponDescription.length < 3 || couponAmount.length < 1) {
      toast.error("Please enter valid coupon details");
      return;
    }

    try {
      setIsDisabled(true);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "referral/create-coupon-and-referral/",
        {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(couponData),
        }
      );
      const data = await response.json();
      if (data.message === "Coupon and Referral Code created successfully") {
        toast.success(`${couponCode} created successfully`);
        setCouponStatus(true);
        setIsDisabled(false);

        handleFetchAffiliateData();

        console.log("Details updated successfully", couponData);
      }
    } catch (error) {
      setIsDisabled(false);
      toast.error("An error occurred");
      console.error("An error occurred:", error);
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <div className="mx-auto items-center justify-center px-4 md:mx-0 md:w-full md:p-4">
      <h1 className="my-6 items-center justify-center text-center text-4xl font-bold capitalize text-navy-700 dark:text-white md:my-8">
        Search And Update Affiliates
      </h1>

      <div className="mb-4">
        <label className="mb-1 block font-semibold text-gray-700 md:mb-2">
          Mobile Number:
        </label>
        <input
          type="mobile"
          id="mobile"
          className="w-full rounded-md border border-gray-400 px-4 py-2 focus:border-blue-500 focus:outline-none"
          placeholder="Enter mobile number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>

      <button
        className={`w-full rounded px-4 py-2 text-white md:col-span-2 ${
          isDisabled ? "cursor-not-allowed bg-gray-300" : "bg-blue-500"
        }`}
        disabled={isDisabled}
        onClick={handleFetchAffiliateData}
      >
        {isDisabled ? "Loading..." : "Search"}
      </button>

      {affiliatesSetSuccess && (
        <div className="flex h-auto w-full items-center bg-blue-50 py-6">
          <div className="mx-auto mt-8 w-[350px] max-w-7xl rounded-md bg-white p-6 shadow-lg md:w-full">
            <h3 className="mx-auto mb-4 text-center text-xl font-bold">
              Affiliate's Profile👩‍⚕️
            </h3>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="mb-2 text-lg font-bold text-gray-700">
                <strong>Name:</strong> {affiliateProfile.name}
              </div>
              <div className="mb-2 text-lg font-bold text-gray-700">
                <strong>Mobile Number:</strong> {affiliateProfile.mobile}
              </div>
              <div className="mb-2 text-lg font-bold text-gray-700">
                <strong>Email:</strong> {affiliateProfile.email}
              </div>
              <div className="mb-2 flex items-center gap-2 text-lg font-bold text-gray-700">
                <strong>Role:</strong>{" "}
                {affiliateProfile.role.map((role, index) => (
                  <p key={index} className="">{`${role},`}</p>
                ))}
                <div className="text-sm">
                  {!affiliateProfile.role.includes("Affiliate") && (
                    <button
                      className={`rounded-md px-2 py-2 text-white ${
                        isDisabled
                          ? "cursor-not-allowed bg-gray-300"
                          : "bg-blue-500"
                      }`}
                      onClick={() => handleRoleChange("affiliate")}
                      disabled={isDisabled}
                    >
                      {isDisabled ? "Changing Role..." : "Change to Affiliate"}
                    </button>
                  )}

                  {affiliateProfile.role.includes("Affiliate") && (
                    <button
                      className={`rounded-md border border-gray-900 px-2 py-2 text-white ${
                        isDisabled
                          ? "cursor-not-allowed bg-gray-300"
                          : "bg-blue-500"
                      }`}
                      onClick={() => handleRoleChange("user")}
                      disabled={isDisabled}
                    >
                      {isDisabled ? "Changing Role..." : "Change to User"}
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 py-4 md:grid-cols-2">
              {affiliateProfile.role.includes("Affiliate") && (
                <div className="mt-5">
                  <div className="col-span-1 mb-2 md:col-span-2">
                    <label className="mb-2 block text-lg font-bold text-gray-700">
                      Coupon Code (Please include GH in the beginning):
                    </label>
                    <input
                      name="coupon_code"
                      value={couponCode}
                      onChange={(e) =>
                        setCouponCode(
                          e.target.value.replace(/\s/g, "").toUpperCase()
                        )
                      }
                      placeholder="Enter Coupon Code"
                      type="text"
                      required
                      className="w-full rounded-md border-2 border-solid border-[#BFDBFE] bg-white p-2 focus:ring-blue-500 dark:focus:ring-blue-600"
                    />
                  </div>
                  <div className="col-span-1 mb-2 md:col-span-2">
                    <label className="mb-2 block text-lg font-bold text-gray-700">
                      Coupon Description:
                    </label>
                    <input
                      name="coupon_description"
                      value={couponDescription}
                      onChange={(e) => setCouponDescription(e.target.value)}
                      placeholder="Enter Coupon Description"
                      type="text"
                      required
                      className="w-full rounded-md border-2 border-solid border-[#BFDBFE] bg-white p-2 focus:ring-blue-500 dark:focus:ring-blue-600"
                    />
                  </div>
                  {/* <div className="col-span-1 mb-2 md:col-span-2">
                <label className="block mb-2 text-lg font-bold text-gray-700">
                  Coupon Type:
                </label>
                <select
                  className="p-2 border rounded"
                  value={selectedOption}
                  onChange={handleChange}
                >
                  <option value="Percent">Percent</option>
                  <option value="Flat">Flat</option>
                </select>
              </div> */}
                  <div className="mb-2">
                    <label className="mb-2 block text-lg font-bold text-gray-700">
                      Percentage Off (Only Number, Max 50):
                    </label>
                    <input
                      name="coupon_description"
                      value={couponAmount}
                      onChange={(e) => setCouponAmount(e.target.value)}
                      placeholder="Enter percentage off"
                      type="text"
                      required
                      className="w-full rounded-md border-2 border-solid border-[#BFDBFE] bg-white p-2 focus:ring-blue-500 dark:focus:ring-blue-600"
                    />
                  </div>
                  <button
                    className={`w-full rounded px-4 py-2 text-white ${
                      isDisabled
                        ? "cursor-not-allowed bg-gray-300"
                        : "bg-blue-500"
                    }`}
                    onClick={updateCouponData}
                    disabled={isDisabled}
                  >
                    {/* <FaPencilAlt className="mr-2 inline-block" /> */}
                    {isDisabled ? "Updating..." : "Create Coupon"}
                  </button>
                  {couponStatus && (
                    <div className="mt-4 text-center text-lg font-bold text-green-500">
                      {`Coupon Code: ${couponCode} created successfully with ${couponAmount}% off`}
                    </div>
                  )}
                </div>
              )}

              {affiliateProfile.role.includes("Affiliate") && (
                <div className="mt-5">
                  <div class="w-full max-w-md rounded-lg border border-gray-200 bg-white p-4 shadow dark:border-gray-700 dark:bg-gray-800 sm:p-8">
                    <div class="mb-4 flex items-center justify-between">
                      <h5 class="text-xl font-bold leading-none text-gray-900 dark:text-white">
                        Affiliate Coupons
                      </h5>
                    </div>
                    {Array.isArray(affiliateProfile.coupon_detail) &&
                    affiliateProfile.coupon_detail.length > 0 ? (
                      <div class="flow-root">
                        <ul class="divide-y divide-gray-200 dark:divide-gray-700">
                          {affiliateProfile.coupon_detail.map((coupon) => (
                            <li class="py-3 sm:py-4">
                              <div class="flex items-center">
                                <div class="min-w-0 flex-1 ms-4">
                                  <p class="truncate text-base font-medium text-gray-900 dark:text-white">
                                    {coupon?.coupon_name}
                                  </p>
                                  <p class="truncate text-sm text-gray-800 dark:text-gray-400">
                                    {coupon?.created_at}
                                  </p>
                                  <p class="truncate text-sm text-gray-800 dark:text-gray-400">
                                    Status -{" "}
                                    <span
                                      className={`${
                                        coupon?.active === true
                                          ? `text-green-500`
                                          : `text-red-500`
                                      }`}
                                    >
                                      {coupon?.active
                                        ? "Active"
                                        : "Deactivated"}
                                    </span>
                                  </p>
                                </div>
                                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                  Discount - {coupon?.coupon_amount}%
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div>No Active Coupons</div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default AffiliatesPortal;
