import { useState, useEffect } from "react";
import { Dialog, Listbox } from "@headlessui/react";
import axios from "axios";
import { getToken } from "utils/getToken";
import { toast } from "react-toastify";
import SyncLoader from "components/Loaders/SyncLoader";
import { IoMdClose } from "react-icons/io";
import "react-toastify/dist/ReactToastify.css";
import { HiOutlineSelector } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};

const roleOptions = [
  { name: "User" },
  { name: "Doctor" },
  { name: "Affiliate" },
  { name: "Support" },
  { name: "Sales" },
];

const specialties = ["lactation", "growth", "nutrition"];

const EditDoctorModal = ({
  openEditDoctorModal,
  setOpenEditDoctorModal,
  refresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [supportUsers, setSupportUsers] = useState([]);
  const [data, setData] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    medical_registration_number: "",
    degree: "",
    phone_number: "",
    address: "",
    visibility: "",
    babynama_doctor: "",
    specialty: [],
  });

  const getDoctorDetails = async () => {
    if (openEditDoctorModal.mobile !== "") {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}doctors/get-doctor/`,
        { mobile: openEditDoctorModal.mobile },
        { headers }
      );
      if (res.status === 200) {
        setData(res.data?.doctor);
      }
    }
  };

  useEffect(() => {
    setFormData({
      name: data?.name,
      email: data?.email,
      medical_registration_number: data?.medical_registration_number,
      degree: data?.degree,
      phone_number: data?.phone_number,
      address: data?.address,
      visibility: data?.visibility,
      babynama_doctor: data?.babynama_doctor,
      specialty: data?.specialty,
    });
  }, [data]);

  useEffect(() => {
    if (openEditDoctorModal.open) {
      getDoctorDetails();
    }
  }, [openEditDoctorModal]);

  const handleEditUser = async (e) => {
    e.preventDefault();

    if (openEditDoctorModal.userId) {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}doctors/support/update-doctor/`,
          { user_id: openEditDoctorModal.userId?.toString(), ...formData },
          { headers }
        );

        if (response.status === 200) {
          toast.success("Doctor's details updated successfully!");
          setOpenEditDoctorModal({ open: false, userId: null });
          refresh();
        }
      } catch (error) {
        toast.error("Something went wrong while editing doctor data.");
        console.error("Edit user data", error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.warn("Invalid user ID. Please try again.");
    }
  };

  const closeModal = () => {
    setOpenEditDoctorModal({
      open: false,
      mobile: "",
      userId: null,
    });
    setFormData({});
  };

  const handleSpecialtyChange = (selectedSpecialties) => {
    setFormData({ ...formData, specialty: selectedSpecialties });
  };

  return (
    <Dialog
      open={openEditDoctorModal.open}
      onClose={closeModal}
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
    >
      <div
        className="bg-black fixed inset-0 bg-opacity-50"
        aria-hidden="true"
      />
      <div className="relative mx-auto w-full max-w-lg rounded-lg bg-white shadow-lg">
        <div className="flex items-center justify-between border-b p-4">
          <h3 className="text-lg font-medium text-gray-900">
            Edit Doctor Details
          </h3>
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-700"
          >
            <IoMdClose size={25} />
          </button>
        </div>

        <form onSubmit={handleEditUser} className="space-y-6 p-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                name="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                name="phoneNumber"
                value={formData.phone_number}
                onChange={(e) =>
                  setFormData({ ...formData, phone_number: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Degree
              </label>
              <input
                name="degree"
                value={formData.degree}
                onChange={(e) =>
                  setFormData({ ...formData, degree: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Medical Registration Number
              </label>
              <input
                name="reg number"
                value={formData.medical_registration_number}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    medical_registration_number: e.target.value,
                  })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <input
                name="degree"
                value={formData.address}
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Visibility
              </label>
              <select
                onChange={(e) =>
                  setFormData({ ...formData, visibility: e.target.value })
                }
                value={formData.visibility}
                name="visibility"
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option value="all_customer">All Customers</option>
                <option value="old_customer">Old Customers</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Babynama Doctor
              </label>
              <select
                value={formData.babynama_doctor}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    babynama_doctor: JSON.parse(e.target.value),
                  })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Specialty
              </label>
              <Listbox
                value={formData.specialty}
                onChange={handleSpecialtyChange}
                multiple
              >
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button className="inline-flex w-full items-center justify-between gap-2 rounded-md border border-gray-300 bg-white px-2 py-3 text-sm font-medium text-gray-700 shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                        {Array.isArray(formData.specialty) &&
                        formData.specialty.length > 0
                          ? `${formData.specialty.length} ${
                              formData.specialty.length > 1
                                ? "Specialties"
                                : "Specialty"
                            } Selected`
                          : "Select Specialty"}
                        {Array.isArray(formData.specialty) &&
                        formData.specialty.length > 0 ? (
                          <button
                            onClick={() => handleSpecialtyChange([])}
                            className="ml-2 flex items-center text-gray-500 hover:text-red-500"
                          >
                            <MdOutlineClose size={22} />
                          </button>
                        ) : (
                          <HiOutlineSelector size={22} />
                        )}
                      </Listbox.Button>

                      <Listbox.Options
                        className={`ring-black absolute bottom-[110%] mt-1 max-h-60 min-w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm ${
                          open ? "block" : "hidden"
                        }`}
                      >
                        {specialties.map((option, index) => (
                          <Listbox.Option
                            key={index}
                            value={option} // Send only the role name
                            className={({ active }) =>
                              `${
                                active
                                  ? "bg-green-500 text-white"
                                  : "text-gray-900"
                              } relative cursor-pointer select-none py-2 pl-3 pr-9`
                            }
                          >
                            {({ selected }) => (
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  readOnly
                                  checked={selected}
                                  className="form-checkbox w h-5 w-5 text-green-600"
                                />
                                <span
                                  className={`ml-3 block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {option}
                                </span>
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>

          <div>
            {loading ? (
              <div className="flex justify-center py-2">
                <SyncLoader size="8px" color="#22C55E" />
              </div>
            ) : (
              <button
                type="submit"
                className={`w-full rounded-md bg-green-600 px-4 py-2 text-white shadow-sm hover:bg-green-700`}
              >
                Update Doctor Details
              </button>
            )}
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default EditDoctorModal;
