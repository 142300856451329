import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FiSearch } from "react-icons/fi";
import { parseISO, format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "assets/css/filter.css";
import { HiExternalLink } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { ClipLoader } from "react-spinners";
import PData from "components/PData/PData";
import { getToken } from "utils/getToken";

const PendingDoctorChatStatus = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [filterPage, setFilterPage] = useState("");
  const [isGroupDropdownOpen, setIsGroupDropdownOpen] = useState(false);
  const [groupSearchQuery, setGroupSearchQuery] = useState("");
  const [visibleGroups, setVisibleGroups] = useState(30);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [groups, setGroups] = useState(["All Groups"]);
  const [groupData, setGroupData] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [lastMessageTime, setLastMessageTime] = useState("");
  const [isChatStatusModalOpen, setIsChatStatusModalOpen] = useState(false);
  const [groupDataLoaded, setGroupDataLoaded] = useState(false);
  const [isChatStatusSaveButtonDisabled, setIsChatStatusSaveButtonDisabled] =
    useState(data.map(() => false));
  const [mobileNumber, setMobileNumber] = useState("");
  const [isFilterStatusMenuOpen, setIsFilterStatusMenuOpen] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);
  const [newStatus, setNewStatus] = useState("default");
  // const [chatCurrentStatus, setChatCurrentStatus] = useState("null");
  const [currentChatId, setCurrentChatId] = useState(null);
  const [chatIndex, setChatIndex] = useState(null);
  const [
    isChatStatusModalOpenSaveButttonDisable,
    setIsChatStatusModalOpenSaveButttonDisable,
  ] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const groupDataFetcher = async () => {
    try {
      let url = process.env.REACT_APP_API_URL + "whatsapp/get-group-details/";

      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      //   console.log("response", response);

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setGroupDataLoaded(true);
        const groupNames = response.data.data.map((group) => group.group_name);

        // console.log("groupData", groupData);
        setGroups(["All Groups", ...groupNames]);
        setGroupData([
          {
            group_name: "All Groups",
            group_id: null,
          },
          ...response.data.data,
        ]);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      // setIsDisabled(false);
    }
  };

  const retrieveConversation = async (retries = 0) => {
    try {
      setIsPageLoading(true);
      const queryParams = {
        status: `'pending_doctor'`,
      };
      console.log(filterChanged);
      const timeInMinutes = parseInt(lastMessageTime, 10);
      if (retries >= 0 && !isNaN(timeInMinutes)) {
        queryParams.minutes = timeInMinutes;
      }
      if (selectedGroupId !== null) {
        queryParams.group_id = selectedGroupId;
      }
      if (mobileNumber.length > 7) {
        queryParams.mobile = mobileNumber;
      }
      if (filterChanged) {
        setCurrentPage(1);
      }
      if (currentPage) {
        queryParams.page = filterChanged ? 1 : currentPage;
      }
      const apiUrl = process.env.REACT_APP_API_URL + "whatsapp/get-chats/";
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
        params: queryParams,
      });
      if (filterChanged) {
        setFilterChanged(false);
      }
      if (response.status === 200) {
        // const newData = await privateGroupRetrieveConversation();
        // console.log("newData", newData);
        if (response.data.data.length === 0) {
          setIsSearching(false)
          toast.error("No data found for the selected filters.");
          setData([]);
        } else {
          const combinedData = [...response.data.data];
          setData(combinedData);
          setIsPageLoading(false);
          setIsSearching(false)
        }
      } else {
        toast.error("Failed to fetch data from the API.");
        setIsSearching(false)
      }
    } catch (error) {
      setIsSearching(false)
      console.error("Error fetching data:", error);
      toast.error("Error fetching data from the API.");
    }
  };
  // For private group api
  const privateGroupRetrieveConversation = async (retries = 0) => {
    try {
      setIsPageLoading(true);
      const queryParams = {
        status: `'pending_doctor'`,
      };
      const timeInMinutes = parseInt(lastMessageTime, 10);
      if (retries >= 0 && !isNaN(timeInMinutes)) {
        queryParams.minutes = timeInMinutes;
      }
      if (selectedGroupId !== null) {
        queryParams.group_id = selectedGroupId;
      }
      if (mobileNumber.length > 7) {
        queryParams.mobile = mobileNumber;
      }
      if (filterChanged) {
        setCurrentPage(1);
      }
      if (currentPage) {
        queryParams.page = filterChanged ? 1 : currentPage;
      }
      const privateApiUrl =
        process.env.REACT_APP_API_URL +
        "whatsapp/get-chats/?category=private-group";
      const response = await axios.get(privateApiUrl, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
        params: queryParams,
      });
      if (filterChanged) {
        setFilterChanged(false);
      }
      if (response.status === 200) {
        // console.log(response);
        if (response.data.data.length === 0) {
          return [];
        } else {
          return response.data.data;
        }
      } else {
        toast.error("Failed to fetch additional data from the API.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching additional data:", error);
      toast.error("Error fetching additional data from the API.");
      return [];
    }
  };

  // fetch initial data and group data
  useEffect(() => {
    groupDataFetcher();
  }, []);

  useEffect(() => {
    const interval = setInterval(retrieveConversation, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (groupDataLoaded) {
      retrieveConversation();
    }
  }, [currentPage, selectedGroupId, lastMessageTime, groupDataLoaded]);

  // For filters

  // For group change
  const handleGroupSelection = (selectedGroupName) => {
    const matchingGroup = groupData.find(
      (group) =>
        group.group_name.toLowerCase() === selectedGroupName.toLowerCase()
    );
    // console.log(matchingGroup);
    if (matchingGroup) {
      setSelectedGroup(matchingGroup.group_name);
      setSelectedGroupId(matchingGroup.group_id);
      setIsGroupDropdownOpen(false);
      setFilterChanged(true);
      //   console.log(selectedGroupId);
    } else {
      toast.error("Group not found with id:", selectedGroupName);
      console.log("Group not found with id:", selectedGroupName);
    }
  };
  const filteredGroups = groups.filter((group) =>
    group.toLowerCase().includes(groupSearchQuery.toLowerCase())
  );

  // for mobile filter

  const handleSearchByMobileNumber = () => {
    setIsSearching(true)
    setFilterChanged(true);
    retrieveConversation();
  };
  const handleMobileNumberRemoval = () => {
    setMobileNumber("");
    // console.log("mobileNumber", mobileNumber);
    setFilterChanged(true);
    retrieveConversation();
  };

  const handlePageSearch = () => {
    const pageNumber = parseInt(filterPage);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setMobileNumber("");
      setCurrentPage(pageNumber);
    }
  };

  const handleNextPage = () => {
    // if (currentPage < totalPages) {
    //   setCurrentPage((prevPage) => prevPage + 1);
    // }
    setCurrentPage((prevPage) => prevPage + 1);
    window.scrollTo(0, 0);
    setData([]);
  };

  const handlePrevPage = () => {
    // if (currentPage > 1) {
    //   setCurrentPage((prevPage) => prevPage - 1);
    // }
    setCurrentPage((prevPage) => prevPage - 1);
    setData([]);
    window.scrollTo(0, 0);
  };

  const handleGroupScroll = (e) => {
    const maxScroll = e.target.scrollHeight - e.target.clientHeight;
    setScrollPosition(e.target.scrollTop);

    if (scrollPosition >= maxScroll - 100) {
      setVisibleGroups((prevVisibleGroups) => prevVisibleGroups + 5);
    }
  };

  // Close groupDropDowmenu on clicking outside
  const groupDropDownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        groupDropDownRef.current &&
        !groupDropDownRef.current.contains(event.target)
      ) {
        setIsGroupDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // end group change

  const handleChatStatusChangeEdit = (chatId, currentStatus, rowIndex) => {
    setIsChatStatusModalOpen(true);
    setCurrentChatId(chatId);
    setChatIndex(rowIndex);
    setNewStatus(currentStatus);
  };

  const handleChatStatusChange = async (chatId, currentStatus, rowIndex) => {
    const url = process.env.REACT_APP_API_URL + "whatsapp/chat-update/";
    const payload = {
      chat_status: currentStatus,
      chat_id: chatId,
    };
    try {
      const updatedDisabledState = [...isChatStatusSaveButtonDisabled];
      updatedDisabledState[rowIndex] = true;
      setIsChatStatusSaveButtonDisabled(updatedDisabledState);
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      // console.log(response);
      // console.log(response.data);
      if (response.status === 200) {
        const itemIndex = data.findIndex((item) => item.chat_id === chatId);
        console.log("itemIndex", itemIndex);

        if (itemIndex !== -1) {
          // Create a copy of the data array with the updated Chat_status
          const updatedData = [...data];
          updatedData[itemIndex].Chat_status = response.data.updated_status;
          updatedData[itemIndex].Chat_last_updated = response.data.updated_time;
          // Update the state with the new data
          setData(updatedData);
          updatedDisabledState[rowIndex] = false;
          setIsChatStatusSaveButtonDisabled(updatedDisabledState);
          setIsChatStatusModalOpen(false);
          toast.success("Chat status updated successfully");
        }
      }
    } catch (error) {
      console.log("Error updating data:", error);
      const updatedDisabledState = [...isChatStatusSaveButtonDisabled];

      updatedDisabledState[rowIndex] = false;
      setIsChatStatusSaveButtonDisabled(updatedDisabledState);
      toast.error("Error updating data:", error);
    }
  };

  const handleLastMessageTimeChange = (value) => {
    setLastMessageTime(value);
    setFilterChanged(true);
  };

  // For navigating to that particular chat
  const [highlightedRow, setHighlightedRow] = useState(null);
  function createLinkAndOpenChat(row, rowIndex) {
    if (!row.Chat_start_time) {
      return "";
    }

    const chatStartTime = new Date(row.Chat_start_time);
    const startTimeOffset = chatStartTime.getTimezoneOffset();

    const lastMessageTime = new Date(row.event_time);
    const lastMessageTimeOffset = lastMessageTime.getTimezoneOffset();
    const startTime = new Date(
      chatStartTime.getTime() - 2 * 60 * 1000 - startTimeOffset * 60 * 1000
    );
    const endTime = new Date(
      lastMessageTime.getTime() +
        2 * 60 * 1000 -
        lastMessageTimeOffset * 60 * 1000
    );
    const startTimeISO = startTime.toISOString().slice(0, 19);
    const endTimeISO = endTime.toISOString().slice(0, 19);
    // console.log(startTimeISO, endTimeISO);

    const url = `/admin/whatsapp-chat?group_id=${
      row.Whatsappgroup_id
    }&start_time=${startTimeISO}&end_time=${endTimeISO}&mobile_number=${row.Chat_started_by.slice(
      1
    )}`;

    const newWindow = window.open(`${url}`, "_blank", "width=900,height=900");
    if (newWindow) {
      newWindow.focus();
      setHighlightedRow(rowIndex);
    }
  }

  // For PData Modal

  const [selectedUser, setSelectedUser] = useState(null);
  const [isPDataModalOpen, setIsPDataModalOpen] = useState(false);
  const handlePDataModal = (row) => {
    setSelectedUser(row.Chat_started_by);
    // console.log(row.Chat_started_by);
    setIsPDataModalOpen(true);
  };

  const handlePDataModalClose = () => {
    setIsPDataModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <>
      <PData
        mobile={selectedUser}
        isPDataModalOPen={isPDataModalOpen}
        onClose={() => {
          handlePDataModalClose();
        }}
      />
      <div className="mt-5 ">
        <div className="flex justify-between">
          <h1 className="items-center justify-center text-center text-2xl font-bold capitalize text-blue-500 dark:text-white">
            Pending Doctor Chat's Status
          </h1>
          <button
            className="rounded-lg bg-blue-500 px-8 py-2 text-white hover:bg-blue-600"
            onClick={() => retrieveConversation()}
          >
            Refresh
          </button>
        </div>

        {/* Filters */}
        <div className="flex flex-col py-4 md:flex-row md:justify-between ">
          {/* Filter using group name */}
          <div className="flex flex-col">
            <div
              className="flex w-[80vw] flex-col rounded-xl md:w-auto"
              ref={groupDropDownRef}
            >
              <button
                type="button"
                className={`focus:ring-primary relative inline-flex items-center rounded-md border ${
                  selectedGroup ? "text-green-500" : "text-black"
                } border-gray-300 bg-white px-4 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1 `}
                id="options-menu"
                aria-haspopup="listbox"
                aria-expanded="true"
                onClick={() => setIsGroupDropdownOpen(!isGroupDropdownOpen)}
              >
                {selectedGroup ? (
                  <>
                    {selectedGroup}
                    <svg
                      className="mt-2 mr-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 5.293a1 1 0 011.414 0L10 6.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {selectedGroup !== "All Groups" && (
                      <span
                        className="ml-2 cursor-pointer text-red-500"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the button's click event from firing
                          handleGroupSelection("All Groups"); // Clear the group filter
                        }}
                      >
                        <MdCancel />
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    Filter using group name
                    <svg
                      className="mt-2 mr-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 5.293a1 1 0 011.414 0L10 6.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </>
                )}
              </button>
              {/* Groupdropdown */}
              {isGroupDropdownOpen && (
                <div className="focus:ring-primary absolute z-10 mt-11 w-auto origin-top-left divide-y divide-gray-200 rounded-md border border-gray-300 bg-white shadow-lg ring-1 ring-opacity-5 focus:outline-none">
                  <input
                    type="text"
                    placeholder="Search groups..."
                    value={groupSearchQuery}
                    onChange={(e) => setGroupSearchQuery(e.target.value)}
                    className="border-b border-gray-300 px-3 py-2 focus:outline-none"
                  />
                  <div
                    className="max-h-40 overflow-y-auto"
                    onScroll={handleGroupScroll}
                  >
                    {filteredGroups
                      .slice(0, visibleGroups)
                      .map((groupName, index) => (
                        <div
                          key={groupName + index}
                          className={`text-black cursor-pointer select-none px-4 py-2 text-sm `}
                          onClick={() => {
                            handleGroupSelection(groupName);
                          }}
                        >
                          {groupName}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Filter by Last Message Time */}
          <div className="relative inline-flex items-center">
            <select
              id="lastMessageTime"
              className={`focus:ring-primary rounded-md border border-gray-300 bg-white px-4 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1 ${
                lastMessageTime !== "gamma" && lastMessageTime !== ""
                  ? "text-green-500"
                  : "text-black"
              }`}
              value={lastMessageTime}
              onChange={(e) => {
                handleLastMessageTimeChange(e.target.value);
              }}
            >
              <option value="gamma">Filter By Last Message Time</option>
              <option value="">All</option>
              <option
                value="5 mins"
                className={
                  lastMessageTime === "5 mins" ? "text-green-500" : "text-black"
                }
              >
                5 mins ago
              </option>
              <option
                value="10 mins"
                className={
                  lastMessageTime === "10 mins"
                    ? "text-green-500"
                    : "text-black"
                }
              >
                10 mins ago
              </option>
              <option
                value="20 mins"
                className={
                  lastMessageTime === "20 mins"
                    ? "text-green-500"
                    : "text-black"
                }
              >
                20 mins ago
              </option>
              <option
                value="30 mins"
                className={
                  lastMessageTime === "30 mins"
                    ? "text-green-500"
                    : "text-black"
                }
              >
                30 mins ago
              </option>
            </select>
            {lastMessageTime && lastMessageTime !== "gamma" && (
              <button
                className="ml-2.5 cursor-pointer text-red-500"
                onClick={() => setLastMessageTime("gamma")}
              >
                <MdCancel />
              </button>
            )}
          </div>

          {/* Filters via mobile number */}
          <div
            id="dropdown"
            className="flex min-w-[25vw] items-center gap-x-1 self-center rounded-md bg-white px-2 py-1"
          >
            <input
              type="tel"
              className="bg-50 h-10 flex-grow rounded-md px-3 text-sm text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-white"
              id="mobile"
              placeholder="Search By Mobile Number"
              aria-label="Search"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
            <div className="flex">
              <button
                className="rounded-md bg-blue-500 px-4 py-2 text-white shadow hover:bg-blue-600 disabled:opacity-50"
                type="button"
                id="button-addon1"
                onClick={() => handleSearchByMobileNumber()}
              >
                {isSearching ? "Loading..." : "Search"}
              </button>
            </div>
          </div>
        </div>
        {/* Filters end */}
        {isChatStatusModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="modal-overlay absolute h-full w-full bg-gray-900 opacity-50"></div>
            <div className="modal-container z-50 mx-auto w-96 rounded bg-white shadow-lg">
              <div className="modal-content p-6">
                <button
                  className="relative left-[330px] text-gray-600 hover:text-gray-800"
                  onClick={() => setIsChatStatusModalOpen(false)}
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
                <h2 className="mb-4 text-lg font-semibold">Change Status</h2>
                <select
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  className="w-full rounded-md border p-2"
                >
                  <option value="default">Choose the status</option>
                  <option value="closed">Closed</option>
                  <option value="active">Active</option>
                  <option value="replied">Replied</option>
                  <option value="open">Open</option>
                </select>
                <div className="mt-4 flex justify-end">
                  <button
                    className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                    onClick={() => {
                      handleChatStatusChange(
                        currentChatId,
                        newStatus,
                        chatIndex
                      );
                    }}
                    disabled={isChatStatusModalOpenSaveButttonDisable}
                  >
                    {isChatStatusModalOpenSaveButttonDisable
                      ? "Please Wait..."
                      : "Save"}
                  </button>

                  <button
                    className="ml-2 rounded-md bg-gray-400 px-4 py-2 text-white"
                    onClick={() => setIsChatStatusModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {!isPageLoading ? (
          <table className="w-full bg-white shadow-md ">
            <thead>
              <tr className="bg-blue-200">
                <th className="border px-2 py-2 ">Group Name</th>
                <th className="border px-4 py-2">User</th>
                <th className="border px-4 py-2">Last Message</th>
                <th className="border px-4 py-2">Chat Start Time</th>
                <th className="border px-4 py-2">Last Message Time</th>
                <th className="border px-4 py-2">Updated At</th>
                <th className="border px-4 py-2">Link</th>
                <th className="border px-4 py-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={`${
                    rowIndex === highlightedRow
                      ? "bg-yellow-500"
                      : "hover:bg-blue-100"
                  }`}
                >
                  <td className="border px-2 py-2">{row.Group_name}</td>
                  <td className="border px-4 py-2">
                    <div className="flex flex-col justify-center gap-3">
                      <button
                        className="flex cursor-pointer items-center gap-2 text-blue-500 hover:underline"
                        onClick={() => {
                          handlePDataModal(row);
                        }}
                      >
                        {row.Chat_started_by} <HiExternalLink />
                      </button>

                      <p>{row?.user_name || ""}</p>
                    </div>
                  </td>
                  <td className="w-1/5 border px-4 py-2">{row.message}</td>
                  <td className="border px-4 py-2">
                    {row.Chat_start_time ? (
                      format(
                        parseISO(row.Chat_start_time),
                        "dd-MM-yyyy HH:mm:ss"
                      )
                    ) : (
                      <p className="text-gray-500">Not available</p>
                    )}
                  </td>
                  <td className="border px-4 py-2">
                    {row.event_time ? (
                      format(parseISO(row.event_time), "dd-MM-yyyy HH:mm:ss")
                    ) : (
                      <p className="text-red-500">Not replied</p>
                    )}
                  </td>
                  <td className="border px-4 py-2">
                    {row.Chat_last_updated
                      ? format(
                          parseISO(row.Chat_last_updated),
                          "dd-MM-yyyy HH:mm:ss"
                        )
                      : "Not available"}
                  </td>
                  <td className="border px-4 py-2">
                    <button
                      className="cursor-pointer text-blue-500 hover:underline"
                      onClick={() => {
                        createLinkAndOpenChat(row, rowIndex);
                      }}
                    >
                      Open chat <HiExternalLink />
                    </button>
                  </td>
                  <td className="border px-4 py-2">
                    <p
                      className={`text-sm font-semibold capitalize ${
                        row.Chat_status === "active"
                          ? "text-yellow-500"
                          : row.Chat_status === "replied"
                          ? "text-green-500"
                          : row.Chat_status === "closed"
                          ? "text-gray-500"
                          : row.Chat_status === "open"
                          ? "text-red-500"
                          : "text-red-500"
                      }`}
                    >
                      {row.Chat_status === "active"
                        ? "Live (Active)"
                        : row.Chat_status === "open"
                        ? "Live (Open)"
                        : row.Chat_status}
                    </p>
                    <button
                      className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                      onClick={() =>
                        handleChatStatusChange(row.chat_id, "closed", rowIndex)
                      }
                      disabled={isChatStatusSaveButtonDisabled[rowIndex]}
                    >
                      {isChatStatusSaveButtonDisabled[rowIndex]
                        ? "Please Wait..."
                        : "Close"}
                    </button>
                    <button
                      className="mt-2 rounded-md bg-blue-500 px-3 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                      onClick={() =>
                        handleChatStatusChangeEdit(
                          row.chat_id,
                          row.Chat_status,
                          rowIndex
                        )
                      }
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : data.length > 0 ? (
          <div className="flex h-[70vh] items-center justify-center">
            <ClipLoader color={"blue"} size={60} />
          </div>
        ) : (
          <p className="text-center text-lg font-semibold">No data available</p>
        )}

        <div className="mt-10 flex flex-col justify-center md:mt-20 md:flex-row">
          <div className="flex justify-center md:ml-auto">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline-flex h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              Previous Page
            </button>
            {/* {totalPages > 1 &&
            [
              ...Array(Math.min(numberOfButtons, totalPages - currentPage + 1)),
            ].map((_, index) => {
              const pageNumber = currentPage + index;
              return (
                <button
                  key={index}
                  onClick={() => setCurrentPage(pageNumber)}
                  disabled={pageNumber > totalPages}
                  className={`ml-2 rounded-lg px-4 py-2 ${
                    currentPage === pageNumber
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })} */}

            <h3 className="flex items-center px-2 text-lg text-gray-800 ">
              Page {currentPage}
            </h3>

            <button
              onClick={handleNextPage}
              // disabled={currentPage === totalPages}
              className="mx-2 rounded-lg bg-blue-500 px-4 py-4 text-white hover:bg-blue-600 md:px-8"
            >
              Next Page
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline-flex h-5 w-5 -rotate-180 transform"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="mt-5 flex items-center justify-end md:ml-auto md:mt-0">
            <input
              type="text"
              placeholder={`Go to page (1 - ${totalPages})`}
              value={filterPage}
              onChange={(e) => setFilterPage(e.target.value)}
              className="w-full rounded-md border border-gray-400 bg-white py-2 pl-8 pr-2 text-sm font-medium text-gray-800 outline-none"
            />
            <button
              className="mx-2 cursor-pointer rounded-lg bg-blue-500 px-4 py-3 text-white hover:bg-blue-300"
              onClick={handlePageSearch}
            >
              <FiSearch
                className="h-4 w-4 cursor-pointer rounded-lg"
                onClick={handlePageSearch}
              />
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default PendingDoctorChatStatus;
