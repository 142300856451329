import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { BiSearchAlt2 } from "react-icons/bi";
import { MdCancel } from "react-icons/md";

export const FiltersDropdown = ({
  isDropdownOpen,
  toggleDropdown,
  mobileNumberSearchQuery,
  setMobileNumberSearchQuery,
  handleMobileNumberSearch,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  handleDateTimeSearch,
  isDisabled,
  handleInputFocus,
  filterIcon,
  dropdownRef,
  isDropdownPhoneOpen,
  phoneToggleDropdown,
  closePhoneDropdowns,
}) => {
  return (
    <div className="" ref={dropdownRef}>
      <button
        id="dropdownDefault"
        data-dropdown-toggle="dropdown"
        onClick={phoneToggleDropdown}
        className="text-black inline-flex items-center rounded-lg px-4 py-2 text-center text-xl font-bold "
        type="button"
      >
        <BiSearchAlt2 />
      </button>
      {isDropdownPhoneOpen && (
        <div
          id="dropdown"
          className="talk-bubble tri-right btm-right-alpha absolute right-[55px] top-[110px] mb-4 flex w-[80vw] flex-wrap items-stretch rounded-xl bg-white p-3 md:right-[100px] md:top-[65px] md:w-[20vw]"
        >
          <input
            type="tel"
            className="border-neutral-300 bg-transparent text-neutral-700 focus:border-primary focus:text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none"
            id="mobile"
            placeholder="Search Mobile Number"
            aria-label="Search"
            aria-describedby="button-addon1"
            value={mobileNumberSearchQuery}
            onChange={(e) => setMobileNumberSearchQuery(e.target.value)}
          />
          <button
            className="active:bg-primary-800 relative z-[2] flex items-center rounded-r bg-[#113f6ec6] px-6 text-xs font-medium  uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            type="button"
            id="button-addon1"
            data-te-ripple-init
            data-te-ripple-color="light"
            onClick={handleMobileNumberSearch}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5"
            >
              <path
                fillRule="evenodd"
                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <button
            className="active:bg-primary-800 relative z-[2] mx-2 flex items-center bg-red-500 px-4 text-xs font-medium  uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-300 hover:shadow-lg focus:bg-red-300 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            type="button"
            id="button-addon1"
            data-te-ripple-init
            data-te-ripple-color="light"
            onClick={closePhoneDropdowns}
          >
            <MdCancel />
          </button>
        </div>
      )}
      <button
        id="dropdownDefault"
        data-dropdown-toggle="dropdown"
        onClick={toggleDropdown}
        className="text-black inline-flex items-center rounded-lg px-4 py-2 text-center text-xl font-bold "
        type="button"
      >
        {filterIcon}
      </button>
      {isDropdownOpen && (
        <div
          id="dropdown"
          className="talk-bubble tri-right btm-right-alpha absolute right-[15px] top-28  grid w-[80vw] grid-cols-1 gap-4 rounded-xl  bg-white p-3  dark:bg-gray-700 sm:w-[28vw] md:top-16 md:right-[50px]  md:w-[20vw]"
        >
          <div>
            <h6 className="mb-3 text-base font-medium text-gray-900 dark:text-white">
              Filter By Time Range
            </h6>
            <div className="flex flex-col">
              <label htmlFor="startTime" className="pr-1 font-medium">
                Start Time (24 hr format):
              </label>
              <DateTimePicker
                onChange={setStartTime}
                value={startTime}
                placeholder="Select a start date and time"
                className="custom-datetime-picker w-[50%] rounded-xl border focus:border-blue-500 focus:outline-none md:w-full"
                onFocus={handleInputFocus}
                format="dd-MM-y HH:mm"
                disableClock={true}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="endTime" className="pr-1 font-medium">
                End Time (24hr format):
              </label>
              <DateTimePicker
                onChange={setEndTime}
                value={endTime}
                placeholder="Select end date and time"
                className="custom-datetime-picker w-full rounded-xl border focus:border-blue-500 focus:outline-none"
                onFocus={handleInputFocus}
                format="dd-MM-y HH:mm"
                disableClock={true}
              />
            </div>
            <button
              className={`mt-2 w-full rounded px-4 py-2 text-white md:col-span-2 ${
                isDisabled
                  ? "cursor-not-allowed bg-gray-300"
                  : "bg-[#113f6ec6] hover:bg-[#244668c6]"
              }`}
              disabled={isDisabled}
              onClick={handleDateTimeSearch}
            >
              {isDisabled ? "Loading..." : "Search"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
