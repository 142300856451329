import React from "react";
import "./WhatsappChat.css";
import { formatTimestamp } from "utils/formatTimeStamp";

export default function MessagePersonal({
  chatResponse,
  query,
  selectedTeamType,
}) {
  const isMatch =
    query.length > 7 &&
    chatResponse.to_phone &&
    chatResponse.to_phone.includes(query);
  if (isMatch) {
    console.log(chatResponse.to_phone, query, isMatch);
  }
  return (
    <div className="row message-body">
      {chatResponse.to_phone && (
        <div className={`col-sm-12 message-main-receiver px-2 py-4`}>
          <div
            className={`receiver bg-white  ${isMatch ? "bg-yellow-500" : ""}`}
          >
            <div className="flex justify-between">
              <div className={`font-bold text-green-500`}>
                {chatResponse.to_phone}
              </div>
              <div>~{""} customer</div>
            </div>
            <div className="message-text">{chatResponse.message}</div>
            <span className="message-time text-right">
              {formatTimestamp(chatResponse.event_time)}
            </span>
          </div>
        </div>
      )}
      {chatResponse.from_phone === ("+918864865605" || "+917303786959") && (
        <div className="col-sm-12 message-main-sender py-4">
          <div className={`sender py-4 ${"bg-[#dcf8c6]"}`}>
            <div className="flex justify-between">
              <div className="font-bold text-blue-500">
                {chatResponse.from_phone}
              </div>
              <div>
                ~{""} {selectedTeamType}
              </div>
              <div className="message-text">{chatResponse.message}</div>
            </div>
            <span className="message-time text-right">
              {formatTimestamp(chatResponse.event_time)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
