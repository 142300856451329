import WhatsappChat from "../../../components/WhatsappChat/ChatMain.jsx";
import React from "react";

export default function index() {
  return (
    <>
      <WhatsappChat />
    </>
  );
}
