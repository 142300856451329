import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { formatToDateAndTime } from "utils/formatTime";
import { MdOpenInNew } from "react-icons/md";
import SyncLoader from "components/Loaders/SyncLoader";
import { toast, ToastContainer } from "react-toastify";
import { getToken } from "utils/getToken";

const headers = {
  Authorization: `Token ${getToken()}`,
};

const FollowUpChatMessages = () => {
  const [messages, setMessages] = useState([]);
  const [initialMessageLoad, setInitialMessageLoad] = useState(false);
  const [endChatLoading, setEndChatLoading] = useState(false);
  const [groupedMessages, setGroupedMessages] = useState([]);
  const [typeMessage, setTypeMessage] = useState(
    "Message from Babynama Support - "
  );
  const [isMessageSending, setIsMessageSending] = useState(false);
  const messagesEndRef = useRef(null);
  const [currentChatStatus, setCurrentChatStatus] = useState("");
  const [currentChatId, setCurrentChatId] = useState("");
  const [showMessageForm, setShowMessageForm] = useState(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Accessing query parameters
  const userId = queryParams.get("userId");
  const chatId = queryParams.get("chatId");
  const chatStatus = queryParams.get("status");

  const getInitialFollowUpChats = async () => {
    try {
      setInitialMessageLoad(true);
      const payload = {
        user_id: parseInt(userId),
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}live-chat/get-user-followup-chat-message/`,
        payload,
        { headers }
      );

      if (response.data.status === 1) {
        setMessages(response.data.messages);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setInitialMessageLoad(false);
    }
  };

  const fetchFollowUpChatsMessages = async () => {
    try {
      const payload = {
        user_id: parseInt(userId),
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}live-chat/get-user-followup-chat-message/`,
        payload,
        { headers }
      );

      if (response.data.status === 1) {
        setMessages(response.data.messages);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView(true);
    }
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    setTypeMessage("");
    if (userId && typeMessage) {
      try {
        setIsMessageSending(true);
        const payload = {
          user_id: parseInt(userId),
          message: typeMessage,
        };
        const sendMessageResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}live-chat/send-followup-message/`,
          payload,
          {
            headers: {
              Authorization: `Token ${getToken()}`,
            },
          }
        );

        if (sendMessageResponse.status === 200) {
          toast("Message sent to the patient successfully!");
          if (currentChatStatus !== "open") {
            setCurrentChatStatus("open");
          }

          // if (chatId === "null") {
          //   setCurrentChatId(sendMessageResponse?.data?.chat_id);
          // }
        }

        fetchFollowUpChatsMessages();
      } catch (error) {
        if (error?.response?.status === 409) {
          toast("Failed to send message since this chat is closed!");
        } else {
          toast(
            "Something Went Wrong while sending message to the patient, Please check your network!"
          );
        }
        console.error("Error sending message:", error);
      } finally {
        setIsMessageSending(false);
      }
    }
  };

  //For Fetching messages in every 10 seconds.
  useEffect(() => {
    if (!userId) return;
    const interval = setInterval(fetchFollowUpChatsMessages, 10000);
    return () => clearInterval(interval);
  }, [userId]);

  // Group messages based on changed ChatID
  useEffect(() => {
    const newGroupedMessages = [];

    if (messages.length > 0) {
      // Initialize variables to keep track of the current chat_id and the current array
      let currentChatId = null;
      let currentChatGroup = null;

      // Iterate through the messages array
      messages.forEach((currentMessage, index) => {
        // Check if this is the first iteration or if the current chat_id is different from the previous one
        if (index === 0 || currentMessage.chat_id !== currentChatId) {
          // Create a new array for the current chat_id
          currentChatGroup = [currentMessage];

          // Add the new array to newGroupedMessages
          newGroupedMessages.push(currentChatGroup);

          // Update the current chat_id
          currentChatId = currentMessage.chat_id;
        } else {
          // If the current chat_id is the same as the previous one, add the message to the existing array
          currentChatGroup.push(currentMessage);
        }
      });

      setGroupedMessages(newGroupedMessages);
    }
  }, [messages.length]);

  useEffect(() => {
    if (userId) {
      getInitialFollowUpChats();
    }
    if (chatStatus) {
      setCurrentChatStatus(chatStatus);
    }
    if (chatStatus !== "open") {
      setShowMessageForm(false);
    }
  }, [userId]);

  //For Scrolling to the last message
  useEffect(() => {
    scrollToBottom();
  }, [groupedMessages]);

  console.log("ChatId", chatId, typeof chatId);
  return (
    <div className="w-full pt-5">
      <div className="text-black pb-16 antialiased">
        <div>
          {groupedMessages.length > 0 ? (
            groupedMessages.map((messages, index) => {
              const boxColor =
                index % 2 === 0 ? "bg-[#F3F8FF]" : "bg-[#F3FDE8]";

              return (
                <div key={index} className="mb-4">
                  <div className={`${boxColor} mb-2 rounded border  p-2`}>
                    <div className="flex items-center justify-center gap-2">
                      <p>
                        Chat Start Time:{" "}
                        <span className="text-sm uppercase text-blue-600">
                          {formatToDateAndTime(messages[0]?.time)}
                        </span>
                      </p>
                    </div>

                    {messages.map((msg, index) => (
                      <div
                        key={index}
                        className={`relative mt-4 flex font-semibold ${
                          msg?.user_type === null
                            ? "justify-center"
                            : msg?.user_type === "doctor" ||
                              msg?.user_type === "support"
                            ? "justify-end"
                            : "justify-start"
                        }`}
                      >
                        <div
                          className={`w-[80%] rounded px-4 py-2 text-gray-700 shadow transition-all duration-300 ease-in-out md:w-auto md:max-w-md lg:max-w-2xl ${
                            msg?.user_type === null
                              ? " bg-green-100 "
                              : msg?.user_type === "doctor"
                              ? "bg-[#ddf7c8]"
                              : "bg-white"
                          }`}
                        >
                          <p
                            className={`text-sm ${
                              msg?.user_type === "doctor"
                                ? "text-blue-500"
                                : "text-yellow-500 "
                            }`}
                          >
                            {msg?.user_type === "doctor" && <span>Dr. </span>}
                            {msg?.user_name} &nbsp;
                          </p>

                          {msg?.media_url && (
                            <img
                              src={msg?.media_url}
                              alt={`message_img-${index}`}
                              className="my-2 max-h-96 max-w-full rounded-md object-cover lg:max-w-xl"
                            />
                          )}

                          {msg?.media_url && (
                            <div className="my-2 flex">
                              <a
                                href={msg?.media_url}
                                target="_blank"
                                rel="noreferrer"
                                className="flex items-center gap-2 rounded-md bg-blue-500 p-1 text-sm text-white"
                              >
                                Open Media <MdOpenInNew />
                              </a>
                            </div>
                          )}

                          {msg?.message && (
                            <p
                              className={`text-slate-800 whitespace-break-spaces break-words font-medium
                ${
                  msg?.user_type === null ? "bg-green-100 text-sm" : "text-base"
                }
                `}
                            >
                              {msg?.message}
                            </p>
                          )}

                          <p className="text-slate-500 mt-1 text-xs font-normal">
                            {formatToDateAndTime(msg?.time)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })
          ) : initialMessageLoad ? (
            <div className="flex min-h-screen items-center justify-center">
              <SyncLoader color="#525CEB" size="10px" />
            </div>
          ) : (
            <div className="flex min-h-[80vh] items-start justify-center pt-40">
              <p className="text-center text-gray-500">No Messages Found</p>
            </div>
          )}
        </div>

        {showMessageForm ? (
          <form
            className="fixed bottom-1 left-0 right-0 bg-white px-3 py-4 shadow-lg"
            onSubmit={handleSendMessage}
          >
            <div className="flex items-center">
              <input
                type="text"
                className="mr-2 flex-grow rounded-md bg-gray-200 px-3 py-2 text-sm text-gray-700"
                value={typeMessage}
                onChange={(e) => setTypeMessage(e.target.value)}
                required
              />
              <button
                type="submit"
                disabled={isMessageSending}
                className={`rounded-md bg-blue-500 px-3 py-2 text-sm text-white`}
              >
                {isMessageSending ? (
                  <SyncLoader color="#F1EFEF" size="5px" />
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        ) : (
          <div className="bg-black/10 fixed bottom-1 right-0 left-0 px-3 py-3 shadow-lg">
            <div className="flex w-full items-center justify-center">
              <button
                onClick={() => {
                  setShowMessageForm(true);
                }}
                type="submit"
                className={`rounded-md bg-blue-500 px-10 py-2 text-sm text-white hover:bg-blue-700 md:px-14 md:text-base`}
              >
                Start Chat
              </button>
            </div>
          </div>
        )}
      </div>

      <div ref={messagesEndRef} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default FollowUpChatMessages;
