import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Listbox } from "@headlessui/react";
import { HiOutlineSelector } from "react-icons/hi";
import { MdCancel, MdOutlineClose } from "react-icons/md";
import { getToken } from "utils/getToken";
import dayjs from "dayjs";
import AppointnmentMobileView from "./AppointmentMobileView";
import { useLocation } from "react-router-dom";
import {
  dataState,
  currentPageState,
  totalPagesState,
  appointmentDateState,
  appointmentTypeState,
  appointmentStatusState,
  ratingState,
  feedbackState,
  remarkUpdateState,
  selectedUserState,
  filterChangeForUserState,
  statusFilterState,
  filterSelectedForStatusState,
  filterSelectedForUserState,
  mobileNumberState,
  showModalState,
  doctorIdState,
  doctorsDataState,
  mainLoaderState,
  statusModalOpenState,
  isSaveStatusButtonDisabledState,
  isAppointmentDetailsUpdatedState,
  consultationTypeFilterState,
  isVideoConsultationAllowedState,
  openAddUpdatePatientModalState,
} from "../../store/appointment/atoms/appointmentAtomState";
import { useRecoilState } from "recoil";
import AppointmentDesktopView from "./AppointmentDesktopView";
import AppointmentPagination from "./AppointmentPagination";
import AppointmentModal from "./AppointmentModal";
import { ToastContainer, toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import AppointmentStatusModal from "./AppointmentStatusModal";

const consultationTypeFilterOptions = [
  { name: "All", value: "all" },
  { name: "Growth", value: "growth" },
  { name: "Nutrition", value: "nutrition" },
  { name: "Gyne", value: "gyne" },
  { name: "Pediatric", value: "pediatric" },
  { name: "Lactation", value: "lactation" },
  { name: "Derma", value: "derma" },
  { name: "Course", value: "course" },
  { name: "Webinar", value: "webinar" },
];

const appointmentStatusFilterOptions = [
  { name: "All Appointments", value: "all" },
  { name: "Created", value: "created" },
  { name: "Scheduled", value: "scheduled" },
  { name: "Pending Confirmation", value: "pending_confirmation" },
  { name: "On Going", value: "on_going" },
  { name: "Upcoming Appointments", value: "custom_filter" },
  { name: "Consultation Done", value: "consultation_done" },
  { name: "Cancelled", value: "cancelled" },
  { name: "Closed", value: "closed" },
];

const ratingFilterOptions = [
  { name: "All", value: "all" },
  { name: "Not Filled", value: "no_rating" },
  { name: "Not Satisfied", value: "1" },
  { name: "Satisfied", value: "2" },
  { name: "Very Satisfied", value: "3" },
];

const AppointmentMain = () => {
  const [token] = useState(getToken());
  const [data, setData] = useRecoilState(dataState);
  const [fetchingData, setFetchingData] = useState(false);
  const [mobileNumber, setMobileNumber] = useRecoilState(mobileNumberState);

  const [selectedUser, setSelectedUser] = useRecoilState(selectedUserState);
  const [showModal, setShowModal] = useRecoilState(showModalState);
  const [appointmentType, setAppointmentType] =
    useRecoilState(appointmentTypeState);
  const [appointmentStatus, setAppointmentStatus] = useRecoilState(
    appointmentStatusState
  );
  const [previousRemark, setPreviousRemark] = useState([]);
  const [isStatusModalOpen, setIsStatusModalOpen] =
    useRecoilState(statusModalOpenState);
  const [currentPage, setCurrentPage] = useRecoilState(currentPageState);
  const [totalPages, setTotalPages] = useRecoilState(totalPagesState);
  const [filterPage, setFilterPage] = useState("");
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [isSearchButtonLoading, setIsSearchButtonLoading] = useState(false);
  const [appointmentDate, setAppointmentDate] =
    useRecoilState(appointmentDateState);
  const [filterChangeForUser, setFilterChangeForUser] = useRecoilState(
    filterChangeForUserState
  );
  const [isFilterSelectedForUser, setIsFilterSelectedForUser] = useRecoilState(
    filterSelectedForUserState
  );
  const [isFilterSelectedForStatus, setIsFilterSelectedForStatus] =
    useRecoilState(filterSelectedForStatusState);

  const [doctorId, setDoctorId] = useRecoilState(doctorIdState);
  const [feedback, setFeedback] = useRecoilState(feedbackState);
  const [rating, setRating] = useRecoilState(ratingState);
  const [modalContent, setModalContent] = useState(null);
  const [doctorsData, setDoctorsData] = useRecoilState(doctorsDataState);
  const [mainLoader, setMainLoader] = useRecoilState(mainLoaderState);
  const [isSaveStatusButtonDisabled, setIsSaveStatusButtonDisabled] =
    useRecoilState(isSaveStatusButtonDisabledState);
  const [isVideoConsultationAllowed, setIsVideoConsultationAllowed] =
    useRecoilState(isVideoConsultationAllowedState);

  const [isAppointmentDetailsUpdated, setIsAppointmentDetailsUpdated] =
    useRecoilState(isAppointmentDetailsUpdatedState);

  const [consultationTypeSelectedFilter, setConsultationTypeSelectedFilter] =
    useState(consultationTypeFilterOptions);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [appointmentFilterStatus, setAppointmentFilterStatus] = useState([
    appointmentStatusFilterOptions[5],
  ]);
  const [ratingSelectedFilter, setRatingSelectedFilter] = useState([
    ratingFilterOptions[0],
  ]);
  const [openAddUpdatePatientModal, setOpenAddUpdatePatientModal] =
    useRecoilState(openAddUpdatePatientModalState);
  const [isPatientAttachedToAppointment, setIsPatientAttachedToAppointment] =
    useState(true);
  const [selectedFilterDate, setSelectedFilterDate] = useState(null);
  const [source,setSource]=useState('support_portal');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mobileNumberFromQuery = queryParams.get("mobile_number");
  const appointmentStatusFilterOptionsFromQuery = queryParams.get("status");
  const consultationTypeFilterOptionsFromQuery = queryParams.get("type");

  // useEffect(() => {
  //   if (mobileNumberFromQuery) {
  //     setMobileNumber(`+${mobileNumberFromQuery}`);
  //   }
  //   if (appointmentStatusFilterOptionsFromQuery) {
  //     setAppointmentFilterStatus([
  //       appointmentStatusFilterOptions[appointmentStatusFilterOptionsFromQuery],
  //     ]);
  //   }
  //   if (consultationTypeFilterOptionsFromQuery) {
  //     setConsultationTypeSelectedFilter([consultationTypeFilterOptions[1]]);
  //   }
  // }, []);

  const fetchData = async (params) => {
    // console.log("fetchData", params, params.statusFilter, params.consultationTypeFilter);
    try {
      let url =
        process.env.REACT_APP_API_URL + "consultation/appointment/fetch/";
        url = url + `?source=${source}`;
      if (params.mobileNumber) {
        url += `&phone_number=${encodeURIComponent(params.mobileNumber)}`;
      } else if (params.isFilterSelectedForUser) {
        url += `&${params.filterChangeForUser}=true`;
      }
      

      if (consultationTypeSelectedFilter.length > 0) {
        let consultationNameFilters = [];
        let formattedConsultationNameFilters;

        if (consultationTypeSelectedFilter.length > 0) {
          if (
            consultationTypeSelectedFilter.includes(
              consultationTypeFilterOptions[0]
            )
          ) {
            consultationNameFilters.push("all");
          } else {
            consultationTypeSelectedFilter.forEach((filter) => {
              consultationNameFilters.push(filter.value);
            });
          }

          formattedConsultationNameFilters = `${consultationNameFilters.join(
            ","
          )}`;
        }
        url = url += `&consultation_type=${formattedConsultationNameFilters}`;
      }
      if (appointmentFilterStatus.length > 0) {
        let appointmentNameFilters = [];
        let formattedAppointmnetNameFilters;

        const hasAll = appointmentFilterStatus.some(
          (filter) => filter.value === "all"
        );

        if (!hasAll) {
          appointmentFilterStatus.forEach((filter) => {
            if (filter.value !== "all") {
              appointmentNameFilters.push(filter.value);
            }
          });

          if (appointmentNameFilters.length > 0) {
            formattedAppointmnetNameFilters = `${appointmentNameFilters.join(
              ","
            )}`;
            url += `&status=${formattedAppointmnetNameFilters}`;
          }
        }
      }

      // rating
      if (ratingSelectedFilter.length > 0) {
        let ratingNameFilters = [];
        let formattedRatingNameFilters;

        const hasAll = ratingSelectedFilter.some(
          (filter) => filter.value === "all"
        );

        if (!hasAll) {
          ratingSelectedFilter.forEach((filter) => {
            if (filter.value !== "all") {
              ratingNameFilters.push(filter.value);
            }
          });

          if (ratingNameFilters.length > 0) {
            formattedRatingNameFilters = ratingNameFilters.join(",");
            url += `&rating=${formattedRatingNameFilters}`;
          }
        }
      }

      if (selectedFilterDate) {
        url += `&scheduled_date=${selectedFilterDate}`;
      }
      url += `&page=${isFilterChanged ? 1 : params.currentPage}`;
      setFetchingData(true);

      if (isFilterChanged) {
        setCurrentPage(1);
      }
      if (isFilterChanged) {
        setIsFilterChanged(false);
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      });
      const jsonData = await response.data;
      setData(jsonData.results);

      const totalItems = jsonData.count;
      const itemsPerPage = 100;
      console.log(data.next);
      if (data.next !== null) {
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        setTotalPages(totalPages);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsSearchButtonLoading(false);
      setFetchingData(false);
    }
  };

  useEffect(() => {
    // Check if mobileNumber has an invalid length that isn't empty
    if (mobileNumber.length > 0 && mobileNumber.length < 7) {
      return;
    }
    fetchData({
      mobileNumber,
      isFilterSelectedForUser,
      isFilterSelectedForStatus,
      filterChangeForUser,
      currentPage,
    });
  }, [
    currentPage,
    mobileNumber,
    isFilterSelectedForUser,
    isFilterSelectedForStatus,
    filterChangeForUser,
    appointmentFilterStatus,
    consultationTypeSelectedFilter,
    ratingSelectedFilter,
    selectedFilterDate,
  ]);

  useEffect(() => {
    if (isAppointmentDetailsUpdated) {
      fetchData({
        mobileNumber,
        isFilterSelectedForUser,
        isFilterSelectedForStatus,
        filterChangeForUser,
        currentPage,
      });
      setIsAppointmentDetailsUpdated(false);
    }
  }, [isAppointmentDetailsUpdated]);

  const handleSearchButtonClick = () => {
    setIsSearchButtonLoading(true);
    setCurrentPage(1);

    if (mobileNumber.length < 7) {
      toast.error("Please Enter the Correct Mobile number!");
      setIsSearchButtonLoading(false);
      console.log("Invalid Mobile");

      return;
    }
    fetchData({
      mobileNumber,
      isFilterSelectedForUser,
      isFilterSelectedForStatus,
      filterChangeForUser,
      currentPage: 1,
    });
    setIsSearchButtonClicked(true);
  };
  useEffect(() => {
    setMobileNumber("");
  }, []);

  const handleEditClick = async (rowIndex) => {
    // console.log("rowIndex:", rowIndex);
    const user = data[rowIndex];
    setSelectedUser(user);

    try {
      // Make the API call to get the data for the selected user
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `consultation/appointment/fetch/?app_id=${user.id}&source=${source}`,
        {
          headers: {
            Authorization: `Token ${getToken()}`,
          },
        }
      );

      const userData = response.data.results[0];
      console.log(response.data.results[0]);
      setAppointmentDate(
        userData.scheduled_at
          ? dayjs(userData.scheduled_at, { format: "DD-MM-YYYYTHH:mm" })
          : null
      );
      setAppointmentType(userData.appointment_type);
      setAppointmentStatus(userData.appointment_status);
      setPreviousRemark(userData.remark.update);
      const userRating = ratingHandler(userData.rating);
      const userFeedback = userData.feedback ? userData.feedback : "";
      const CurrentDoctorId = userData.doctor ? userData.doctor : 0;
      setDoctorId(CurrentDoctorId);
      setRating(userRating);
      setFeedback(userFeedback);
      setIsVideoConsultationAllowed(userData?.video_consultation_allowed);

      // Make the second API call
      const secondResponse = await axios.get(
        process.env.REACT_APP_API_URL + `doctors/doctors/all`, // URL for the second API call
        {
          headers: {
            Authorization: `Token ${getToken()}`,
          },
        }
      );

      setDoctorsData(secondResponse.data);
      setMainLoader(false);

      setShowModal(true);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setMainLoader(false);
    }
  };
  const ratingHandler = (ratingValue) => {
    if (ratingValue === "unsatisfied") return "1";
    else if (ratingValue === "satisfied") return "2";
    else if (ratingValue === "very satisfied") return "3";
    else {
      return "null";
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleMobileNumberChange = (e) => {
    const mobileNumberData = e.target.value.replace(
      /[^\d+]/g,
      ""
    );
    setMobileNumber(mobileNumberData);
    setIsSearchButtonClicked(true);
  };

  const handleFilterValueChangeForUser = (value) => {
    setFilterChangeForUser(value);
    setIsFilterSelectedForUser(true);
    setCurrentPage(1);
  };
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const numberOfButtons = isSmallScreen ? 4 : 10;
  //Appointment Modal:

  const closeModal = () => {
    setModalContent(null);
  };

  const handleStatusChangeSaveClick = async () => {
    console.log("Status Save button clicked");

    setIsSaveStatusButtonDisabled(true);
    const updatedUser = {
      ...selectedUser,
    };

    try {
      const updateData = JSON.stringify({
        appointment_id: updatedUser.id,
        appointment_status: appointmentStatus,
      });

      const config = {
        method: "post",
        url: process.env.REACT_APP_API_URL + "consultation/appointment/update/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${getToken()}`,
        },
        data: updateData,
      };

      // Update the data on the server using Axios
      const response = await axios(config);
      console.log(response);
      setIsSaveStatusButtonDisabled(false);
      setIsStatusModalOpen(false);
      fetchData({
        mobileNumber,
        isFilterSelectedForUser,
        isFilterSelectedForStatus,
        filterChangeForUser,
        currentPage,
      });
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
        setIsPatientAttachedToAppointment(false);
        setIsStatusModalOpen(false);
      }
    } finally {
      setIsSaveStatusButtonDisabled(false);
    }
  };

  const handleConsultationTypeFilterChange = (newSelectedStatuses) => {
    if (newSelectedStatuses.includes(consultationTypeFilterOptions[0])) {
      if (
        newSelectedStatuses.length ===
          consultationTypeFilterOptions.length - 1 &&
        consultationTypeSelectedFilter.includes(
          consultationTypeFilterOptions[0]
        )
      ) {
        setConsultationTypeSelectedFilter(
          newSelectedStatuses.filter((status) => status.value !== "all")
        );
        setIsFilterChanged(true);
      } else {
        setConsultationTypeSelectedFilter(consultationTypeFilterOptions);
        setIsFilterChanged(true);
      }
    } else if (
      !consultationTypeSelectedFilter.includes(
        consultationTypeFilterOptions[0]
      ) &&
      newSelectedStatuses.length >= consultationTypeFilterOptions.length - 1
    ) {
      setConsultationTypeSelectedFilter(consultationTypeFilterOptions);
      setIsFilterChanged(true);
    } else if (
      consultationTypeSelectedFilter.includes(
        consultationTypeFilterOptions[0]
      ) &&
      newSelectedStatuses.length >= consultationTypeFilterOptions.length - 1
    ) {
      setConsultationTypeSelectedFilter([]);
      setIsFilterChanged(true);
    } else {
      setConsultationTypeSelectedFilter(newSelectedStatuses);
      setIsFilterChanged(true);
    }
  };

  const handleAppointmentFilterChange = (newSelectedStatuses) => {
    if (newSelectedStatuses.includes(appointmentStatusFilterOptions[0])) {
      if (
        newSelectedStatuses.length ===
          appointmentStatusFilterOptions.length - 1 &&
        appointmentFilterStatus.includes(appointmentStatusFilterOptions[0])
      ) {
        setAppointmentFilterStatus(
          newSelectedStatuses.filter((status) => status.value !== "all")
        );
        setIsFilterChanged(true);
      } else {
        setAppointmentFilterStatus(appointmentStatusFilterOptions);
        setIsFilterChanged(true);
      }
    } else if (
      !appointmentFilterStatus.includes(appointmentStatusFilterOptions[0]) &&
      newSelectedStatuses.length >= appointmentStatusFilterOptions.length - 1
    ) {
      setAppointmentFilterStatus(appointmentStatusFilterOptions);
      setIsFilterChanged(true);
    } else if (
      appointmentFilterStatus.includes(appointmentStatusFilterOptions[0]) &&
      newSelectedStatuses.length >= appointmentStatusFilterOptions.length - 1
    ) {
      setAppointmentFilterStatus([]);
      setIsFilterChanged(true);
    } else {
      setAppointmentFilterStatus(newSelectedStatuses);
      setIsFilterChanged(true);
    }
  };

  const handleRatingFilterChange = (newSelectedStatuses) => {
    if (newSelectedStatuses.includes(ratingFilterOptions[0])) {
      if (
        newSelectedStatuses.length === ratingFilterOptions.length - 1 &&
        ratingSelectedFilter.includes(ratingFilterOptions[0])
      ) {
        setRatingSelectedFilter(
          newSelectedStatuses.filter((status) => status.value !== "all")
        );
        setIsFilterChanged(true);
      } else {
        setRatingSelectedFilter(ratingFilterOptions);
        setIsFilterChanged(true);
      }
    } else if (
      !ratingSelectedFilter.includes(ratingFilterOptions[0]) &&
      newSelectedStatuses.length >= ratingFilterOptions.length - 1
    ) {
      setRatingSelectedFilter(ratingFilterOptions);
      setIsFilterChanged(true);
    } else if (
      ratingSelectedFilter.includes(ratingFilterOptions[0]) &&
      newSelectedStatuses.length >= ratingFilterOptions.length - 1
    ) {
      setRatingSelectedFilter([]);
      setIsFilterChanged(true);
    } else {
      setRatingSelectedFilter(newSelectedStatuses);
      setIsFilterChanged(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(getTotalUnratedChatsInLast1Week, 60000);
    getTotalUnratedChatsInLast1Week();

    const handleFocus = getTotalUnratedChatsInLast1Week;
    window.addEventListener("focus", handleFocus);

    return () => {
      clearInterval(interval);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  const [unRatedChats, setUnRatedChats] = useState([0]);
  const getTotalUnratedChatsInLast1Week = async () => {
    try {
      const config = {
        method: "get",
        url:
          process.env.REACT_APP_API_URL +
          "consultation/fetch-unrated-appointments/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${getToken()}`,
        },
      };

      const response = await axios(config);
      setUnRatedChats(response.data.count);
    } catch (error) {
      console.error(error);
    }
  };
  const handleRatedButtonClick = () => {
    setAppointmentFilterStatus([appointmentStatusFilterOptions[0]]);
    setConsultationTypeSelectedFilter([consultationTypeFilterOptions[0]]);
    setRatingSelectedFilter([ratingFilterOptions[1]]);
    fetchData({
      mobileNumber,
      isFilterSelectedForUser,
      isFilterSelectedForStatus,
      filterChangeForUser,
      currentPage,
    });
  };

  return (
    <div className="px-4 py-1">
      <div className="flex items-center justify-between py-2">
        <h1 className="items-center justify-center text-3xl font-bold text-center capitalize text-navy-700 dark:text-white">
          Appointment
        </h1>

        <button
          className={`rounded-lg px-8 py-2 text-white ${
            unRatedChats > 0
              ? "bg-red-500 hover:bg-red-600"
              : "bg-green-500 hover:bg-green-600"
          }`}
          onClick={handleRatedButtonClick}
        >
          Total Unrated Chats: {unRatedChats}
        </button>

        <Link
          className="px-8 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
          to="/admin/manage-doctors-appointments"
        >
          Manage Doctor's Schedule
        </Link>
      </div>

      {/* filters */}
      <div className="flex flex-col items-center justify-between gap-2 mt-2 mb-4 md:mt-0 md:flex-row">
        {/* Mobile search */}
        <div className="flex items-center justify-center gap-2 px-2 py-1 bg-white border border-gray-300 rounded-md shadow-md w-72 dark:border-navy-700 dark:bg-navy-800">
          <input
            type="text"
            placeholder="User Phone Number"
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            className="flex-grow h-10 px-3 text-sm rounded-md outline-none bg-50 text-navy-700 placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-white"
          />
          <button
            className="px-4 py-2 text-white bg-blue-500 rounded-md shadow hover:bg-blue-600 disabled:opacity-50"
            onClick={handleSearchButtonClick}
            disabled={isSearchButtonLoading}
          >
            {isSearchButtonLoading ? "Loading..." : "Search"}
          </button>
        </div>

        {/* Appointment Status Filter Multi Select */}
        <div className="relative">
          <Listbox
            value={appointmentFilterStatus}
            onChange={handleAppointmentFilterChange}
            multiple
          >
            {({ open }) => (
              <>
                <div className="relative inline-block text-left">
                  <Listbox.Button className="inline-flex items-center justify-center w-full gap-2 px-2 py-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:border-navy-700 dark:bg-navy-800">
                    {appointmentFilterStatus.length > 0
                      ? `${appointmentFilterStatus.length} Status Selected`
                      : "Filter By Status"}
                    {appointmentFilterStatus.length > 0 ? (
                      <button
                        onClick={() => setAppointmentFilterStatus([])}
                        className="flex items-center ml-2 text-gray-500 hover:text-red-500"
                      >
                        <MdOutlineClose size={22} />
                      </button>
                    ) : (
                      <HiOutlineSelector size={22} />
                    )}
                  </Listbox.Button>
                </div>
                <Listbox.Options
                  className={`absolute mt-1 max-h-60 min-w-[180px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm ${
                    open ? "block" : "hidden"
                  }`}
                >
                  {appointmentStatusFilterOptions.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      value={option}
                      className={({ active }) =>
                        `${
                          active ? "bg-blue-500 text-white" : "text-gray-900"
                        } relative cursor-pointer select-none py-2 pl-1 pr-4`
                      }
                    >
                      {({ selected }) => (
                        <div className="flex items-center gap-3">
                          <input
                            type="checkbox"
                            readOnly
                            checked={selected}
                            className="cursor-pointer"
                          />
                          <span
                            className={`${
                              selected ? "font-semibold" : "font-normal"
                            }`}
                          >
                            {option.name}
                          </span>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </Listbox>
        </div>

        {/* ConsultationType Filter Multi Select */}
        <div className="relative">
          <Listbox
            value={consultationTypeSelectedFilter}
            onChange={handleConsultationTypeFilterChange}
            multiple
          >
            {({ open }) => (
              <>
                <div className="relative inline-block text-left">
                  <Listbox.Button className="inline-flex items-center justify-center w-full gap-2 px-2 py-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:border-navy-700 dark:bg-navy-800">
                    {consultationTypeSelectedFilter.length > 0
                      ? `${consultationTypeSelectedFilter.length} Consultation Selected`
                      : "Filter By Consultation"}
                    {consultationTypeSelectedFilter.length > 0 ? (
                      <button
                        onClick={() => setConsultationTypeSelectedFilter([])}
                        className="flex items-center ml-2 text-gray-500 hover:text-red-500"
                      >
                        <MdOutlineClose size={22} />
                      </button>
                    ) : (
                      <HiOutlineSelector size={22} />
                    )}
                  </Listbox.Button>
                </div>
                <Listbox.Options
                  className={`absolute mt-1 max-h-60 min-w-[180px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm ${
                    open ? "block" : "hidden"
                  }`}
                >
                  {consultationTypeFilterOptions.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      value={option}
                      className={({ active }) =>
                        `${
                          active ? "bg-blue-500 text-white" : "text-gray-900"
                        } relative cursor-pointer select-none py-2 pl-1 pr-4`
                      }
                    >
                      {({ selected }) => (
                        <div className="flex items-center gap-3">
                          <input
                            type="checkbox"
                            readOnly
                            checked={selected}
                            className="cursor-pointer"
                          />
                          <span
                            className={`${
                              selected ? "font-semibold" : "font-normal"
                            }`}
                          >
                            {option.name}
                          </span>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </Listbox>
        </div>

        {/* Rating filter */}
        <div className="relative">
          <Listbox
            value={ratingSelectedFilter}
            onChange={handleRatingFilterChange}
            multiple
          >
            {({ open }) => (
              <>
                <div className="relative inline-block text-left">
                  <Listbox.Button className="inline-flex items-center justify-center w-full gap-2 px-2 py-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:border-navy-700 dark:bg-navy-800">
                    {ratingSelectedFilter.length > 0
                      ? `${ratingSelectedFilter.length} Rating Selected`
                      : "Filter By Rating"}
                    {ratingSelectedFilter.length > 0 ? (
                      <button
                        onClick={() => setRatingSelectedFilter([])}
                        className="flex items-center ml-2 text-gray-500 hover:text-red-500"
                      >
                        <MdOutlineClose size={22} />
                      </button>
                    ) : (
                      <HiOutlineSelector size={22} />
                    )}
                  </Listbox.Button>
                </div>
                <Listbox.Options
                  className={`absolute mt-1 max-h-60 min-w-[180px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm ${
                    open ? "block" : "hidden"
                  }`}
                >
                  {ratingFilterOptions.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      value={option}
                      className={({ active }) =>
                        `${
                          active ? "bg-blue-500 text-white" : "text-gray-900"
                        } relative cursor-pointer select-none py-2 pl-1 pr-4`
                      }
                    >
                      {({ selected }) => (
                        <div className="flex items-center gap-3">
                          <input
                            type="checkbox"
                            readOnly
                            checked={selected}
                            className="cursor-pointer"
                          />
                          <span
                            className={`${
                              selected ? "font-semibold" : "font-normal"
                            }`}
                          >
                            {option.name}
                          </span>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </Listbox>
        </div>

        {/* Date filter */}
        <div className="relative inline-flex items-center justify-center gap-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-md hover:bg-gray-50 dark:border-navy-700 dark:bg-navy-800">
          <input
            id="dob"
            name="dob"
            type="date"
            value={selectedFilterDate}
            onChange={(e) => setSelectedFilterDate(e.target.value)}
            max={new Date().toISOString().split("T")[0]}
            placeholder="Filter By Date"
            className="w-full px-5 py-3 bg-transparent rounded-md focus:outline-none"
          />
          {selectedFilterDate && (
            <button
              onClick={() => setSelectedFilterDate("")}
              className="absolute text-gray-500 top-3 right-2 hover:text-red-500"
            >
              <MdOutlineClose size={22} />
            </button>
          )}
        </div>

        {/* Refresh Button */}
        <div className="inline-flex items-center justify-center w-auto">
          <button
            className="px-5 py-3 text-white transition duration-150 ease-in-out bg-blue-500 rounded-md shadow-md hover:bg-blue-600 disabled:opacity-50"
            disabled={fetchingData}
            onClick={() =>
              fetchData({
                mobileNumber,
                isFilterSelectedForUser,
                isFilterSelectedForStatus,
                filterChangeForUser,
                currentPage,
              })
            }
          >
            Refresh
          </button>
        </div>
      </div>

      <AppointnmentMobileView />
      {/* Desktop View Data */}
      <AppointmentDesktopView
        handleEditClick={handleEditClick}
        setModalContent={setModalContent}
        refresh={() => fetchData({
          mobileNumber,
          isFilterSelectedForUser,
          isFilterSelectedForStatus,
          filterChangeForUser,
          currentPage,
        })}
      />
      <AppointmentPagination
        setMobileNumber={setMobileNumber}
        setIsSearchButtonClicked={setIsSearchButtonClicked}
      />

      {selectedUser && showModal && (
        <AppointmentModal
          handleCloseModal={handleCloseModal}
          fetchData={fetchData}
        />
      )}
      {mainLoader || fetchingData ? (
        <div className="fixed inset-0 z-50 flex h-screen items-center justify-center overflow-y-auto bg-gray-900 text-sm opacity-75  sm:h-[150%]">
          <ClipLoader
            color={"white"}
            size={100}
            className="relative h-10 text-white bottom-48"
          />
        </div>
      ) : null}
      <AppointmentStatusModal
        handleStatusChangeSaveClick={handleStatusChangeSaveClick}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default AppointmentMain;
