import { useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ImSad } from "react-icons/im";
import { useLocation } from "react-router-dom";
import { FaPlus, FaMinus, FaPrescriptionBottleAlt } from "react-icons/fa";
import axios from "axios";
import { getToken } from "utils/getToken";
import { ClipLoader } from "react-spinners";
import DeleteUserModal from "./DeleteUserModal";
import {
  MdBarChart,
  MdPeople,
  MdPayment,
  MdDownload,
  MdPermIdentity,
  MdPhone,
  MdEvent,
} from "react-icons/md";
import subscriptionStatusIcon from "../../assets/svg/subscriptionStatusIcon.svg";
import currentGroupsIcon from "../../assets/svg/currentGroupsIcon.svg";
import firstGroupIcon from "../../assets/svg/firstGroupIcon.svg";
import joiningDateIcon from "../../assets/svg/joiningDateIcon.svg";
import questionsIcon from "../../assets/svg/questionsIcon.svg";
import onboardingIcon from "../../assets/svg/onboardingIcon.svg";
import assignedDoctorsIcon from "../../assets/svg/assignedDoctorsIcon.svg";
import personWithoutBG from "../../assets/svg/personWithoutBG.svg";
import { useRecoilState } from "recoil";
import {
  openAddUpdatePatientModalOthersState,
  patientRefetchState,
} from "store/patientModalState";
import PatientModal from "components/PatientModal";
import EditUserModal from "./EditUserModal";
import UserMessages from "./UserMessages";
import EditDoctorModal from "./EditDoctorModal";
import { FaPencil } from "react-icons/fa6";
import CreateDoctorModal from "./CreateDoctorModal";

function UserData(props) {
  const { noSearch, mobileNum } = props;
  const [mobile, setMobile] = useState("");
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [blob, setBlob] = useState(null);
  const [isTableExpandedForPayments, setIsTableExpandedForPayments] =
    useState(false);
  const [isTableExpandedForSubscription, setTableExpandedForSubscription] =
    useState(false);
  const [isTableExpandedForPatients, setTableExpandedForPatients] =
    useState(false);
  const [isTableExpandedForMessages, setTableExpandedForMessages] =
    useState(false);
  const [isTableExpandedForAppointment, setTableExpandedForAppointment] =
    useState(false);
  const [isTableExpandedForPrescription, setTableExpandedForPrescription] =
    useState(false);
  const [isCurrentGroupsExpanded, setIsCurrentGroupsExpanded] = useState(false);
  const [isAssignedDoctorsExpanded, setIsAssignedDoctorsExpanded] =
    useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState({
    open: false,
    userMobile: null,
    patientName: null,
  });
  const [openEditUserModal, setOpenEditUserModal] = useState({
    open: false,
    userId: null,
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    userMobile: "",
    growth: "",
    onboarding: "",
    btpSubscriber: "",
    onboardingStatus: "",
    updatedBy: "",
  });
  const [openEditDoctorModal, setOpenEditDoctormodal] = useState({
    open: false,
    mobile: "",
  });
  const [openCreateDoctorModal, setOpenCreateDoctorModal] = useState({
    open: false,
    mobile: "",
    userId: null,
    userName: "",
  });
  const [patients, setPatients] = useState([]);
  const [openAddUpdatePatientModal, setOpenAddUpdatePatientModal] =
    useRecoilState(openAddUpdatePatientModalOthersState);
  const [shouldRefetch, setShouldRefetch] = useRecoilState(patientRefetchState);
  // Parse data from url
  const location = useLocation();
  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const getMobileFromQuery = queryParams.get("mobile_number");

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const toggleTable = (val) => {
    if (val === "sub") {
      setTableExpandedForSubscription(!isTableExpandedForSubscription);
    } else if (val === "pat") {
      setTableExpandedForPatients(!isTableExpandedForPatients);
    } else if (val === "mes") {
      setTableExpandedForMessages(!isTableExpandedForMessages);
    } else if (val === "app") {
      setTableExpandedForAppointment(!isTableExpandedForAppointment);
    } else if (val === "cur") {
      setIsCurrentGroupsExpanded(!isCurrentGroupsExpanded);
    } else if (val === "docs") {
      setIsAssignedDoctorsExpanded(!isAssignedDoctorsExpanded);
    } else if (val === "pre") {
      setTableExpandedForPrescription(!isTableExpandedForPrescription);
    } else if (val === "pay") {
      setIsTableExpandedForPayments(!isTableExpandedForPayments);
    }
  };
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 768px) and (max-width: 900px)"
  );

  const dateTimeFormateHandler = (date) => {
    const time = new Date(date);
    let hours = time.getHours();
    let minutes = time.getMinutes();

    // Determine whether it's AM or PM
    var ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (12 AM)

    // Pad minutes with leading zero if necessary
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const timeInAMPM = hours + ":" + minutes + " " + ampm;
    const newDate = dateFormateHandler(date);
    return `${newDate} at ${timeInAMPM}`;
  };

  const dateFormateHandler = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth();
    const year = newDate.getFullYear();
    return `${day}-${months[month]}-${year}`;
  };

  const fetchUserData = (mobile_number, userId) => {
    setMainLoader(true);
    fetch(`${process.env.REACT_APP_API_URL}users/get-users-data/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${getToken()}`,
        "Content-Type": "application/json",
      },
      body: mobile_number
        ? JSON.stringify({
            mobile: `${mobile_number}`,
          })
        : userId
        ? JSON.stringify({
            user_id: userId,
          })
        : null,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.messages === "Number not found") {
          toast.error("Number not found!");
          setClicked(true);
          setDataFound(false);
          setMainLoader(false);
        } else {
          console.log(data);
          setUserData(data);
          setDataFound(true);
          setPatients(data.patients || []);
          setClicked(true);
          setMainLoader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong!");
        setMainLoader(false);
      });
  };

  useEffect(() => {
    if (getMobileFromQuery) {
      setMobile(getMobileFromQuery);
      fetchUserData(getMobileFromQuery);
    }
    if (mobileNum) {
      fetchUserData(mobileNum);
    }
  }, []);

  const handleSubscriptionExpiry = (statusName, dateValue, status) => {
    const expiryDate = new Date(dateValue);
    const todayDate = new Date();
    // console.log("expiryDate", expiryDate);
    // console.log("todayDate", todayDate);

    if (status === "expired") {
      return (
        <p>
          {statusName}
          {"  "}
          <b className="text-red-500">
            (Expired on {dateFormateHandler(dateValue)})
          </b>
        </p>
      );
    } else {
      return (
        <p>
          {statusName} {"  "}
          <b className="text-green-500">
            (Valid till {dateFormateHandler(dateValue)})
          </b>
        </p>
      );
    }
  };
  const getPDF = async (consultationId) => {
    if (!consultationId) return null;
    setMainLoader(true);
    try {
      // Get the consultation details from the stored consultations list
      const consultation = userData.consultations.find(
        (cons) => cons.id === consultationId
      );
      console.log("consultation", consultation);
      if (!consultation) {
        console.error("Consultation details not found.");
        toast.error("Consultation details not found.");
        setMainLoader(false);
        return null;
      }

      // Construct the file name using patient_name, doctor_name, and date
      let fileName = `${
        consultation.patient_name
      }-${consultation.doctor_name.slice(0, 15)}-${consultation.date}`;
      fileName = fileName.replace(/[ -]/g, "_").toLowerCase();
      console.log("fileName", fileName);

      const response = await axios({
        url: process.env.REACT_APP_API_URL + "patients/pdf/",
        method: "POST",
        responseType: "blob",
        data: {
          consultation_id: consultationId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });

      if (response.status === 200) {
        const blobData = response.data;
        setBlob(blobData);
        downloadBlob(blobData, fileName);
      }
      setMainLoader(false);
    } catch (error) {
      console.error(error);
      if (error.response) {
        console.error(error.response.data);
      }
      setMainLoader(false);
      return null;
    }
  };

  const downloadBlob = (pdfBlob, fileName) => {
    if (!pdfBlob) return;
    const url = URL.createObjectURL(pdfBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName ? `${fileName}.pdf` : "precsription.pdf"}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // For patient add update
  useEffect(() => {
    if (shouldRefetch) {
      fetchUserData(mobile);
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  const handleEditPatient = (patient) => {
    console.log("patient", patient.id);
    setOpenAddUpdatePatientModal({
      userMobile: mobile,
      open: true,
      type: "update",
      patientDetails: patient,
      appointmentIndex: null,
    });
  };

  const assignedDoctors = () => {
    if (userData && userData.assigned_doctor) {
      return Object.keys(userData?.assigned_doctor).map((key) => {
        return {
          role: [key].toString(),
          name: userData?.assigned_doctor[key]?.name,
        };
      });
    } else return null;
  };
  const assignedDocs = assignedDoctors();
  return (
    <>
      <DeleteUserModal
        openDeleteUserModal={openDeleteUserModal}
        setOpenDeleteUserModal={setOpenDeleteUserModal}
      />
      <EditUserModal
        refresh={() => {
          if (mobile.trim() !== "") {
            fetchUserData(mobile, null);
          } else if (userId) {
            fetchUserData(null, userId);
          } else {
            return null;
          }
        }}
        openEditUserModal={openEditUserModal}
        setOpenEditUserModal={setOpenEditUserModal}
      />
      <EditDoctorModal
        refresh={() => {
          if (mobile.trim() !== "") {
            fetchUserData(mobile, null);
          } else if (userId) {
            fetchUserData(null, userId);
          } else {
            return null;
          }
        }}
        openEditDoctorModal={openEditDoctorModal}
        setOpenEditDoctorModal={setOpenEditDoctormodal}
      />
      <CreateDoctorModal
        openCreateDoctorModal={openCreateDoctorModal}
        setOpenCreateDoctorModal={setOpenCreateDoctorModal}
        refresh={() => {
          if (mobile.trim() !== "") {
            fetchUserData(mobile, null);
          } else if (userId) {
            fetchUserData(null, userId);
          } else {
            return null;
          }
        }}
      />
      <div
        className={`flex h-full flex-col items-center overflow-hidden px-4  ${
          clicked ? "md:px-0" : "md:px-5"
        }`}
      >
        <div className="mt-2 flex w-full flex-col items-center ">
          {/* Form start */}
          {!noSearch && (
            <form className={`flex w-[100%] flex-col space-y-4`}>
              <div className="w-full">
                <h2
                  className={` items-center justify-center text-center font-bold capitalize text-navy-700 dark:text-white md:my-0 ${
                    clicked ? "my-2 text-3xl " : "py-4 text-4xl"
                  }`}
                >
                  User Data
                </h2>

                <div
                  className={`mt-2 flex  gap-2  ${
                    clicked
                      ? " flex-row justify-center gap-1 "
                      : "w-full flex-col items-center gap-3"
                  } `}
                >
                  <div className="flex w-full items-center gap-4">
                    <input
                      className={`text-black  rounded border-2 border-solid border-[#BFDBFE] bg-white py-2 px-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-600 ${
                        clicked ? "w-[70%]" : "w-full"
                      }`}
                      type="tel"
                      id="telNo"
                      name="telNo"
                      placeholder="Enter Mobile Number"
                      value={mobile}
                      onChange={(e) => {
                        if (e.target.value.trim() === "") {
                          setClicked(false);
                          setDataFound(false);
                        }
                        if (userId) {
                          setUserId("");
                        }
                        const mobileNumber = e.target.value.replace(
                          /[^\d+]/g,
                          ""
                        );
                        setMobile(mobileNumber);
                      }}
                    />

                    <div className="flex items-center text-lg text-gray-600">
                      or
                    </div>

                    <input
                      value={userId}
                      placeholder="Enter User Id"
                      onChange={(e) => {
                        if (mobile) {
                          setMobile("");
                        }
                        if (e.target.value === "") {
                          setClicked(false);
                          setDataFound(false);
                          setUserId("");
                        } else {
                          setUserId(e.target.value);
                        }
                      }}
                      type="number"
                      min="0"
                      step="any"
                      className={`text-black rounded  border-2 border-solid border-[#BFDBFE] bg-white py-2 px-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-600 [&::-webkit-inner-spin-button]:appearance-none ${
                        clicked ? "w-[70%]" : "w-full"
                      }`}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </div>

                  <button
                    className={`  rounded bg-blue-500 px-4 py-2 text-white md:col-span-2 ${
                      clicked ? "w-[100px]" : "w-full"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();

                      if (mobile.trim() !== "") {
                        fetchUserData(mobile, null);
                      } else if (userId) {
                        fetchUserData(null, userId);
                      } else {
                        return null;
                      }
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          )}
          {clicked && (
            <div className="mt-4 mb-4 flex w-full items-center justify-center overflow-y-auto ">
              <div
                className={`mx-4 w-full rounded  pb-4 md:mx-auto ${
                  isSmallScreen ? "mx-0" : "bg-white"
                }`}
              >
                {dataFound ? (
                  <div className="message-container h-[80vh] w-[100%] overflow-y-scroll p-4 text-gray-900">
                    <div
                      className={`flex flex-col gap-2  ${
                        isSmallScreen ? "gap-5" : ""
                      } `}
                    >
                      <div className="mb-2 flex flex-col justify-between gap-5">
                        <div className="w-full overflow-hidden rounded-lg bg-white shadow-[2px_2px_10px_1px_rgba(0,0,0,0.15)]">
                          <div className="relative flex flex-col items-center justify-between border-b p-4 md:flex-row">
                            <div className="ml-3 flex items-center">
                              <img
                                src={personWithoutBG}
                                alt="User Image"
                                className="mr-4 h-20 w-20 text-orange-600"
                              />
                              <div className="ml-1 flex flex-col items-start justify-start gap-y-1 text-[#333]">
                                <p className="text-xl font-semibold">
                                  {userData.user_name || "NA"}
                                </p>
                                <p className="flex items-center gap-x-3 text-sm">
                                  <span className="flex items-center justify-center gap-x-1">
                                    <MdPermIdentity />{" "}
                                    {userData.user_id || "NA"}
                                  </span>
                                  <span className="flex items-center justify-center gap-x-1">
                                    <MdPhone />
                                    {userData.phone_number || "NA"}
                                  </span>
                                </p>
                              </div>
                            </div>
                            {!userData.is_doctor_profile_created &&
                              userData?.user_role?.includes("Doctor") && (
                                <button
                                  onClick={() =>
                                    setOpenCreateDoctorModal({
                                      open: true,
                                      mobile: userData?.phone_number || "",
                                      userId: userData?.user_id,
                                      userName: userData?.user_name
                                    })
                                  }
                                  className="absolute top-1 right-1 flex items-center justify-center text-sm text-blue-600 hover:underline md:top-4 md:right-4"
                                >
                                  <FaPlus className="mr-2" /> Create Doctor
                                </button>
                              )}
                            {userData?.user_role?.includes("Doctor") &&
                            userData?.is_doctor_profile_created ? (
                              <button
                                onClick={() =>
                                  setOpenEditDoctormodal({
                                    open: true,
                                    mobile: userData?.phone_number || "",
                                    userId: userData?.user_id,
                                  })
                                }
                                className="absolute top-1 right-1 flex items-center justify-center text-sm text-blue-600 hover:underline md:top-4 md:right-4"
                              >
                                <FaPencil className="mr-2" /> Edit Doctor
                              </button>
                            ) : userData?.user_role?.includes("User") &&
                              !userData?.user_role?.includes("Doctor") ? (
                              <button
                                onClick={() =>
                                  setOpenEditUserModal({
                                    open: true,
                                    userId: userData.user_id,
                                    firstName: userData.first_name || "",
                                    lastName: userData.last_name || "",
                                    role:
                                      userData.user_role.filter(
                                        (role) => role !== null
                                      ) || [],
                                    email: userData.email || "",
                                    userMobile: userData.phone_number || "",
                                    growth: userData.growth_call_enabled
                                      ? "Yes"
                                      : "No",
                                    onboardingStatus:
                                      userData.subscriber_onboarding
                                        .call_completed || false,
                                    btpSubscriber:
                                      userData.btp_subscriber || false,
                                    updatedBy: userData.updated_by || "",
                                  })
                                }
                                className="absolute top-1 right-1 flex items-center justify-center text-sm text-blue-600 hover:text-[#d4743b] hover:underline md:top-4 md:right-4"
                              >
                                <FaPencil className="mr-2" /> Edit User
                              </button>
                            ) : null}
                          </div>

                          <div className="grid auto-cols-auto grid-flow-col overflow-auto p-4 md:gap-4">
                            <div className="flex w-[200px] items-center rounded p-3 md:w-fit">
                              <img
                                src={subscriptionStatusIcon}
                                alt="Subscription Status"
                                className="mr-3 h-auto w-auto"
                              />
                              <div>
                                <p className="text-sm">Subscription Status</p>
                                <p className="font-bold text-[#333]">
                                  {userData.subscription &&
                                  userData.subscription.length > 0 &&
                                  userData.subscription[0].date_expired &&
                                  userData.subscription[0].name
                                    ? handleSubscriptionExpiry(
                                        userData.subscription[0].name,
                                        userData.subscription[0].date_expired,
                                        userData.subscription[0].status
                                      )
                                    : "NA"}
                                </p>
                              </div>
                            </div>

                            <div className="flex w-[180px] items-center rounded p-3 md:w-fit">
                              <img
                                src={joiningDateIcon}
                                alt="Joining Date"
                                className="mr-3 h-auto w-auto"
                              />
                              <div>
                                <p className="text-sm">Joining Date</p>
                                <p className="font-bold text-[#333]">
                                  {userData.user_joining_date
                                    ? dateFormateHandler(
                                        userData.user_joining_date
                                      )
                                    : "NA"}
                                </p>
                              </div>
                            </div>

                            <div className="flex w-[180px] items-center rounded p-3 md:w-fit">
                              <img
                                src={questionsIcon}
                                alt="Total Questions"
                                className="mr-3 h-auto w-auto"
                              />
                              <div>
                                <p className="text-sm">Total Questions</p>
                                <p className="font-bold text-[#333]">
                                  {userData.total_questions
                                    ? userData.total_questions
                                    : "NA"}
                                </p>
                              </div>
                            </div>

                            {userData.btp_subscriber && (
                              <div className="flex w-[180px] items-center rounded p-3 md:w-fit">
                                <img
                                  src={onboardingIcon}
                                  alt="Onboarding Status"
                                  className="mr-3 h-auto w-auto"
                                />
                                <div>
                                  <p className="text-sm">Onboarding Status</p>
                                  <p className="font-bold">
                                    {userData.subscriber_onboarding
                                      .call_completed === true ? (
                                      <span className="text-green-500">
                                        Onboarded
                                      </span>
                                    ) : userData.subscriber_onboarding
                                        .call_completed === false ? (
                                      <span className="text-red-500">
                                        Not Onboarded
                                      </span>
                                    ) : (
                                      <span className="text-gray-500">
                                        Status Unknown
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            )}

                            <div className="flex w-[180px] items-center rounded p-3 md:w-fit">
                              <img
                                src={firstGroupIcon}
                                alt="First Group"
                                className="mr-3 h-auto w-auto"
                              />
                              <div>
                                <p className="text-sm">First Group</p>
                                <p className="font-bold text-[#333]">
                                  {userData.first_group || "NA"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-4">
                          <div className="w-1/2 overflow-auto rounded-md shadow-[2px_2px_10px_1px_rgba(0,0,0,0.15)]">
                            <h2 className="justify-left text-black mb-2 flex items-center gap-x-4 p-4 text-lg font-bold">
                              <img
                                src={assignedDoctorsIcon}
                                alt="Assigned Doctors"
                                className="h-auto w-auto"
                              />
                              Assigned Doctors
                            </h2>
                            <table className="w-full border-collapse text-left">
                              <thead>
                                <tr>
                                  <th className="border-y border-r border-gray-300 p-2">
                                    S. No.
                                  </th>
                                  <th className="border-y border-r border-gray-300 p-2">
                                    Consultation Type
                                  </th>
                                  <th className="border-y border-gray-300 p-2">
                                    Doctor Assigned
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {(() => {
                                  const doctorsToShow =
                                    isAssignedDoctorsExpanded
                                      ? assignedDocs
                                      : assignedDocs?.slice(0, 2);
                                  return doctorsToShow &&
                                    doctorsToShow.length > 0 ? (
                                    doctorsToShow.map((doc, index) => (
                                      <tr key={index}>
                                        <td className="border-y border-r border-gray-300 p-2">
                                          {index + 1}
                                        </td>
                                        <td className="border-y border-r border-gray-300 p-2">
                                          {doc.role}
                                        </td>
                                        <td className="border-y border-gray-300 p-2">
                                          {doc.name}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="3"
                                        className="border-t border-b border-gray-300 p-2 text-center italic text-red-400"
                                      >
                                        No doctors assigned
                                      </td>
                                    </tr>
                                  );
                                })()}
                              </tbody>
                            </table>
                            {assignedDocs?.length > 2 && (
                              <div className="p-1">
                                <button
                                  onClick={() => toggleTable("docs")}
                                  className="text-blue-600 underline focus:outline-none"
                                >
                                  {isAssignedDoctorsExpanded ? (
                                    <span className="flex items-center gap-1 px-3">
                                      <FaMinus className="text-xs" /> less
                                    </span>
                                  ) : (
                                    <span className="flex items-center gap-1 px-3">
                                      <FaPlus className="text-xs" /> more
                                    </span>
                                  )}
                                </button>
                              </div>
                            )}
                          </div>

                          <div className="w-1/2 overflow-auto rounded-md shadow-[2px_2px_10px_1px_rgba(0,0,0,0.15)]">
                            <h2 className="justify-left text-black mb-2 flex items-center gap-x-4 p-4 text-lg font-bold">
                              <img
                                src={currentGroupsIcon}
                                alt="Current Groups"
                                className="h-auto w-auto"
                              />
                              Current Groups
                            </h2>
                            <table className="w-full border-collapse text-left">
                              <thead>
                                <tr>
                                  <th className="border-y border-r border-gray-300 p-2">
                                    S. No.
                                  </th>
                                  <th className="border-y border-gray-300 p-2">
                                    Group Names
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {(() => {
                                  const groupsToShow = isCurrentGroupsExpanded
                                    ? userData.current_groups
                                    : userData.current_groups?.slice(0, 2);
                                  return groupsToShow &&
                                    groupsToShow.length > 0 ? (
                                    groupsToShow.map((group, index) => (
                                      <tr key={index}>
                                        <td className="border-y border-r border-gray-300 p-2">
                                          {index + 1}
                                        </td>
                                        <td className="border-y border-gray-300 p-2">
                                          {group}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="2"
                                        className="border-t border-b border-gray-300 p-2 text-center italic text-red-400"
                                      >
                                        No groups available
                                      </td>
                                    </tr>
                                  );
                                })()}
                              </tbody>
                            </table>
                            {userData.current_groups?.length > 2 && (
                              <div className="p-1">
                                <button
                                  onClick={() => toggleTable("cur")}
                                  className="text-blue-600 underline focus:outline-none"
                                >
                                  {isCurrentGroupsExpanded ? (
                                    <span className="flex items-center gap-1 px-3">
                                      <FaMinus className="text-xs" /> less
                                    </span>
                                  ) : (
                                    <span className="flex items-center gap-1 px-3">
                                      <FaPlus className="text-xs" /> more
                                    </span>
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="  w-full  rounded-[10px] bg-gray-100 p-2">
                        <div>
                          <div className="flex items-center gap-1 ">
                            <MdPayment className="text-lg" />
                            <p>
                              <b>Paid Payments</b>
                            </p>
                          </div>
                        </div>

                        <table className="inline-table w-[100%] table-auto bg-white text-sm shadow">
                          <thead>
                            <tr className="bg-blue-200">
                              <th className=" w-[200px] border px-4 py-2">
                                Payment Amount
                              </th>
                              <th className="border px-4 py-2">Payment Date</th>
                              <th className="border px-4 py-2">
                                Payment Reason
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(userData.paid_payments) &&
                            userData.paid_payments.length > 0 ? (
                              userData.paid_payments
                                .slice(
                                  0,
                                  isTableExpandedForPayments ? undefined : 1
                                )
                                .map((row, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      className="hover:bg-gray-100"
                                    >
                                      <td className="border px-4 py-2 text-center">
                                        {row.payment_amount ? (
                                          <>
                                            {"₹"}
                                            {row.payment_amount}
                                          </>
                                        ) : (
                                          "NA"
                                        )}
                                      </td>
                                      <td className="border px-4 py-2 text-center ">
                                        {row.payment_amount
                                          ? dateTimeFormateHandler(
                                              row.payment_date
                                            )
                                          : null}
                                      </td>
                                      <td className="border px-4 py-2 text-center ">
                                        {row?.payment_reason
                                          ? row.payment_reason
                                          : null}
                                      </td>
                                    </tr>
                                  );
                                })
                            ) : (
                              <tr>
                                <td
                                  className="border px-4 py-2 text-center"
                                  colSpan="3"
                                >
                                  No data available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {userData.paid_payments &&
                          userData.paid_payments.length > 1 && (
                            <div className="flex w-full justify-center">
                              <button
                                onClick={() => toggleTable("pay")}
                                className="flex w-[40px] items-center justify-center rounded-b-md bg-white p-1 focus:outline-none"
                              >
                                {isTableExpandedForPayments ? (
                                  <>
                                    <FaMinus className="text-sm text-blue-500" />
                                  </>
                                ) : (
                                  <>
                                    <FaPlus className="text-sm text-blue-500" />
                                  </>
                                )}
                              </button>
                            </div>
                          )}
                      </div>
                      <div
                        className={` flex w-full gap-2 ${
                          isSmallScreen ? "flex-col gap-5" : ""
                        }  ${isMediumScreen ? "flex-col gap-2" : ""}`}
                      >
                        <div className="  w-[100%] rounded-[10px] bg-gray-100 p-2 ">
                          <div className="flex items-center gap-1">
                            <MdBarChart className="text-lg" />
                            <p>
                              <b>Subscription</b>
                            </p>
                          </div>

                          {isSmallScreen ? (
                            <div className="flex flex-col gap-3 ">
                              {" "}
                              {userData.subscription &&
                                userData.subscription.length > 0 &&
                                userData.subscription
                                  .slice(
                                    0,
                                    isTableExpandedForSubscription
                                      ? undefined
                                      : 1
                                  )
                                  .map((row, index) => {
                                    return (
                                      <div
                                        className="rounded-[20px] bg-white px-4 py-2"
                                        key={index}
                                      >
                                        <p>
                                          <b>Patients</b>:{" "}
                                          {row.status ? row.status : null}
                                        </p>
                                        <p>
                                          <b>Name</b>:{" "}
                                          {row.name ? row.name : null}
                                        </p>
                                        <p>
                                          <b>Start Date</b>:{" "}
                                          {row.start_date
                                            ? dateFormateHandler(row.start_date)
                                            : null}
                                        </p>
                                        <p>
                                          <b>Date Expired</b>:{" "}
                                          {row.date_expired
                                            ? dateFormateHandler(
                                                row.date_expired
                                              )
                                            : null}
                                        </p>
                                        <p>
                                          <b>Max Calls</b>:{" "}
                                          {row.max_calls ? row.max_calls : null}
                                        </p>
                                        <p>
                                          <b>Calls Left</b>:{" "}
                                          {row.calls_left
                                            ? row.calls_left
                                            : null}
                                        </p>
                                        <p>
                                          <b>Original Price</b>:{" "}
                                          {row.original_price
                                            ? row.original_price
                                            : null}
                                        </p>
                                      </div>
                                    );
                                  })}
                            </div>
                          ) : (
                            <table className="inline-table w-full table-auto bg-white text-sm shadow">
                              <thead>
                                <tr className="bg-blue-200">
                                  <th className="border px-4 py-2">Status</th>
                                  <th className="border px-4 py-2">Name</th>
                                  <th className="border px-4 py-2">
                                    Start Date
                                  </th>
                                  <th className="border px-4 py-2">End Date</th>
                                  <th className="border px-4 py-2">
                                    Max Calls
                                  </th>
                                  <th className="border px-4 py-2">
                                    Calls Left
                                  </th>
                                  <th className="border px-4 py-2">
                                    Original Price
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {userData.subscription &&
                                userData.subscription.length > 0 ? (
                                  userData.subscription
                                    .slice(
                                      0,
                                      isTableExpandedForSubscription
                                        ? undefined
                                        : 1
                                    )
                                    .map((row, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          className="text-center hover:bg-gray-100"
                                        >
                                          <td className="border px-4 py-2">
                                            {row.status ? row.status : null}
                                          </td>
                                          <td className="border px-4 py-2">
                                            {row.name ? row.name : null}
                                          </td>
                                          <td className="border px-4 py-2">
                                            {row.start_date
                                              ? dateFormateHandler(
                                                  row.start_date
                                                )
                                              : null}
                                          </td>
                                          <td className="border px-4 py-2">
                                            {row.date_expired
                                              ? dateFormateHandler(
                                                  row.date_expired
                                                )
                                              : null}
                                          </td>
                                          <td className="border px-4 py-2">
                                            {row.max_calls
                                              ? row.max_calls
                                              : null}
                                          </td>
                                          <td className="border px-4 py-2">
                                            {row.calls_left
                                              ? row.calls_left
                                              : null}
                                          </td>
                                          <td className="border px-4 py-2">
                                            {row.original_price
                                              ? row.original_price
                                              : null}
                                            {row.original_price ? "rs" : null}
                                          </td>
                                        </tr>
                                      );
                                    })
                                ) : (
                                  <tr>
                                    <td
                                      className="border px-4 py-2 text-center"
                                      colSpan="6"
                                    >
                                      No data available
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          )}

                          {userData.subscription &&
                            userData.subscription.length > 1 && (
                              <div className="flex w-full justify-center">
                                <button
                                  onClick={() => toggleTable("sub")}
                                  className="flex w-[40px] items-center justify-center rounded-b-md bg-white p-1 focus:outline-none"
                                >
                                  {isTableExpandedForSubscription ? (
                                    <>
                                      <FaMinus className="text-sm text-blue-500" />
                                    </>
                                  ) : (
                                    <>
                                      <FaPlus className="text-sm text-blue-500" />
                                    </>
                                  )}
                                </button>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="  w-[100%] rounded-[10px] bg-gray-100 p-2 ">
                        <div className="flex items-center gap-1">
                          <MdPeople className="text-lg" />
                          <p>
                            <b>Patients</b>:{" "}
                            {userData.patients && userData.patients.length > 0
                              ? userData.patients.length
                              : "NA"}
                          </p>
                        </div>

                        {isSmallScreen ? (
                          <div className="flex flex-col gap-3 ">
                            {userData.patients &&
                              userData.patients.length > 0 &&
                              userData.patients
                                .slice(
                                  0,
                                  isTableExpandedForPatients ? undefined : 1
                                )
                                .map((row, index) => {
                                  return (
                                    <div
                                      className="rounded-[20px] bg-white px-4 py-2"
                                      key={index}
                                    >
                                      <p>
                                        <b>Name</b>:{" "}
                                        {row.name ? (
                                          row.name
                                        ) : (
                                          <span className="text-red-500">
                                            NO-NAME
                                          </span>
                                        )}
                                      </p>
                                      <p>
                                        <b>Gender</b>:{" "}
                                        {row.gender ? row.gender : "NA"}
                                      </p>
                                      <p>
                                        <b>DOB</b>:{" "}
                                        {row.date_of_birth
                                          ? dateFormateHandler(
                                              row.date_of_birth
                                            )
                                          : null}
                                      </p>

                                      <p>
                                        <b>Weight</b>:{" "}
                                        {row.weight ? row.weight : "NA"}
                                      </p>
                                    </div>
                                  );
                                })}
                          </div>
                        ) : (
                          <table className="inline-table w-full table-auto bg-white text-sm shadow">
                            <thead>
                              <tr className="bg-blue-200">
                                <th className="border px-4 py-2">Name</th>
                                <th className="border px-4 py-2">Gender</th>
                                <th className="border px-4 py-2">DOB</th>
                                <th className="border px-4 py-2">Weight</th>
                              </tr>
                            </thead>

                            <tbody>
                              {userData.patients &&
                              userData.patients.length > 0 ? (
                                userData.patients
                                  .slice(
                                    0,
                                    isTableExpandedForPatients ? undefined : 1
                                  )
                                  .map((patient, index) => (
                                    <tr
                                      key={index}
                                      className="text-center hover:bg-gray-100"
                                    >
                                      <td className="border px-4 py-2">
                                        {patient.name ? (
                                          patient.name
                                        ) : (
                                          <span className="text-red-500">
                                            NO-NAME
                                          </span>
                                        )}
                                      </td>
                                      <td className="border px-4 py-2">
                                        {patient.gender || "NA"}
                                      </td>
                                      <td className="border px-4 py-2">
                                        {patient.date_of_birth
                                          ? dateFormateHandler(
                                              patient.date_of_birth
                                            )
                                          : "NA"}
                                      </td>
                                      <td className="border px-4 py-2">
                                        {patient.weight || "NA"}
                                      </td>
                                      <td className="border px-4 py-2">
                                        <button
                                          onClick={() =>
                                            handleEditPatient(patient)
                                          }
                                          className="text-blue-500 hover:text-blue-600"
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="5"
                                    className="border px-4 py-2 text-center"
                                  >
                                    No data available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        )}
                        {userData.patients && userData.patients.length > 1 && (
                          <>
                            <div className="flex w-full justify-center">
                              <button
                                onClick={() => toggleTable("pat")}
                                className="flex w-[40px] items-center justify-center rounded-b-md bg-white p-1 focus:outline-none"
                              >
                                {isTableExpandedForPatients ? (
                                  <>
                                    <FaMinus className="text-sm text-blue-500" />
                                  </>
                                ) : (
                                  <>
                                    <FaPlus className="text-sm text-blue-500" />
                                  </>
                                )}
                              </button>
                            </div>
                          </>
                        )}
                        <div className="flex justify-center">
                          <button
                            onClick={() => {
                              setOpenAddUpdatePatientModal({
                                open: true,
                                userMobile: mobile,
                                type: "add",
                                patientDetails: null,
                              });
                            }}
                            className="mt-4 flex justify-center rounded-md bg-blue-500 px-4 py-2 text-white"
                          >
                            Add Patient
                          </button>
                        </div>
                      </div>
                      <UserMessages
                        mobileNo={mobile || mobileNum}
                        userId={userId}
                      />
                      {/* Appointments */}
                      <div className="  w-full  rounded-[10px] bg-gray-100 p-2">
                        <div>
                          <div className="flex items-center gap-1 ">
                            <MdEvent className="text-lg" />
                            <p>
                              <b>Appointments</b>
                            </p>
                          </div>
                        </div>

                        {isSmallScreen ? (
                          <div className="flex flex-col gap-3 ">
                            {" "}
                            {userData.appointment &&
                              userData.appointment.length > 0 &&
                              userData.appointment
                                .slice(
                                  0,
                                  isTableExpandedForAppointment ? undefined : 1
                                )
                                .map((row, index) => {
                                  return (
                                    <div
                                      className="rounded-[20px] bg-white px-4 py-2"
                                      key={index}
                                    >
                                      <p>
                                        <b>Type</b>:{" "}
                                        {row.type ? row.type : null}
                                      </p>

                                      <p>
                                        <b>Appointment Date</b>:{" "}
                                        {row.appointment_time
                                          ? dateTimeFormateHandler(
                                              row.appointment_time
                                            )
                                          : null}
                                      </p>

                                      <p>
                                        <b>Status</b>:{" "}
                                        {row.status ? row.status : null}
                                      </p>
                                    </div>
                                  );
                                })}
                          </div>
                        ) : (
                          <table className="inline-table w-full table-auto bg-white text-sm shadow">
                            <thead>
                              <tr className="bg-blue-200">
                                <th className="border px-4 py-2">Type</th>

                                <th className="border px-4 py-2">
                                  Appointment Date
                                </th>

                                <th className="border px-4 py-2">Status</th>
                                <th className="border px-4 py-2">Payment</th>
                                <th className="border px-4 py-2">
                                  Subscription
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {userData.appointment &&
                              userData.appointment.length > 0 ? (
                                userData.appointment
                                  .slice(
                                    0,
                                    isTableExpandedForAppointment
                                      ? undefined
                                      : 1
                                  )
                                  .map((row, index) => {
                                    return (
                                      <tr
                                        key={index}
                                        className="hover:bg-gray-100"
                                      >
                                        <td className="border px-4 py-2">
                                          {row.type ? row.type : null}
                                        </td>
                                        <td className="border px-4 py-2">
                                          {row.appointment_time
                                            ? dateTimeFormateHandler(
                                                row.appointment_time
                                              )
                                            : null}
                                        </td>

                                        <td className="border px-4 py-2">
                                          {row.status ? row.status : null}
                                        </td>
                                        <td className="border px-4 py-2">
                                          {row.payment ? row.payment : null}
                                        </td>
                                        <td className="border px-4 py-2">
                                          {row.subscription
                                            ? row.subscription
                                            : null}
                                        </td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  <td
                                    className="border px-4 py-2 text-center"
                                    colSpan="6"
                                  >
                                    No data available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        )}
                        {userData.appointment &&
                          userData.appointment.length > 1 && (
                            <div className="flex w-full justify-center">
                              <button
                                onClick={() => toggleTable("app")}
                                className="flex w-[40px] items-center justify-center rounded-b-md bg-white p-1 focus:outline-none"
                              >
                                {isTableExpandedForAppointment ? (
                                  <>
                                    <FaMinus className="text-sm text-blue-500" />
                                  </>
                                ) : (
                                  <>
                                    <FaPlus className="text-sm text-blue-500" />
                                  </>
                                )}
                              </button>
                            </div>
                          )}
                      </div>{" "}
                      <div className="  w-full  rounded-[10px] bg-gray-100 p-2">
                        <div>
                          <div className="flex items-center gap-1 ">
                            <FaPrescriptionBottleAlt className="text-lg" />
                            <p>
                              <b>Prescription</b>
                            </p>
                          </div>
                        </div>

                        <table className="inline-table w-[100%] table-auto bg-white text-sm shadow">
                          <thead>
                            <tr className="bg-blue-200">
                              <th className=" w-[200px] border px-4 py-2">
                                Date
                              </th>
                              <th className="border px-4 py-2">Doctor Name</th>
                              <th className="border px-4 py-2">Status</th>
                              <th className="border px-4 py-2">Download</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(userData.consultations) &&
                            userData.consultations.length > 0 ? (
                              userData.consultations
                                .sort((a, b) => {
                                  const dateA = new Date(a.date);
                                  const dateB = new Date(b.date);
                                  return dateB - dateA; // Comparing dates in descending order
                                })
                                .slice(
                                  0,
                                  isTableExpandedForPrescription ? undefined : 1
                                )

                                .map((row, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      className="hover:bg-gray-100"
                                    >
                                      <td className="border px-4 py-2 text-center">
                                        {row.date
                                          ? dateFormateHandler(row.date)
                                          : "NA"}
                                      </td>
                                      <td className="border px-4 py-2 text-center">
                                        {row.doctor_name
                                          ? row.doctor_name
                                          : "NA"}
                                      </td>
                                      <td className="border px-4 py-2 text-center">
                                        {row.status ? row.status : "NA"}
                                      </td>

                                      <td className="border px-4 py-2 text-center">
                                        {row.id ? (
                                          <button
                                            onClick={() => {
                                              getPDF(row.id);
                                            }}
                                          >
                                            <MdDownload className="text-lg text-blue-500" />
                                          </button>
                                        ) : (
                                          "NA"
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                            ) : (
                              <tr>
                                <td
                                  className="border px-4 py-2 text-center"
                                  colSpan="6"
                                >
                                  No data available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {userData.consultations &&
                          userData.consultations.length > 1 && (
                            <div className="flex w-full justify-center">
                              <button
                                onClick={() => toggleTable("pre")}
                                className="flex w-[40px] items-center justify-center rounded-b-md bg-white p-1 focus:outline-none"
                              >
                                {isTableExpandedForPrescription ? (
                                  <>
                                    <FaMinus className="text-sm text-blue-500" />
                                  </>
                                ) : (
                                  <>
                                    <FaPlus className="text-sm text-blue-500" />
                                  </>
                                )}
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex h-[80vh]  w-full items-center justify-center gap-2 text-5xl font-bold text-gray-900">
                    <ImSad />
                    <h2 className="">Number not found!</h2>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {mainLoader && (
          <div className="fixed inset-0 z-50 flex h-screen items-center justify-center overflow-y-auto bg-gray-900 text-sm opacity-75  sm:h-[150%]">
            <ClipLoader
              color={"white"}
              size={100}
              className="relative bottom-48 h-10 text-white"
            />
          </div>
        )}
        <PatientModal />
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
}

export default UserData;
