import React, { useEffect, useState } from "react";
import MessagePersonal from "./MessagePersonal";
import { ClipLoader } from "react-spinners";

export const ChatContainerPersonal = ({
  chatData,
  mobileNumberSearchQuery,
  handleShowPreviousMessages,
  fetchNextMessages,
  isPreviousMessageLoading,
  isNextMessageLoading,
  isNewMessageLoading,
  handleScroll,
  chatContainerRef,
  selectedTeamType,
  onTeamTypeChange,
}) => {
  const handleTeamTypeChange = (prop) => {
    onTeamTypeChange(prop);
  };
  useEffect(() => {
    console.log(selectedTeamType);
  }, [selectedTeamType]);
  useEffect(() => {
    console.log("personal called");
    console.log(chatData);
  }, []);

  return (
    <div
      ref={chatContainerRef}
      className="message bg-[#DAD3CC]"
      id="conversation"
    >
      {chatData.length <= 0 ? (
        <div className="flex h-[94vh] flex-col items-center justify-center">
          <p className="text-black text-center text-2xl">
            Welcome to Babynama. Please select a Chat to see the messages
          </p>
          <div className="mt-4 flex">
            <button
              className="mr-4 rounded bg-[#DDECF2] py-2 px-4 text-sm"
              onClick={() => handleTeamTypeChange("sales")}
            >
              Sales
            </button>
            <button
              className="rounded bg-[#DDECF2] py-2 px-4 text-sm"
              onClick={() => handleTeamTypeChange("support")}
            >
              Support
            </button>
          </div>
        </div>
      ) : isNewMessageLoading ? (
        <div className="flex h-[90vh] flex-col items-center justify-center">
          <ClipLoader color={"blue"} size={60} />
        </div>
      ) : (
        <>
          <div className=" message-previous">
            <div className="previous">
              <button
                className="rounded bg-[#DDECF2] py-2 px-4 text-sm"
                onClick={handleShowPreviousMessages}
              >
                {isPreviousMessageLoading ? (
                  <ClipLoader color={"blue"} size={20} />
                ) : (
                  "Show Previous Messages!"
                )}
              </button>
            </div>
          </div>
          {/* Render messages */}
          {chatData.map((message, index) => (
            <MessagePersonal
              key={index}
              chatResponse={message}
              query={mobileNumberSearchQuery}
              teamType={selectedTeamType}
            />
          ))}

          <div className="row message-next">
            <div className="next">
              <button
                className="rounded bg-[#DDECF2] py-2 px-4 text-sm"
                onClick={() => fetchNextMessages()}
              >
                {isNextMessageLoading ? (
                  <ClipLoader color={"blue"} size={20} />
                ) : (
                  "Show Next Messages!"
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
