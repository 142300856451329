import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Message from "./Message";
import { ClipLoader } from "react-spinners";
import SyncLoader from "components/Loaders/SyncLoader";
import { MdCancel } from "react-icons/md";
import { getToken } from "utils/getToken";

const headers = {
  Authorization: `Token ${getToken()}`,
};

export const ChatContainer = ({
  chatData,
  setMobileNumberSearchQuery,
  mobileNumberSearchQuery,
  handleShowPreviousMessages,
  handleNextMessages,
  isPreviousMessageLoading,
  isNextMessageLoading,
  isNewMessageLoading,
  isPreviousMessageLoaded,
  newMessageLength,
  selectedGroupId,
}) => {
  const [isOldMessageLineVisible, setIsOldMessageLineVisible] = useState(false);
  const chatContainerRef = useRef(null);

  //State for Send and Reply Message Feature
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [replyingToMessage, setReplyingToMessage] = useState(null);
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [typeMessage, setTypeMessage] = useState("");
  const sendMessageFormRef = useRef(null);
  //End State for Send and Reply Message Feature

  // Scroll to the bottom when new messages are loaded
  useEffect(() => {
    if (!isNewMessageLoading) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [isNewMessageLoading]);

  //  set oldmessagelineVisible to false after 5 seconds
  useLayoutEffect(() => {
    if (isOldMessageLineVisible) {
      setTimeout(() => {
        setIsOldMessageLineVisible(false);
      }, 5000);
    }
  }, [isOldMessageLineVisible]);

  // Add a new message indicator when new messages are loaded
  useEffect(() => {
    if (isPreviousMessageLoaded) {
      const messageToScrollTo = chatData[newMessageLength];
      if (messageToScrollTo) {
        scrollToMessage(messageToScrollTo);
      }
    }
  }, [isPreviousMessageLoaded, newMessageLength, chatData]);

  useEffect(() => {
    const getGroupMembers = async (groupId) => {
      if (groupId) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}whatsapp/get-group-members/`,
            {
              group_id: groupId,
            },
            {
              headers,
            }
          );

          if (response.status === 200 && response.data) {
            if (Array.isArray(response.data)) {
              setAllUsers(response.data);
            }
          }
        } catch (e) {
          console.log("CHAT_CONTAINER_GET_GROUP_MEMBER", e);
          setAllUsers([]);
        }
      }
    };

    if (selectedGroupId) {
      getGroupMembers(selectedGroupId);
    }
  }, [selectedGroupId]);

  const scrollToMessage = (message) => {
    console.log("scrollToMessage");
    if (!message) return;
    const messageElement = document.getElementById(
      `message-${message.message.message_id}`
    );
    if (messageElement) {
      // const newMessagesDiv = document.createElement("div");
      // newMessagesDiv.innerText = "Scroll Up to View New Messages";
      // newMessagesDiv.className = "new-messages-indicator";
      // // Insert the element directly before messageElement
      // messageElement.insertAdjacentHTML(
      //   "beforebegin",
      //   newMessagesDiv.outerHTML
      // );
      // Scroll to the message and show the "New Messages" indicator
      messageElement.scrollIntoView();
      // messageElement.classList.add("new-message-color");
      // const newMessagesDivToRemove = document.querySelector(
      //   ".new-messages-indicator"
      // );
      // setTimeout(() => {
      //   newMessagesDivToRemove.remove();
      // }, 3000);
    }
  };

  const handleReplyClick = (message) => {
    setReplyingToMessage(message);

    if (sendMessageFormRef.current) {
      sendMessageFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleInputChange = (event) => {
    // const mentionRegex = /@(\w+)/g;
    const mentionRegex = /@(\w+)(?![^\(]*\))/g;
    const inputText = event.target.value;
    setTypeMessage(inputText);

    // Detect "@" mentions and filter suggestions based on allUsers
    const mentions = inputText.match(mentionRegex);
    if (mentions) {
      const uniqueMentions = [
        ...new Set(mentions.map((mention) => mention.substring(1))),
      ];
      const filteredSuggestions = allUsers.filter((user) =>
        uniqueMentions.some(
          (mention) =>
            user.name.toLowerCase().includes(mention.toLowerCase()) ||
            user.phoneNumber.toLowerCase().includes(mention.toLowerCase())
        )
      );
      setMentionSuggestions(filteredSuggestions);
    } else {
      setMentionSuggestions([]);
    }
  };

  const handleMentionClick = (mention) => {
    // const mentionRegex = /@(\w+)/g;
    const mentionRegex = /@(\w+)(?![^\(]*\))/g;
    setTypeMessage((prevMessage) =>
      prevMessage.replace(
        mentionRegex,
        `(@${mention.name} - ${mention.phoneNumber})`
      )
    );
    setMentionSuggestions([]);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (typeMessage && selectedGroupId) {
      let validMentions = [];
      const mentionRegex = /\(([^)]+)\)/g;
      const mentions = typeMessage.match(mentionRegex);

      if (mentions && mentions.length > 0) {
        // Validate mentions against allUsers array
        const uniqueValidMentionsSet = new Set(
          mentions
            ? mentions
                .map((mention) => {
                  const phoneNumber = mention.replace(/\D/g, ""); // Extract only digits from the mention
                  // const phoneNumber = mention.replace(/^\D+/g, "");
                  // const phoneNumber = mention.replace(/[^-\d]/g, "");
                  return phoneNumber;
                })
                .filter((mention) =>
                  allUsers.some(
                    (user) =>
                      user.phoneNumber.replace(/\D/g, "").toLowerCase() ===
                      mention.toLowerCase()
                  )
                )
            : []
        );

        validMentions = Array.from(uniqueValidMentionsSet);
      }

      try {
        setIsMessageSending(true);

        const payload = {
          group_id: selectedGroupId,
          content: typeMessage.replace(/\(([^)]+)\)/g, (match, mention) => {
            const phoneNumber = mention.replace(/\D/g, ""); // Extract only digits from the mention
            return `@${phoneNumber}`; // Replace the mention with the formatted mention
          }),
        };

        if (replyingToMessage) {
          payload.message_id = replyingToMessage.message.message_id;
        }

        if (validMentions.length > 0) {
          payload.mentions = validMentions
            .map((phoneNumber) => `${phoneNumber}@c.us`)
            .join(",");
        }

        // console.log("final Payload", payload);

        const sendMessageResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}whatsapp/send-message/`,
          payload,
          {
            headers,
          }
        );

        if (sendMessageResponse.status === 200) {
          //Simulation a delay of 7 seconds because messages takes time to sync on backend
          toast.success("Message sent successfully!");
          setTimeout(() => {
            handleNextMessages();

            setIsMessageSending(false);
            setTypeMessage("");
          }, 7000);
        }
      } catch (error) {
        toast.error("Something Went Wrong, Please check your network!");
        console.error("Error sending message:", error);
        setIsMessageSending(false);
      }
    }
  };

  return (
    <div
      ref={chatContainerRef}
      className="message bg-[#DAD3CC]"
      id="conversation"
    >
      {chatData.length <= 0 ? (
        <div className="flex h-[92vh] flex-col items-center justify-center">
          <p className="text-black text-center text-2xl">
            Welcome to Babynama. Please select a Chat to see the messages
          </p>
        </div>
      ) : isNewMessageLoading ? (
        <div className="flex h-[90vh] flex-col items-center justify-center">
          <ClipLoader color={"blue"} size={60} />
        </div>
      ) : (
        <>
          <div className="message-previous">
            <div className="previous">
              {isPreviousMessageLoading ? (
                <ClipLoader color={"blue"} size={20} />
              ) : (
                <button
                  className={`${
                    isPreviousMessageLoading ? "hidden" : ""
                  } rounded bg-[#DDECF2] py-2 px-4 text-sm`}
                  onClick={handleShowPreviousMessages}
                >
                  Show Previous Messages!
                </button>
              )}
            </div>
          </div>
          {/* Render messages */}
          {chatData.map((messageData, index) => (
            <div key={index} className="">
              <Message
                key={index}
                setMobileNumberSearchQuery={setMobileNumberSearchQuery}
                message={messageData}
                query={mobileNumberSearchQuery}
                handleReplyClick={handleReplyClick}
              />
            </div>
          ))}
          <div className="row message-next">
            <div className="next">
              {isNextMessageLoading ? (
                <ClipLoader color={"blue"} size={20} />
              ) : (
                <button
                  className={`${
                    isNextMessageLoading ? "hidden" : ""
                  } rounded bg-[#DDECF2] py-2 px-4 text-sm`}
                  onClick={() => handleNextMessages()}
                >
                  Show Next Messages!
                </button>
              )}
            </div>
          </div>

          {/* Send messages */}

          <div className="rounded-md px-3 py-4 shadow-lg">
            {replyingToMessage && (
              <div className="mb-2 flex items-center gap-2 rounded-md bg-gray-50 px-2 py-2">
                <div className="h-16 w-1 rounded-md bg-blue-400" />
                <div className="flex-1 rounded-md p-3">
                  <div className="">
                    <p className="text-blue-500">
                      <span className="font-semibold text-gray-800">
                        Replying to:{" "}
                      </span>
                      {`${
                        replyingToMessage.user.name
                          ? replyingToMessage.user.name
                          : replyingToMessage.user.phone_number
                      }`}
                    </p>
                    <p>
                      <span className="font-semibold">Message: </span>
                      {`${replyingToMessage.message.content}`}
                    </p>
                  </div>
                </div>
                <button
                  className="text-gray-700"
                  onClick={() => setReplyingToMessage(null)}
                >
                  <MdCancel size={25} />
                </button>
              </div>
            )}
            <form
              className="w-full"
              ref={sendMessageFormRef}
              onSubmit={handleSendMessage}
            >
              <div className="flex items-center gap-2">
                <div className="relative flex-grow ">
                  {/* Mention suggestions dropdown */}
                  {mentionSuggestions.length > 0 && (
                    <div className="ring-black absolute bottom-10 left-0 mt-2 max-h-32 w-full overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-opacity-5">
                      <ul className="py-1">
                        {mentionSuggestions.map((mention) => (
                          <li
                            key={mention.phoneNumber}
                            className="block cursor-pointer px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200"
                            onClick={() => handleMentionClick(mention)}
                          >
                            @ {mention.name} ({mention.phoneNumber})
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <input
                    type="text"
                    className="mr-2 w-full rounded-md bg-gray-50 px-3 py-2 text-sm text-gray-800"
                    value={typeMessage}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <button
                  type="submit"
                  disabled={isMessageSending}
                  className={`rounded-md bg-blue-500 px-3 py-2 text-sm text-white disabled:cursor-not-allowed disabled:bg-blue-300`}
                >
                  {isMessageSending ? "Sending" : "Send"}
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
