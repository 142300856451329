import { useEffect, useState } from "react";
import { SiLivechat, SiChatwoot } from "react-icons/si";
import { MdPendingActions, MdEvent, MdQuestionAnswer } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ChatCountLogic from "../../utils/ChatCountLogic";
import ChatStatus from "components/ChatStatus/ChatStatus";
import { RiChatPrivateLine } from "react-icons/ri";
import axios from "axios";
import { getToken } from "utils/getToken";
import ServerStatus from "components/serverStatus/ServerStatus";
import { SyncLoader } from "react-spinners";

function Home() {
  const navigate = useNavigate();
  const [groupLiveChatsCount, setGroupLiveChatsCount] = useState(0);
  const [actionableGroupLiveChatsCount, setActionableGroupLiveChatsCount] =
    useState(0);
  const [privateLiveChatsCount, setPrivateLiveChatsCount] = useState(0);
  const [actionablePrivateLiveChatsCount, setActionablePrivateLiveChatsCount] =
    useState(0);
  const [pendingDoctorChatsCount, setPendingDoctorChatsCount] = useState(0);
  const [
    actionablePendingDoctorChatsCount,
    setActionablePendingDoctorChatsCount,
  ] = useState(0);
  const [appointmentScheduledCount, setAppointmentScheduledCount] =
    useState(null);
  const [appointmentTotalCount, setAppointmentTotalCount] = useState(null);
  const [followUpChatTotalCount, setFollowUpChatTotalCount] = useState(0);
  const [shouldPrivateChatBlink, setShouldPrivateChatBlink] = useState(false);
  const [groupChatBlink, setGroupChatBlink] = useState(false);
  const [pendingDoctorChatBlink, setPendingDoctorChatBlink] = useState(false);
  const [taskTotalCount, setTaskTotalCount] = useState(0);
  const [criticalTaskCount, setCriticalTaskCount] = useState(0);
  const [shouldTaskBlink, setShouldTaskBlink] = useState(false);
  const [shouldQnasBlink, setShouldQnasBlink] = useState(false);
  const [chatwootChatsCount, setChatwootChatsCount] = useState(0);
  const [qnasTotalActionableCount, setQnasTotalActionableCount] = useState(0);
  const [qnasTotalPendingCount, setQnasTotalPendingCount] = useState(0);
  const [qnasCountDocs, setQnasCountDocs] = useState(0);
  const [qnasCountFollowup, setQnasCountFollowup] = useState(0);
  const [qnasBotCount, setQnasBotCount] = useState(0);
  const [actionableChatwootChatsCount, setActionableChatwootChatsCount] =
    useState(0);
  const [countsLoading, setCountsLoading] = useState(false);
  const [qnaCountsLoading, setQnaCountsLoading] = useState(false);

  const cardData = [
    {
      id: 1,
      count: groupLiveChatsCount,
      actionableCount: actionableGroupLiveChatsCount,
      icon: <SiLivechat className="text-2xl" />,
      loading: countsLoading,
      title: "Group Live Chat",
      link: "/admin/chat-status",
    },
    {
      id: 2,
      count: privateLiveChatsCount,
      actionableCount: actionablePrivateLiveChatsCount,
      icon: <RiChatPrivateLine className="text-2xl" />,
      loading: countsLoading,
      title: "Private Group Live Chat",
      link: "/admin/private-chat-tool",
    },
    {
      id: 3,
      count: taskTotalCount,
      actionableCount: criticalTaskCount,
      icon: <BiSupport className="text-2xl" />,
      loading: countsLoading,
      title: "Actionable Tasks",
      link: "/admin/support-tasks",
    },
    {
      id: 4,
      count: pendingDoctorChatsCount,
      actionableCount: actionablePendingDoctorChatsCount,
      icon: <MdPendingActions className="text-2xl" />,
      loading: countsLoading,
      title: "Pending Doctor Chat",
      link: "/admin/pending-doctor-chat",
    },
    {
      id: 5,
      count: appointmentTotalCount,
      actionableCount: appointmentScheduledCount,
      icon: <MdEvent className="text-2xl" />,
      loading: countsLoading,
      title: "Actionable Appts",
      link: "/admin/appointment?status=custom_filter",
    },
    {
      id: 6,
      count: chatwootChatsCount,
      icon: <SiChatwoot className="text-2xl" />,
      loading: countsLoading,
      title: "Chatwoot Open Chats",
      link: "/admin/chatwoot-tool",
    },
    // {
    //   id: 6,
    //   count: followUpChatTotalCount,
    //   icon: <FaRegCalendarCheck className="text-2xl" />,
    //   title: "Follow Up Chat",
    //   link: "/admin/followup-chat-tool",
    // },
  ];

  const cardQNA = {
    id: "qnaCard",
    actionableCount: qnasTotalActionableCount,
    loading: qnaCountsLoading,
    pedningCount: qnasTotalPendingCount,
    botCount: qnasBotCount,
    docsCount: qnasCountDocs,
    followupCount: qnasCountFollowup,
    icon: <MdQuestionAnswer className="text-2xl" />,
    title: "ACTIONABLE QNAs",
    link: "/admin/qnas?status=actionable",
  };

  const fetchAppointmentScheduledData = async (params) => {
    try {
      let url =
        process.env.REACT_APP_API_URL +
        `consultation/appointment/actionable/count/`;

      const response = await axios.post(url, null, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      const actionCount = response.data.action_count;
      const totalCount = response.data.total_count;

      setAppointmentScheduledCount(actionCount);
      setAppointmentTotalCount(totalCount);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(fetchAppointmentScheduledData, 60000);
    fetchAppointmentScheduledData();

    const handleFocus = fetchAppointmentScheduledData;
    window.addEventListener("focus", handleFocus);

    return () => {
      clearInterval(interval);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  const currentTime = new Date();
  const [serverSyncTime, setServerSyncTime] = useState(currentTime);
  const [lastServerSyncTime, setLastServerSyncTime] = useState(currentTime);
  const [isServerSyncTimeExceed, setIsServerSyncTimeExceed] = useState(false);
  const [lastBlockAlertTime, setLastBlockAlertTime] = useState(currentTime);
  const [lastBlockAlertSyncTime, setLastBlockAlertSyncTime] =
    useState(currentTime);
  const [isLastBlockAlertTimeExceed, setIsLastBlockAlertTimeExceed] =
    useState(false);
  const timeDifference = serverSyncTime
    ? currentTime - new Date(serverSyncTime)
    : null;

  const isTimeDifferenceExceeded =
    timeDifference && timeDifference > 15 * 60 * 1000;

  // for checking and giving the blink class
  const getCardClass = (card) => {
    const baseClass =
      card.count > 0
        ? "bg-yellow-500 hover:bg-yellow-600"
        : "bg-green-500 hover:bg-green-600";

    if (card.loading === true) {
      return "bg-gray-500";
    } else {
      if (card.id === 1 && groupChatBlink) {
        return "blink-bg-red bg-red-500";
      }
      if (card.id === 2 && shouldPrivateChatBlink) {
        return "blink-bg-red bg-red-500";
      }
      if (card.id === 3 && shouldTaskBlink) {
        return "blink-bg-red bg-red-500";
      }
      if (card.id === 4 && pendingDoctorChatBlink) {
        return "blink-bg-red bg-red-500";
      }
      if (card.id === 5 && appointmentScheduledCount > 0) {
        return "blink-bg-red bg-red-500";
      }
      if (card.id === 5 && appointmentTotalCount > 0) {
        return "bg-yellow-500 hover:bg-yellow-600";
      }
      if (card.id === 6 && chatwootChatsCount > 0) {
        return "blink-bg-red bg-red-500";
      }
      if (card.id === "qnaCard" && shouldQnasBlink) {
        return "blink-bg-red bg-red-500";
      }
    }
    return baseClass;
  };

  return (
    <div className="mx-auto w-full items-center justify-center px-4 md:mx-0 md:w-full md:p-2">
      <ChatCountLogic
        setGroupLiveChatsCount={setGroupLiveChatsCount}
        setActionableGroupLiveChatsCount={setActionableGroupLiveChatsCount}
        setPendingDoctorChatsCount={setPendingDoctorChatsCount}
        setActionablePendingDoctorChatsCount={
          setActionablePendingDoctorChatsCount
        }
        setPrivateLiveChatsCount={setPrivateLiveChatsCount}
        setActionablePrivateLiveChatsCount={setActionablePrivateLiveChatsCount}
        setServerSyncTime={setServerSyncTime}
        setIsServerSyncTimeExceed={setIsServerSyncTimeExceed}
        setLastBlockAlertTime={setLastBlockAlertTime}
        setIsLastBlockAlertTimeExceed={setIsLastBlockAlertTimeExceed}
        setLastServerSyncTime={setLastServerSyncTime}
        setLastBlockAlertSyncTime={setLastBlockAlertSyncTime}
        setFollowUpChatTotalCount={setFollowUpChatTotalCount}
        setShouldPrivateChatBlink={setShouldPrivateChatBlink}
        setGroupChatBlink={setGroupChatBlink}
        setPendingDoctorChatBlink={setPendingDoctorChatBlink}
        setTaskTotalCount={setTaskTotalCount}
        setCriticalTaskCount={setCriticalTaskCount}
        setShouldTaskBlink={setShouldTaskBlink}
        setChatwootChatsCount={setChatwootChatsCount}
        setQnasTotalActionableCount={setQnasTotalActionableCount}
        setShouldQnasBlink={setShouldQnasBlink}
        setQnasTotalPendingCount={setQnasTotalPendingCount}
        setQnasCountFollowup={setQnasCountFollowup}
        setQnasCountDocs={setQnasCountDocs}
        setQnasBotCount={setQnasBotCount}
        setCountsLoading={setCountsLoading}
        setQnaCountsLoading={setQnaCountsLoading}
        // setActionableChatwootChatsCount={setActionableChatwootChatsCount}
      />

      <div className="my-8 px-4">
        <ServerStatus
          loading={countsLoading}
          lastServerSyncTime={lastServerSyncTime}
          lastBlockAlertSyncTime={lastBlockAlertSyncTime}
          readBotLastMsgTime={serverSyncTime}
          readBotRaiseAlert={isServerSyncTimeExceed}
          writeBotLastMsgTime={lastBlockAlertTime}
          writeBotRaiseAlert={isLastBlockAlertTimeExceed}
        />
      </div>

      <div className="flex w-full flex-wrap items-center justify-center gap-3 px-0 py-6">
        {cardData.map((card) => (
          <div
            key={card.id}
            onClick={() => navigate(card.link)}
            className={`group relative my-4 flex h-[160px] w-36 cursor-pointer flex-col items-center justify-between rounded-lg p-2 text-white shadow-xl transition-all hover:scale-105 hover:shadow-2xl ${getCardClass(
              card
            )}`}
          >
            {/* loader start */}
            {card.loading === true && (
              <SyncLoader
                color={"#fff"}
                size={10}
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            )}
            {/* loader end */}

            {/* icon */}
            <div
              className={`absolute -top-5 rounded-[100%] border-2 border-white p-2 duration-[600ms] group-hover:rotate-[360deg] ${getCardClass(
                card
              )}`}
            >
              {card.icon}
            </div>
            {/* icon end */}

            {/* count */}
            {card.loading === false && card.actionableCount >= 0 ? (
              <p className="mt-8 text-5xl font-bold">{card?.actionableCount}</p>
            ) : card.loading === false ? (
              <p className="mt-8 text-5xl font-bold">{card?.count}</p>
            ) : null}
            {/* count end */}

            {/* card name */}
            {card.loading === false && (
              <h2 className="text-center text-[0.7rem] font-semibold uppercase tracking-wider">
                {card.title}
              </h2>
            )}
            {/* card name end */}

            {/* total */}
            {card.loading === false && card.actionableCount >= 0 ? (
              <p className="text-xs font-semibold uppercase">
                Total: <span className="text-base">{card.count}</span>
              </p>
            ) : (
              <p className="my-2"></p>
            )}
            {/* total end */}
          </div>
        ))}
        {/* QNA CARD */}
        <div
          onClick={() => navigate(cardQNA.link)}
          className={`group relative my-4 flex h-[160px] w-44 cursor-pointer flex-col items-center justify-between rounded-lg p-2 text-white shadow-xl transition-all hover:scale-105 hover:shadow-2xl ${getCardClass(
            cardQNA
          )}`}
        >
          {/* loader start */}
          {cardQNA.loading === true && (
            <SyncLoader
              color={"#fff"}
              size={10}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          )}
          {/* loader end */}

          {/* icon */}
          <div
            className={`absolute -top-5 rounded-[100%] border-2 border-white p-2 duration-[600ms] group-hover:rotate-[360deg] ${getCardClass(
              cardQNA
            )}`}
          >
            {cardQNA.icon}
          </div>
          {/* icon end */}

          {/* count */}
          {/* card name */}
          {cardQNA.loading === false && (
            <h2 className="absolute bottom-14 text-center text-[0.7rem] font-semibold uppercase tracking-wider">
              {cardQNA.title}
            </h2>
          )}
          {/* card name end */}
          {cardQNA.loading === false && cardQNA.actionableCount >= 0 ? (
            <p className="pt-8 text-5xl font-bold">
              {cardQNA?.actionableCount}
            </p>
          ) : cardQNA.loading === false ? (
            <p className="pt-8 text-5xl font-bold">{cardQNA?.pedningCount}</p>
          ) : null}
          {/* count end */}

          {cardQNA.loading === false && (
            <div className="flex items-center justify-center gap-x-3">
              <div className="flex flex-col items-center justify-center">
                <p className="text-center text-xs font-semibold leading-tight">
                  Doctor Care
                </p>
                <p className="text-lg font-bold">{cardQNA?.docsCount}</p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <p className="text-center text-xs font-semibold leading-tight">
                  Followup
                </p>
                <p className="text-lg font-bold">{cardQNA?.followupCount}</p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <p className="text-center text-xs font-semibold leading-tight">
                  Bot
                </p>
                <p className="text-lg font-bold">{cardQNA?.botCount}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <hr className="border-t-3 border-gray-700" />
      <ChatStatus />
    </div>
  );
}

export default Home;
