import { FiSearch } from "react-icons/fi";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  filterPageState,
  currentPageState,
  totalPagesState,
} from "store/appointment/atoms/appointmentAtomState";

const AppointmentPagination = ({
  setMobileNumber,
  setIsSearchButtonClicked,
}) => {
  const [currentPage, setCurrentPage] = useRecoilState(currentPageState);
  const totalPages = useRecoilValue(totalPagesState);
  const [filterPage, setFilterPage] = useRecoilState(filterPageState);

  const handlePageSearch = () => {
    const pageNumber = parseInt(filterPage);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const numberOfButtons = Math.min(10, totalPages);
  const startPage = Math.max(1, currentPage - Math.floor(numberOfButtons / 2));
  const endPage = Math.min(totalPages, startPage + numberOfButtons - 1);

  return (
    <div className="flex flex-col items-center justify-center pt-3 md:flex-row">
      {totalPages > 1 && (
        <div className="flex items-center justify-center space-x-2">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="flex items-center justify-center rounded-lg bg-blue-500 px-2 py-1 text-white hover:bg-blue-600 disabled:bg-gray-400 md:px-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 rotate-180 transform"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 15.707a1 1 0 010-1.414L13.586 11H4a1 1 0 110-2h9.586l-3.293-3.293a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => {
            const pageNumber = startPage + index;
            return (
              <button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber)}
                className={`ml-2 rounded-lg px-4 py-1 ${
                  currentPage === pageNumber
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                }`}
              >
                {pageNumber}
              </button>
            );
          })}
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="flex items-center justify-center rounded-lg bg-blue-500 px-2 py-1 text-white hover:bg-blue-600 disabled:bg-gray-400 md:px-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 15.707a1 1 0 010-1.414L13.586 11H4a1 1 0 110-2h9.586l-3.293-3.293a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      )}
      <div className="relative mt-5 flex items-center justify-end md:ml-auto md:mt-0">
        <input
          type="text"
          placeholder={`Go to page (1 - ${totalPages})`}
          value={filterPage}
          onChange={(e) => setFilterPage(e.target.value)}
          className="w-full rounded-md border border-gray-400 bg-white py-2 pl-8 pr-2 text-sm font-medium text-gray-800 outline-none"
        />
        <button
          className="mx-2 flex cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          onClick={handlePageSearch}
        >
          <FiSearch className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default AppointmentPagination;
