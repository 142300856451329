import { HiX, HiMenuAlt3 } from "react-icons/hi";
import Links from "./components/Links";
import DashIcon from "components/icons/DashIcon";
import routes from "routes.js";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { openAddUpdatePatientModalOthersState } from "store/patientModalState";
import "./styles.css";
const Sidebar = () => {
  const [opens, setOpens] = useState(() => {
    const savedState = localStorage.getItem("sidebarOpen");
    return savedState === "true";
  });

  useEffect(() => {
    localStorage.setItem("sidebarOpen", opens);
  }, [opens]);

  const location = useLocation();

  const activeRoute = (routeName) => location.pathname.includes(routeName);

  const [route, setRoute] = useState(routes);

  const handleMouseEnter = () => setOpens(true);
  const handleMouseLeave = () => setOpens(false);

  const containerVariants = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.08,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: "30px" },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <div
      className={`relative flex min-h-screen shrink-0 ${
        opens
          ? "w-[265px] transition-[width] duration-500"
          : "w-16 transition-[width] duration-500"
      }`}
    >
      <div
        className={`sidebar fixed top-0 left-0 z-50 h-full px-4 text-gray-900 transition-all duration-500 ease-in-out ${
          opens ? "w-[265px]" : "w-16"
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex justify-end py-3">
          {opens && (
            <div className="mx-[26px] mt-[10px] mb-[10px] flex items-center">
              <div className="mb-5 h-3.5 font-poppins text-[27px] font-bold uppercase text-navy-700 dark:text-white">
                Babynama
              </div>
            </div>
          )}
          <HiMenuAlt3
            size={26}
            className="cursor-pointer"
            onClick={() => setOpens(!opens)}
          />
        </div>

        <motion.div
          className={`relative flex h-[calc(100vh-4rem)] flex-col gap-2 overflow-y-scroll ${
            !opens ? "mt-4" : "links-container pb-6"
          }`}
          style={!opens ? { scrollbarWidth: "none", msOverflowStyle: "none" }:{}} // Hide scrollbar for Firefox and IE/Edge
          initial="hidden"
          animate={opens ? "show" : "hidden"}
          variants={containerVariants}
        >
            <style jsx>{`
              ::-webkit-scrollbar {
                width: 0;
                height: 0;
              }
            `}</style>

          {route
            .filter(
              (r) =>
                r.layout === "/admin" ||
                r.layout === "/auth" ||
                r.layout === "/rtl"
            )
            .map(
              (menu, index) =>
                menu.path !== "sign-in" && (
                  <Link
                    to={`${menu.layout}/${menu.path}`}
                    key={index}
                    className={`${
                      menu.margin && "mt-5"
                    } group relative flex items-center gap-3.5 rounded-md p-2 text-sm font-medium hover:bg-white hover:bg-opacity-75`}
                  >
                    <span
                      className={`${
                        activeRoute(menu.path)
                          ? "font-bold text-[#7f3cf2] dark:text-white"
                          : "font-medium text-gray-700"
                      }`}
                    >
                      {menu.icon ? menu.icon : <DashIcon />}
                    </span>

                    <motion.h2
                      className="whitespace-pre font-[600] text-[#6b7280]"
                      variants={itemVariants}
                    >
                      {menu.name}
                    </motion.h2>

                    <h2
                      className={`${
                        openAddUpdatePatientModalOthersState && "hidden"
                      } absolute left-48 w-0 overflow-hidden whitespace-pre rounded-md bg-white px-0 py-0 font-semibold text-gray-900 drop-shadow-lg transition-all ease-in-out group-hover:left-14 group-hover:w-fit group-hover:px-2 group-hover:py-1 group-hover:duration-300`}
                    >
                      {menu.name}
                    </h2>
                  </Link>
                )
            )}
        </motion.div>
      </div>

      {/* <div className="ml-16 flex-1 p-4">
        Main content goes here
      </div> */}
    </div>
  );
};

export default Sidebar;
