import { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

function SnoozeDropdown({ selectedValue, onChange, options }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Handle click outside of the dropdown to close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    if (option.value === "custom" || option.value !== selectedValue) {
      onChange(option.value);
      setIsOpen(false);
    }
  };

  return (
    <div
      ref={dropdownRef}
      style={{ position: "relative", display: "inline-block" }}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="mx-auto flex w-40 cursor-pointer items-center justify-between rounded-md bg-blue-300 p-2 focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
      >
        {options.find((opt) => opt.value === selectedValue)?.label ||
          "Select duration"}
        {isOpen ? (
          <FaChevronUp className="shrink-0 text-[12px]" />
        ) : (
          <FaChevronDown className="shrink-0 text-[12px]" />
        )}
      </button>
      {isOpen && (
        <div
          className={`absolute top-full right-0 z-50 mt-1 min-w-[250px]  overflow-hidden rounded-md bg-blue-300 shadow-md`}
        >
          {options.map((option, index) => (
            <div
              key={index}
              style={{
                cursor:
                  option.value === "custom" || option.value !== selectedValue
                    ? "pointer"
                    : "not-allowed",
              }}
              className={`py-2 px-3 ${
                option.value !== selectedValue || option.value === "custom"
                  ? "hover:bg-gray-100"
                  : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SnoozeDropdown;
