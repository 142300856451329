import axios from "axios";
import UserDataPopup from "components/UserData/UserDataPopup";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { FaPencilAlt, FaStar } from "react-icons/fa";
import { HiExternalLink } from "react-icons/hi";
import {
  useRecoilState,
  useRecoilTransaction_UNSTABLE,
  useSetRecoilState,
} from "recoil";
import { getToken } from "utils/getToken";
import "../../assets/css/scrollBar.css";
import {
  appointmentIdState,
  appointmentStatusState,
  dataState,
  highlightedRowState,
  isAppointmentHistoryModalOpenState,
  mainLoaderState,
  selectedUserState,
  statusModalOpenState,
  userMobileNumberState,
} from "../../store/appointment/atoms/appointmentAtomState";

import useDynamicHeight from "store/useDynamicHeight";
import AppointmentHistoryModal from "./AppointmentHistoryModal";

//PATIENT DETAILS IMPORTS
import AddPatientModal from "./AppointmentPatientDetails/AddPatientModal";
import AppointmentPatientDetail from "./AppointmentPatientDetails/AppointmentPatientDetail";

//APPOINTMENT DETAILS IMPORTS
import AppointmentDetailsModal from "./AppointmentDetails/AppointmentDetailsModal";

//Appointment VideoCALL Imports
import CustomTimeDialog from "components/ChatStatus/CustomTimeDialog";
import SnoozeDropdown from "components/dropdown/SnoozeDropdown";
import { toast } from "react-toastify";
import { openVideoCallModalState } from "store/appointment/atoms/appointmentAtomState";
import VideoCallModal from "./AppointmentVideoCall/VideoCallModal";

const AppointmentDesktopView = ({
  handleEditClick,
  setModalContent,
  refresh,
}) => {
  const [highlightedRow, setHighlightedRow] =
    useRecoilState(highlightedRowState);
  const [appointmentStatus, setAppointmentStatus] = useRecoilState(
    appointmentStatusState
  );

  const [selectedUser, setSelectedUser] = useRecoilState(selectedUserState);
  const [appointmentId, setAppointmentId] = useRecoilState(appointmentIdState);
  const [mainLoader, setMainLoader] = useRecoilState(mainLoaderState);
  const [data, setData] = useRecoilState(dataState);
  const [isStatusModalOpen, setIsStatusModalOpen] =
    useRecoilState(statusModalOpenState);
  const [isPDataModalOpen, setIsPDataModalOpen] = useState(false);
  const [openVideoCallModal, setOpenVideoCallModal] = useRecoilState(
    openVideoCallModalState
  );

  const [source, setSource] = useState("support_portal");
  const [copiedTextIndex, setCopiedTextIndex] = useState("");

  const handleCopyText = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedTextIndex(index);
      console.log("COPIED", text);
      setTimeout(() => setCopiedTextIndex(null), 2000);
    });
  };

  const handlePDataModalClose = () => {
    setIsPDataModalOpen(false);
    setSelectedUser(null);
  };

  const handlePDataModal = (row) => {
    setSelectedUser(row.phone_number);
    // console.log(row.Chat_started_by);
    setIsPDataModalOpen(true);
  };

  function createLinkAndOpenPrescription(row, rowIndex) {
    if (!row.phone_number) {
      return "";
    }

    const url = `/admin/userdata?mobile_number=${row.phone_number}`;

    setHighlightedRow(rowIndex);
    setModalContent({ url });
  }

  const handleEditStatusClick = async (rowIndex) => {
    // console.log("rowIndex:", rowIndex);
    const user = data[rowIndex];
    setSelectedUser(user);
    try {
      // Make the API call to get the data for the selected user
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `consultation/appointment/fetch/?app_id=${user.id}&source=${source}`,
        {
          headers: {
            Authorization: `Token ${getToken()}`,
          },
        }
      );

      const userData = response.data.results[0];
      // console.log(response.data.results[0]);

      setAppointmentStatus(userData.appointment_status);

      setMainLoader(false);

      setIsStatusModalOpen(true);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [isAppointmentHistoryModalOpen, setIsAppointmentHistoryModalOpen] =
    useRecoilState(isAppointmentHistoryModalOpenState);
  const setAppointmentIdState = useSetRecoilState(appointmentIdState);
  const [userMobileNumber, setUserMobileNumber] = useRecoilState(
    userMobileNumberState
  );

  const handleAppointmentHistoryModal = useRecoilTransaction_UNSTABLE(
    ({ get, set }) =>
      (rowIndex) => {
        const user = get(dataState)[rowIndex];
        console.log(user);
        set(appointmentIdState, user.id);
        set(userMobileNumberState, user.phone_number);
        set(isAppointmentHistoryModalOpenState, true);
      }
  );

  const handleAppointmentHistoryModalClose = () => {
    setIsAppointmentHistoryModalOpen(false);
    setAppointmentIdState(null);
  };
  const { ref, isOverflowing } = useDynamicHeight([data]);

  const mapRatingToNumber = (rating) => {
    switch (rating) {
      case "very satisfied":
        return 3;
      case "satisfied":
        return 2;
      case "unsatisfied":
        return 1;
      default:
        return 0;
    }
  };

  // Function to map numbers back to rating strings
  const mapNumberToRating = (number) => {
    switch (number) {
      case 3:
        return "very satisfied";
      case 2:
        return "satisfied";
      case 1:
        return "unsatisfied";
      default:
        return "";
    }
  };

  const handleEditRatingClick = async (rowIndex, star) => {
    const updatedUser = data[rowIndex];
    const newRating = mapNumberToRating(star);
    const updatedData = data.map((item, index) =>
      index === rowIndex ? { ...item, rating: newRating } : item
    );

    setData(updatedData);
    setMainLoader(true);

    try {
      const updateData = JSON.stringify({
        appointment_id: updatedUser.id,
        rating: star,
      });

      const config = {
        method: "post",
        url: process.env.REACT_APP_API_URL + "consultation/appointment/update/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${getToken()}`,
        },
        data: updateData,
      };

      const response = await axios(config);
      console.log(response);
      setMainLoader(false);
    } catch (error) {
      console.error("Error updating rating:", error);
      setMainLoader(false);
    }
  };

  const changeVideoConsultStatus = async (rowIndex, value) => {
    const updatedUser = data[rowIndex];
    try {
      const updateData = JSON.stringify({
        appointment_id: updatedUser.id,
        video_consultation_allowed: JSON.parse(value),
      });

      const config = {
        method: "post",
        url: process.env.REACT_APP_API_URL + "consultation/appointment/update/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${getToken()}`,
        },
        data: updateData,
      };
      await axios(config);
      refresh();
      toast.success("Appointment updated successfully");
    } catch (error) {
      toast.error("Error while updating video consultation status");
    }
  };

  const [showCustomDialog, setShowCustomDialog] = useState(false);
  const [snoozeId, setSnoozeId] = useState();
  const [chatId, setChatId] = useState();

  function handleOpenModal(snooze_id, chat_id) {
    setShowCustomDialog(true);
    setSnoozeId(snooze_id);
    setChatId(chat_id);
  }

  // create snooze
  console.log(data[0]);
  async function createSnooze(chatId, snoozeId, value) {
    const token = getToken();
    const url = `${process.env.REACT_APP_API_URL}tasks/alert/create-snooze/`;
    const data = {
      snooze_duration: value,
      alert_source_table: "appointment",
      alert_source_id: chatId,
    };
    if (value === "custom") {
      handleOpenModal(snoozeId, chatId);
    }

    try {
      if (value !== "" && value !== "custom") {
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200 || response.status === 201) {
          toast.success(response.data.message);
          refresh();
        }
      }
    } catch (error) {
      toast.error("Error occured while creating snooze.");
    }
  }

  async function editSnooze(snoozeId, value, is_snoozed, chatId) {
    const token = getToken();
    const url = `${process.env.REACT_APP_API_URL}tasks/alert/edit-snooze/`;
    const data = {
      snooze_duration: is_snoozed && value === "0_min" ? "0_min" : value,
      snooze_id: snoozeId,
    };
    if (value === "custom") {
      handleOpenModal(snoozeId, chatId);
    }
    try {
      if (value !== "" && value !== "custom") {
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200 || response.status === 201) {
          toast.success(response.data.message);
          refresh();
        }
      }
    } catch (error) {
      toast.error("Error occured while editing snooze.");
    }
  }

  const snoozeOptions = [
    { value: "30_min", label: "30 minutes" },
    { value: "60_min", label: "60 minutes" },
    { value: "8am_next_day", label: "Next working hour (8AM next day)" },
    { value: "custom", label: "Custom" },
  ];

  async function createCustomSnooze(snooze_id, value, chat_id) {
    const token = getToken();
    const url =
      snooze_id && snooze_id !== ""
        ? `${process.env.REACT_APP_API_URL}tasks/alert/edit-snooze/`
        : `${process.env.REACT_APP_API_URL}tasks/alert/create-snooze/`;
    const data = {
      alert_source_table: "appointment",
      alert_source_id: chat_id,
      snooze_duration: "custom",
      snooze_end_time: value,
      snooze_id,
    };

    try {
      if (value !== "") {
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200 || response.status === 201) {
          toast.success(response.data.message);
          refresh();
        }
      }
    } catch (error) {
      toast.error("Error occured while creating snooze.");
    }
  }

  console.log(data[1]);

  return (
    <>
      {isPDataModalOpen && (
        <UserDataPopup
          selectedUser={selectedUser}
          noSearch={true}
          handlePDataModalClose={handlePDataModalClose}
        />
      )}
      {showCustomDialog ? (
        <CustomTimeDialog
          snoozeId={snoozeId}
          chatId={chatId}
          setOpen={setShowCustomDialog}
          createSnooze={createCustomSnooze}
        />
      ) : null}
      <table
        ref={ref}
        className={`custom-scrollbar ${
          isOverflowing ? "h-[74vh]" : "h-auto"
        } max-w-full table-auto overflow-scroll rounded-lg border-2 border-gray-600 bg-white text-sm shadow-md sm:block`}
      >
        <thead>
          <tr className="bg-blue-200">
            <th className="border px-4 py-2">Doctor Name</th>
            <th className="border px-4 py-2">Appointment Date</th>
            <th className="border px-4 py-2">Type</th>
            <th className="border px-4 py-2">User</th>
            <th className="border px-4 py-2">Patient Details</th>
            {/* <th className="px-4 py-2 border">Payment-Subscription</th> */}
            <th className="border px-4 py-2">Status</th>
            <th className="border px-4 py-2">Video Consultation via App</th>
            <th className="border px-4 py-2">History Form</th>
            <th className="border px-4 py-2">Rating</th>
            <th className="border px-4 py-2">Actions</th>
            <th className="border px-4 py-2">Snooze</th>

            {/* <th className="px-4 py-2 border">Appoinment Detail</th> */}
            {/* <th className="px-4 py-2 border">Copy History</th> */}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr
                style={row.is_snoozed ? { backgroundColor: "#adb5bd" } : {}}
                key={rowIndex}
                className={`${
                  rowIndex === highlightedRow || row?.is_actionable
                    ? "bg-orange-200"
                    : "hover:bg-blue-100"
                } hover:bg-blue-100`}
              >
                <td className="border px-4 py-2">{row.doctor_name}</td>
                <td className="border px-4 py-2">
                  {row?.scheduled_at !== null ? (
                    <>
                      {format(parseISO(row.scheduled_at), "dd-MM-yyyy")}
                      <br />
                      {format(parseISO(row.scheduled_at), "HH:mm:ss")}
                    </>
                  ) : row?.preferred_time !== null ? (
                    <>
                      <p className="text-sm font-normal text-red-500">N/A</p>
                      <p className="text-xs font-semibold">
                        {row.preferred_time}{" "}
                        <span className="text-blue-400">(Preferred)</span>
                      </p>
                    </>
                  ) : (
                    "N/A"
                  )}
                </td>

                <td className="border px-4 py-2">{row.appointment_type}</td>

                <td className="border px-4 py-2">
                  <div className="flex flex-col justify-center gap-3">
                    <button
                      className="flex cursor-pointer items-center gap-2 text-blue-500 hover:underline"
                      onClick={() => {
                        handlePDataModal(row);
                      }}
                    >
                      {row.phone_number} <HiExternalLink />
                    </button>

                    <p>{row?.name}</p>
                  </div>
                </td>

                <td className="w-4 table-fixed border px-4 py-2">
                  <AppointmentPatientDetail
                    userMobile={row?.phone_number}
                    patientId={row?.patient_id}
                    appointmentIndex={rowIndex}
                    appointmentId={row?.id}
                  />
                </td>

                {/* <td className="px-4 py-2 border">
                  {`${
                    row.payment_amount ? `₹${row.payment_amount}` : "N/A"
                  } - ${row.subscription_name ? row.subscription_name : "N/A"}`}
                </td> */}

                <td className="border px-4 py-2">
                  <p>{row.appointment_status}</p>
                  <button
                    className="mt-2 self-center rounded-md bg-blue-500 px-3 py-2 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                    onClick={() => {
                      setMainLoader(true);
                      handleEditStatusClick(rowIndex);
                    }}
                  >
                    Edit Status
                  </button>
                </td>

                <td className="flex flex-col flex-wrap border px-4 py-2">
                  <select
                    value={row.video_consultation_allowed}
                    onChange={(e) =>
                      changeVideoConsultStatus(rowIndex, e.target.value)
                    }
                    className="cursor-pointer rounded-md px-4 py-1 ring-blue-500 ring-offset-1 focus:ring-2"
                  >
                    <option value={true}>YES</option>
                    <option value={false}>NO</option>
                  </select>

                  {row.video_consultation_allowed && (
                    <button
                      onClick={() => {
                        setOpenVideoCallModal({
                          open: true,
                          appointmentId: row.id,
                        });
                      }}
                      className="mt-2 rounded-md bg-blue-500 px-3 py-2 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                    >
                      Join Call
                    </button>
                  )}
                </td>

                <td className="min-w-[90px] border px-3 py-2 ">
                  {row.patient_case_history_time ? (
                    <p
                      className="flex w-full cursor-pointer items-center justify-center rounded-md bg-orange-400 px-4 py-2 text-sm text-white hover:bg-orange-500"
                      onClick={() => {
                        handleAppointmentHistoryModal(rowIndex);
                      }}
                    >
                      View
                      <HiExternalLink />
                    </p>
                  ) : (
                    <>
                      {row.history_form_link === "" ? (
                        <p className="text-center text-red-500">
                          Form Link Not Available
                        </p>
                      ) : row.history_form_link !== "" ? (
                        <p
                          className="flex w-full cursor-pointer items-center justify-center rounded-md bg-blue-500 px-2 py-2 text-center text-xs text-white hover:bg-blue-600"
                          onClick={() =>
                            handleCopyText(row.history_form_link, rowIndex)
                          }
                        >
                          {copiedTextIndex === rowIndex
                            ? "Copied!"
                            : "Copy Form Link"}
                        </p>
                      ) : (
                        <p className="text-red-500">N/A</p>
                      )}
                    </>
                  )}
                </td>

                <td className="w-[150px] border py-2 px-1">
                  <p>{row.rating ? row.rating : "Not Filled"}</p>

                  <div className="flex items-center gap-1">
                    {[1, 2, 3].map((star) => (
                      <FaStar
                        key={star}
                        className={`cursor-pointer ${
                          mapRatingToNumber(row.rating) >= star
                            ? "text-yellow-500"
                            : "text-gray-400"
                        }`}
                        onClick={() => {
                          handleEditRatingClick(rowIndex, star);
                        }}
                      />
                    ))}
                  </div>
                </td>

                <td
                  className="mr-2 cursor-pointer border px-4 py-2 text-blue-500"
                  onClick={() => {
                    setMainLoader(true);
                    handleEditClick(rowIndex);
                  }}
                >
                  Edit All <FaPencilAlt className="relative left-3" />
                </td>
                <td className="flex flex-col items-center gap-y-2 border py-2 px-1">
                  <SnoozeDropdown
                    selectedValue={row.is_snoozed ? row.snoozed_duration : ""}
                    onChange={(value) => {
                      if (row.snooze_id) {
                        editSnooze(
                          row.snooze_id,
                          value,
                          row.is_snoozed,
                          row.id
                        );
                      } else {
                        createSnooze(row.id, row.snooze_id, value);
                      }
                    }}
                    options={
                      row.is_snoozed
                        ? [
                            ...snoozeOptions,
                            { value: "0_min", label: "Remove snooze" },
                          ]
                        : snoozeOptions
                    }
                  />

                  <div>
                    {row.snoozed_until ? (
                      <p className="font-semibold">
                        till -{" "}
                        {format(
                          new Date(row.snoozed_until),
                          "HH:mm (dd-MM-yy)"
                        )}
                      </p>
                    ) : null}
                  </div>
                  {row.snoozed_by ? (
                    <p>{row.snoozed_by.replace("@gagahealth.com", "")}</p>
                  ) : null}
                </td>
                {/* <td className="px-4 py-2 border">
                  <AppointmentDetails
                    appointmentDetails={row?.appointment_details}
                    appointmentId={row?.id}
                  />
                </td> */}
                {/* <td className="px-4 py-2 mr-2 text-blue-500 border cursor-pointer">
                  <CopyToClipboard
                    appointmentDetails={row?.appointment_details}
                    userMobile={row?.phone_number}
                    patientId={row?.patient_id}
                    appointmentIndex={rowIndex}
                  />
                </td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td className="border px-4 py-2" colSpan="6">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <AddPatientModal />
      <AppointmentDetailsModal />
      <VideoCallModal />
      {isAppointmentHistoryModalOpen && (
        <AppointmentHistoryModal
          isOpen={isAppointmentHistoryModalOpen}
          closeModal={handleAppointmentHistoryModalClose}
        />
      )}
    </>
  );
};

export default AppointmentDesktopView;
