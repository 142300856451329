export const formatToDateAndTime = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formattedDate = new Date(dateTimeString).toLocaleString(
    "en-IN",
    options
  );

  return formattedDate;
};

export const formatToDate = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const formattedDate = new Date(dateTimeString).toLocaleString(
    "en-IN",
    options
  );

  return formattedDate;
};


export const convertTimeTo12HourReadbleFormat = (timeString) => {
  // Split the time string into hours, minutes, and seconds

  if (!timeString) return;

  const [hours, minutes] = timeString.split(":").map(Number);

  // Determine AM/PM and convert hours to 12-hour format
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM

  // Format minutes with leading zero if less than 10
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Return the formatted time string
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const convertTimeTo12HourFormat = (time) => {
  const [hour, minute] = time.split(":");
  const hourNum = parseInt(hour);
  const meridiem = hourNum >= 12 ? "PM" : "AM";
  const formattedHour = hourNum % 12 === 0 ? 12 : hourNum % 12;
  return { hour: formattedHour, minute, meridiem };
};

export function convertTimeTo24HourFormat({ hour, minute, meridiem }) {
  let hourNum = parseInt(hour);
  if (meridiem === "PM" && hourNum < 12) {
    hourNum += 12;
  } else if (meridiem === "AM" && hourNum === 12) {
    hourNum = 0;
  }
  return `${hourNum < 10 ? "0" + hourNum : hourNum}:${minute}`;
}